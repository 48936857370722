import React, { useEffect } from 'react'
import { MdChevronRight } from "react-icons/md";
import { Link } from 'react-router-dom';
import SuccessImg from '../../images/success2.svg'

const FlightSuccessPage = () => {
    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
        });
    }, [])

  return (
    <div className="pt-[5rem]">
        <div className="w-full bg-[#000] p-7">
            <div className="px-2 lg:px-4 max-w-[1240px] mx-auto">
                <div className="text-white flex gap-2 items-center">
                    <Link to="/" className="cursor-pointer font-bold">
                        Home
                    </Link>{" "}
                    <MdChevronRight className="inline" />{" "}
                    <span className="text-gray-300">Booked Flight</span>
                </div>
            </div>
      </div>

      <div className='flex flex-col items-center justify-center py-16 text-center'>
        <div className="pb-5">
            <h2 className='font-bold text-3xl'>Congratulations!!!</h2>
            <p className='text-md'>You've successfully created a Flight Order</p>
        </div>
        <div className="pb-5">
            <img src={SuccessImg} alt="Success" className='w-[205px] h-[205px]'/>
        </div>
        <div className="pb-5">
            <h2 className='font-bold text-3xl'>Flight Order Created</h2>
        </div>
        <div className='pb-5'>
            <p className='text-gray-600'>Booking Reference</p>
            <p className='bg-gray-300 py-3 px-6 font-bold rounded-md'>
                {localStorage.getItem("booking_ref").toUpperCase()}
            </p>
        </div>
        <div className='pb-5'>
            <p className='text-gray-600'>Order Number</p>
            <p className='bg-gray-300 py-3 px-4 font-bold rounded-md'>
                {localStorage.getItem("order_number").toUpperCase()}
            </p>
        </div>
        <div className='py-5'>
            <Link to="/" className="bg-black py-3 px-6 rounded-md text-white">
                Back to Explore Cities
            </Link>
        </div>
      </div>
    </div>
  )
}

export default FlightSuccessPage