import React, { useEffect, useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../slices/authSlice";
import ClipLoader from "react-spinners/ClipLoader";

const ForgotPasswordPage = () => {
  const initialValue = {
    email: "",
  };
  const [user, setUser] = useState(initialValue);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  //   console.log(auth);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    // console.log(user);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(forgotPassword(user));
  };
  return (
    <div className="pt-[5rem]">
      <div className="max-w-[400px] mx-auto min-h-[600px] px-2 py-20">
        <div className="bg-[#F5F5F5] shadow-lg px-10 py-8 rounded-lg relative">
          <h1 className="text-2xl text-center font-bold">
            Recover your password
          </h1>
          <p className="pt-3 text-center">
            You can request a password reset below. We will send a link to your
            email address, please make sure it is correct.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="my-4">
              <label>Email Address</label>
              <div className="my-2 w-full relative">
                <input
                  className="w-full p-2 border border-gray-400 bg-transparent rounded-lg"
                  type="email"
                  placeholder="Enter Email Address"
                  name="email"
                  value={user.email}
                  onChange={handleChange}
                />
                <AiOutlineMail className="absolute right-2 top-3 text-gray-400" />
              </div>
            </div>

            <button className="w-full my-4 md:my-2 p-3 bg-[#000] text-white rounded-lg">
              {auth.registerStatus === "pending" ? (
                <ClipLoader size={20} color={"#fff"} />
              ) : (
                "Request Password Reset"
              )}
            </button>
            {auth.registerStatus === "rejected" ? (
              <p className="text-sm text-red-600">{auth.registerError}</p>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
