import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdChevronRight } from "react-icons/md";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ClipLoader } from "react-spinners";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import axios from "axios";
import {toast} from 'react-toastify'
import { baseUrl } from "../../utils/baseUrl";
import { getMonth, getYear } from 'date-fns';
import range from "lodash/range";
import moment from 'moment'

const Gender = [
  { value: "m", label: "Male" },
  { value: "f", label: "Female" },
];

const Title = [
  { value: "mr", label: "Mr" },
  { value: "dr", label: "Dr" },
  { value: "mrs", label: "Mrs" },
  { value: "ms", label: "Miss" },
];

const BookFlight = () => {
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [passengerForms, setPassengers] = useState([]);
  const navigate = useNavigate();

  const handleChange = (e, passenger) => {
    const copy = [...passengerForms];
    const passengerDataIndex = passengerForms.findIndex(
      (form) => passenger === form.passenger
    );
    const newObj = {
      ...passengerForms[passengerDataIndex],
      [e.target.name]: e.target.value,
    };
    copy.splice(passengerDataIndex, 1, newObj);
    setPassengers(copy);

    // console.log(copy);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    const passengers = JSON.parse(localStorage.getItem("passengers"));

    const passengersData = passengers.map((passenger) => {
      return {
        ...passenger,
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        gender: "",
        born_on: "",
        title: "",
        open: false,
      };
    });
    setPassengers(passengersData);
    // console.log("passengers", passengersData);
    // console.log(passengers.length);
  }, []);

  const validation = (passengerForms) => {
    const errorss = {};
    const regex = /\S+@\S+\.\S+/;
    if (passengerForms.first_name === "") {
      errorss.first_name = "First Name is Required!";
    }
    if (passengerForms.last_name === "") {
      errorss.last_name = "Last Name is Required!";
    }
    if (passengerForms.phone === "") {
      errorss.phone = "Phone Number is Required!";
    }
    if (passengerForms.gender === "") {
      errorss.gender = "Gender is Required!";
    }
    if (passengerForms.title === "") {
      errorss.gender = "Title is Required!";
    }
    if (passengerForms.born_on === "") {
      errorss.gender = "Date of Birth is Required!";
    }
    if (passengerForms.email === "") {
      errorss.email = "Email Address is Required!";
    } else if (regex.test(passengerForms.email)) {
      errorss.email = "This is not a valid email address";
    }

    return errorss;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors(validation(passengerForms));

    if (passengerForms.email !== "") {
      try {
        await axios
          .post(
            `${baseUrl}/book_flight/`,
            {
              offer_id: localStorage.getItem("offer_id"),
              passengers: passengerForms.map((form) => {
                return {
                  given_name: form.first_name,
                  family_name: form.last_name,
                  phone_number: form.phone,
                  email: form.email,
                  // born_on: form.born_on.toISOString().split("T")[0],
                  born_on: moment.parseZone(form.born_on).format().split("T")[0],
                  title: form.title,
                  gender: form.gender,
                  id: form.passenger,
                };
              }),
            },
            setLoading(true)
          )
          .then((response) => {
            // console.log(response);
            // console.log(response.data);
            // console.log(response.data.errors[0].message)
            if (response.data.data?.id){
              setLoading(false);
              // console.log(response.data.data);
              navigate("/flight-success");
              window.localStorage.setItem(
                "order_number",
                JSON.stringify(response.data.data.id)
              );
              window.localStorage.setItem(
                "booking_ref",
                JSON.stringify(response.data.data.booking_reference)
              );
            } else if (response.data.errors[0]?.code === "invalid_phone_number") {
               setLoading(false);
              //  console.log(response.data.errors[0].message)
               toast.error(
                 `Please input the correct country code and number`,
                 { position: "top-right" }
                );
             } else if (response.data.meta?.status === 422 ) {
              setLoading(false);
              // console.log(response.data.errors[0].message)
              toast.error(
                `${response.data.errors[0].message}`,
                { position: "top-right" }
                ); 
              } else if (response.data.meta?.status === 500) {
                setLoading(false);
                // console.log(response.data.errors[0].message)
                toast.error(
                  `${response.data.errors[0].message}`,
                  { position: "top-right" }
                  );
                } else {
                  setLoading(false)
                  toast.error(
                    `An Error occured, Please try again!`,
                    { position: "top-right" }
                    );
                }   
          })
      } catch (error) {
        setLoading(false);
        console.log(error);
        if (error.message === "Network Error") {
          toast.error(`Unable to book flight, Please try again`, {
            position: "top-right",
          });
        }
      }
    }
  };

  // async function handleSubmit(e) {
  //   e.preventDefault();
  //   try {
  //     setLoading(true);
  //     const response = await fetch(
  //       `${baseUrl}/book_flight/`,
  //       {
  //         method: "POST",
  //         headers: {
  //           'Accept': 'application/json',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           offer_id: localStorage.getItem("offer_id"),
  //           passengers: passengerForms.map((form) => {
  //             return {
  //               given_name: form.first_name,
  //               family_name: form.last_name,
  //               phone_number: form.phone,
  //               email: form.email,
  //               born_on: form.born_on.toISOString().split("T")[0],
  //               title: form.title,
  //               gender: form.gender,
  //               id: form.passenger,
  //             };
  //           }),
  //         }),
  //       }
  //     );
  //     console.log(response);
  //     console.log(response.data.errors[0].message)
  //     setLoading(false);
  //     if (response.data.data.live_mode === false){
  //       // setLoading(false);
  //       console.log(response.data.data);
  //       navigate("/flight-success");
  //       window.localStorage.setItem(
  //         "order_number",
  //         JSON.stringify(response.data.data.id)
  //       );
  //       window.localStorage.setItem(
  //         "booking_ref",
  //         JSON.stringify(response.data.data.booking_reference)
  //       );
  //     }
  //     if (response.data.meta.status === 422) {
  //       setLoading(false);
  //       console.log(response.data.errors[0].message)
  //       toast.error(
  //         `${response.data.errors[0].message}`,
  //         { position: "top-right" }
  //         );
  //       }
  //       if (response.data.meta.status === 500) {
  //         setLoading(false);
  //         console.log(response.data.errors[0].message)
  //         toast.error(
  //           `${response.data.errors[0].message}`,
  //           { position: "top-right" }
  //           );
  //         }
  //   } catch (error) {
  //     // console.error(error);
  //     setLoading(false);
  //     if (error.message === "Network Error") {
  //       toast.error(`Unable to book flight, Please try again`, {
  //         position: "top-right",
  //       });
  //     }
  //   }
  // }

  const openForm = (index) => {
    const passenger = passengerForms[index];
    const copy = [...passengerForms];

    const newObj = {
      ...passenger,
      open: !passenger.open,
    };
    // setValues(newObj);
    copy.splice(index, 1, newObj);
    setPassengers(copy);
    setCurrentIndex(index);
  };
  const goBack = () => {
    navigate(-1);
  };

  const styles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "transparent",
      height: "3rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      borderWidth: "1px",
      borderColor: "#555353",
      outline: "2px solid transparent",
      outlineOffset: "2px",
      borderRadius: "0.375rem",
      transitionDuration: "200ms",
      transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
    }),
  };

  const years = range(1900, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <div className="pt-[5rem]">
      <div className="w-full bg-[#000] p-7">
        <div className="px-2 lg:px-4 max-w-[1240px] mx-auto">
          <div className="text-white flex gap-2 items-center">
            <div onClick={goBack} className="cursor-pointer font-bold">
              Payment
            </div>{" "}
            <MdChevronRight className="inline" />{" "}
            <span className="text-gray-300">Book Flight</span>
          </div>
        </div>
      </div>

      <div className="max-w-[380px] sm:max-w-[500px] lg:max-w-[800px] m-auto px-4 py-16">
        <div className="bg-[#F5F5F5] shadow-xl px-10 py-8 rounded-lg">
          <h1 className="text-2xl font-bold">Passenger Details</h1>

          <div>
            {passengerForms.map((passenger, index) => (
              <div key={index}>
                <div className="flex flex-wrap items-center justify-between border border-black rounded-md py-2 my-4 px-4 ">
                  <div className="hidden md:block">
                    <h2 className="font-bold uppercase">Passenger Info</h2>
                    <p className="text-xs tracking-tighter">{passenger.passenger.toUpperCase()} - {passenger.type.toUpperCase()}</p>
                  </div>
                  <div className="block md:hidden">
                    <h2 className="font-bold uppercase">Passenger Info</h2>
                    <p className="text-xs tracking-tighter">{passenger.type.toUpperCase()}</p>
                  </div>
                  {passenger.open ? (
                    <HiChevronUp
                      className="inline cursor-pointer"
                      size={22}
                      onClick={() => openForm(index)}
                    />
                  ) : (
                    <HiChevronDown
                      className="inline cursor-pointer"
                      size={22}
                      onClick={() => openForm(index)}
                    />
                  )}
                </div>
                  <form onSubmit={handleSubmit}>
                  {passenger.open ? (
                  <div>
                    <div className="grid lg:grid-cols-2 lg:gap-8">
                      <div className="mt-4 mb-2 lg:hidden">
                        <label>First Name</label>
                        <div className="my-2 w-full relative">
                          <input
                            className="w-full p-2 border border-gray-400 bg-transparent rounded-lg"
                            type="text"
                            placeholder="Enter First Name"
                            name="first_name"
                            value={passenger.first_name}
                            onChange={(e) =>
                              handleChange(e, passenger.passenger)
                            }
                            required
                          />
                          {/* {errors.first_name && ( */}
                            <p className="text-sm text-red-600">
                              {errors.first_name}
                            </p>
                          {/* )} */}
                        </div>
                      </div>

                      <label className="relative hidden lg:block mt-9 mb-4">
                        <input
                          className="h-12 w-[330px] px-4 border border-[#555353] outline-none focus:border-[#000] focus:text-[#000] text-gray-400 transition duration-200 bg-transparent rounded-md py-3"
                          type="text"
                          placeholder="Enter First Name"
                          name="first_name"
                          value={passenger.first_name}
                          onChange={(e) => handleChange(e, passenger.passenger)}
                          required
                        />
                        {errors.first_name && (
                            <p className="text-sm text-red-600">
                              {errors.first_name}
                            </p>
                          )}
                        <span className="text-xl absolute left-0 top-2 mx-4 text-[#000] bg-[#F5F5F5] transform -translate-y-6 -translate-x-3 scale-75 font-bold">
                          First Name*
                        </span>
                      </label>

                      <div className="my-2 lg:hidden">
                        <label>Last Name</label>
                        <div className="my-2 w-full relative">
                          <input
                            className="w-full p-2 border border-gray-400 bg-transparent rounded-lg"
                            type="text"
                            placeholder="Enter Last Name"
                            name="last_name"
                            value={passenger.last_name}
                            onChange={(e) =>
                              handleChange(e, passenger.passenger)
                            }
                            required
                          />
                          {errors.last_name && (
                            <p className="text-sm text-red-600">
                              {errors.last_name}
                            </p>
                          )}
                        </div>
                      </div>

                      <label className="relative hidden lg:block mt-9 mb-4">
                        <input
                          className="h-12 w-[330px] px-4 border border-[#555353] outline-none focus:border-[#000] focus:text-[#000] text-gray-400 transition duration-200 bg-transparent rounded-md py-3"
                          text="text"
                          placeholder="Enter Last Name"
                          name="last_name"
                          value={passenger.last_name}
                          onChange={(e) => handleChange(e, passenger.passenger)}
                          required
                        />
                        {errors.last_name && (
                            <p className="text-sm text-red-600">
                              {errors.last_name}
                            </p>
                          )}
                        <span className="text-xl absolute left-0 top-2 mx-4 text-[#000] bg-[#F5F5F5] transform -translate-y-6 -translate-x-3 scale-75 font-bold">
                          Last Name*
                        </span>
                      </label>
                    </div>

                    <div className="grid lg:grid-cols-2 gap-2 lg:gap-8">
                      <div className="my-2 lg:hidden">
                        <label>Email Address</label>
                        <div className="my-2 w-full relative">
                          <input
                            className="w-full p-2 border border-gray-400 bg-transparent rounded-lg"
                            type="email"
                            placeholder="Enter Email Address"
                            name="email"
                            value={passenger.email}
                            onChange={(e) =>
                              handleChange(e, passenger.passenger)
                            }
                            required
                          />
                          {errors.email && (
                            <p className="text-sm text-red-600">
                              {errors.email}
                            </p>
                          )}
                        </div>
                      </div>

                      <label className="relative hidden lg:block mt-9 mb-4">
                        <input
                          className="h-12 w-[330px] px-4 border border-[#555353] outline-none focus:border-[#000] focus:text-[#000] text-gray-400 transition duration-200 bg-transparent rounded-md py-3"
                          type="email"
                          placeholder="Enter Email Address"
                          name="email"
                          value={passenger.email}
                          onChange={(e) => handleChange(e, passenger.passenger)}
                          required
                        />
                        {errors.email && (
                          <p className="text-sm text-red-600">{errors.email}</p>
                        )}
                        <span className="text-xl absolute left-0 top-2 mx-4 text-[#000] bg-[#F5F5F5] transform -translate-y-6 -translate-x-3 scale-75 font-bold">
                          Email Address*
                        </span>
                      </label>

                      <div className="mb-2 lg:hidden">
                        <label>Phone Number</label>
                        <div className="my-2 w-full relative">
                          <input
                            className="w-full p-2 border border-gray-400 bg-transparent rounded-lg"
                            type="tel"
                            placeholder="Enter Phone Number"
                            name="phone"
                            value={passenger.phone}
                            onChange={(e) =>
                              handleChange(e, passenger.passenger)
                            }
                            required
                          />
                          {errors.phone && (
                            <p className="text-sm text-red-600">
                              {errors.phone}
                            </p>
                          )}
                        </div>
                      </div>

                      <label className="relative hidden lg:block mt-9 mb-4">
                        <input
                          className="h-12 w-[330px] px-4 border border-[#555353] outline-none focus:border-[#000] focus:text-[#000] text-gray-400 transition duration-200 bg-transparent rounded-md py-3"
                          type="tel"
                          placeholder="Enter Phone Number"
                          name="phone"
                          value={passenger.phone}
                          onChange={(e) => handleChange(e, passenger.passenger)}
                          required
                        />
                        {errors.phone && (
                            <p className="text-sm text-red-600">
                              {errors.phone}
                            </p>
                          )}
                        <span className="text-xl absolute left-0 top-2 mx-4 text-[#000] bg-[#F5F5F5] transform -translate-y-6 -translate-x-3 scale-75 font-bold">
                          Phone Number*
                        </span>
                      </label>
                    </div>

                    <div className="grid lg:grid-cols-2 gap-2 lg:gap-8">
                      <div className="my-2 lg:hidden">
                        <label>Gender</label>
                        <div className="my-2 w-full relative">
                          <Select
                            defaultValue={selectedGender}
                            options={Gender}
                            onChange={(e) => {
                              handleChange(
                                { target: { name: "gender", value: e.value } },
                                passenger.passenger
                              );
                            }}
                            name="gender"
                            styles={styles}
                            placeholder="Select Gender..."
                            // required
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: "grey",
                                primary: "black",
                              },
                            })}
                          />
                          {errors.gender && (
                            <p className="text-sm text-red-600">
                              {errors.gender}
                            </p>
                          )}
                        </div>
                      </div>

                      <label className="relative hidden lg:block mt-9 mb-4">
                        <Select
                          defaultValue={selectedGender}
                          onChange={(e) => {
                            handleChange(
                              { target: { name: "gender", value: e.value } },
                              passenger.passenger
                            );
                          }}
                          name="gender"
                          options={Gender}
                          styles={styles}
                          placeholder="Select Gender..."
                          // required
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "grey",
                              primary: "black",
                            },
                          })}
                        />
                        {errors.gender && (
                            <p className="text-sm text-red-600">
                              {errors.gender}
                            </p>
                          )}
                        <span className="text-xl absolute left-0 top-2 mx-4 text-[#000] bg-[#F5F5F5] transform -translate-y-6 -translate-x-3 scale-75 font-bold">
                          Gender*
                        </span>
                      </label>

                      <div className="mb-2 lg:hidden">
                        <label>Title</label>
                        <div className="my-2 w-full relative">
                          <Select
                            defaultValue={selectedTitle}
                            onChange={(e) => {
                              handleChange(
                                { target: { name: "title", value: e.value } },
                                passenger.passenger
                              );
                            }}
                            name="title"
                            options={Title}
                            styles={styles}
                            placeholder="Select Title..."
                            // required
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: "grey",
                                primary: "black",
                              },
                            })}
                          />
                          {errors.title && (
                            <p className="text-sm text-red-600">
                              {errors.title}
                            </p>
                          )}
                        </div>
                      </div>

                      <label className="relative hidden lg:block mt-9 mb-4">
                        <Select
                          defaultValue={selectedTitle}
                          onChange={(e) => {
                            handleChange(
                              { target: { name: "title", value: e.value } },
                              passenger.passenger
                            );
                          }}
                          name="title"
                          options={Title}
                          styles={styles}
                          placeholder="Select Title..."
                          // required
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "grey",
                              primary: "black",
                            },
                          })}
                        />
                        {errors.title && (
                            <p className="text-sm text-red-600">
                              {errors.title}
                            </p>
                          )}
                        <span className="text-xl absolute left-0 top-2 mx-4 text-[#000] bg-[#F5F5F5] transform -translate-y-6 -translate-x-3 scale-75 font-bold">
                          Title*
                        </span>
                      </label>
                    </div>

                    <div className="grid lg:grid-cols-2 gap-2 lg:gap-8">
                      <div className="my-2 lg:hidden">
                        <label>Date of Birth</label>
                        <div className="my-2 w-full relative">
                          <DatePicker
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                {"<"}
                              </button>
                              <select
                                value={getYear(date)}
                                onChange={({ target: { value } }) => changeYear(value)}
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                    
                              <select
                                value={months[getMonth(date)]}
                                onChange={({ target: { value } }) =>
                                  changeMonth(months.indexOf(value))
                                }
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                    
                              <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                {">"}
                              </button>
                            </div>
                          )}
                            onChange={(e) => {
                              handleChange(
                                { target: { name: "born_on", value: e } },
                                passenger.passenger
                              );
                            }}
                            value={
                              passenger.born_on
                                ? passenger.born_on
                                : ""
                            }
                            name="born_on"
                            showIcon
                            dateFormat="yyyy-MM-dd"
                            selected={passenger.born_on
                              ? passenger.born_on
                              : ""}
                            placeholderText="yyyy-mm-dd"
                            required
                            className="h-12 w-full px-4 border border-[#555353] outline-none focus:border-[#000] focus:text-[#000] transition duration-200 bg-transparent rounded-md"
                          />
                          {errors.born_on && (
                            <p className="text-sm text-red-600">
                              {errors.born_on}
                            </p>
                          )}
                        </div>
                      </div>

                      <label className="relative hidden lg:block mt-9 mb-4">
                        <DatePicker
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                              {"<"}
                            </button>
                            <select
                              value={getYear(date)}
                              onChange={({ target: { value } }) => changeYear(value)}
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                  
                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                  
                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                              {">"}
                            </button>
                          </div>
                        )}
                          onChange={(e) => {
                            handleChange(
                              { target: { name: "born_on", value: e } },
                              passenger.passenger
                            );
                          }}
                          value={
                            passenger.born_on
                              ? passenger.born_on
                              : ""
                          }
                          name="born_on"
                          showIcon
                          dateFormat="yyyy-MM-dd"
                          selected={passenger.born_on
                            ? passenger.born_on
                            : ""}

                          placeholderText="yyyy-mm-dd"
                          required
                          className="h-12 w-full px-4 border border-[#555353] outline-none focus:border-[#000] focus:text-[#000] transition duration-200 bg-transparent rounded-md"
                        />
                        {errors.born_on && (
                            <p className="text-sm text-red-600">
                              {errors.born_on}
                            </p>
                          )}
                        <span className="text-xl absolute left-0 top-2 mx-4 text-[#000] bg-[#F5F5F5] transform -translate-y-6 -translate-x-3 scale-75 font-bold">
                          Date of Birth*
                        </span>
                      </label>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                  <button
                    type="submit"
                    // onClick={handleSubmit}
                    className="w-full my-4 md:my-2 p-3 bg-[#000] text-white rounded-lg"
                  >
                    {loading ? (
                      <ClipLoader size={20} color={"#fff"} />
                    ) : (
                      "Book Flight"
                    )}
                  </button>
                </form>
              </div>
            ))}

          </div>
        </div>
      </div>
    </div>
  );
};

export default BookFlight;
