import React, { useEffect, useState } from "react";
import { MdChevronRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import {baseUrl} from '../../../utils/baseUrl'

const ReturnWayFlightDetails = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  const goBack = () => {
    navigate(-1);
    localStorage.removeItem("offer_id");
    localStorage.removeItem("price");
    localStorage.removeItem("aircraft_name");
    localStorage.removeItem("airline");
    localStorage.removeItem("airline_logo");
    localStorage.removeItem("cabin_class");
    localStorage.removeItem("duration");

    // departure 
    localStorage.removeItem("arrival_date");
    localStorage.removeItem("arrival_time");
    localStorage.removeItem("departure_date");
    localStorage.removeItem("departure_time");
    localStorage.removeItem("destination_city");
    localStorage.removeItem("destination_airport");
    localStorage.removeItem("origin_city");
    localStorage.removeItem("origin_airport");

    // return
    localStorage.removeItem("return_arrival_date");
    localStorage.removeItem("return_arrival_time");
    localStorage.removeItem("return_departure_date");
    localStorage.removeItem("return_departure_time");
    localStorage.removeItem("return_destination_city");
    localStorage.removeItem("return_destination_airport");
    localStorage.removeItem("return_origin_city");
    localStorage.removeItem("return_origin_airport");
  };

  const truncateString = (str, num) => {
    if (str?.length > num) {
      return str.slice(0, num) + "";
    } else {
      return str;
    }
  };

  const handleSubmit = async () => {
    try {
      await axios
        .post(
          `${baseUrl}/payment_intent/`,
          {
            offer_id: localStorage.getItem("offer_id"),
          },
          setLoading(true)
        )
        .then((response) => {
          console.log(response.data);
          setLoading(false);
          if (response.status === 200) {
            navigate("/payment");
            window.localStorage.setItem(
              "client_token",
              response.data.data.client_token
            );
            window.localStorage.setItem(
              "payment_intent_id",
              response.data.data.payment_intent_id
            );
            toast.success(
              `Flight Offer Selected!, Please input payment details`,
              {
                position: "top-right",
              }
            );
          }
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error) {
        toast.error(
            `${error.message}`,
            {
              position: "top-right",
            }
          );
      }
    }
  };
  return (
    <div className="pt-[5rem]">
      <div className="w-full bg-[#000] p-7">
        <div className="px-2 lg:px-4 max-w-[1240px] mx-auto">
          <div className="text-white flex gap-2">
            <div onClick={goBack} className="cursor-pointer font-bold">
              Available Flights
            </div>{" "}
            <div>
              <MdChevronRight className="inline" />{" "}
              <span className="text-gray-300">Flight Details</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center py-16">
        <div className="bg-[#f5f5f5] py-6 px-9 rounded-lg w-[350px] sm:w-[600px] md:w-[700px]">
          <h1 className="text-2xl font-bold text-center">Flight Details</h1>
          <hr />

          <div className="py-9 flex flex-col gap-8">
            <div className="flex items-center justify-center gap-4">
              <div className="w-16 h-16">
                <img src={localStorage.getItem("airline_logo")} alt="" />
              </div>
              <div className="text-xl font-bold">
                {localStorage.getItem("airline")}
              </div>
            </div>

            <div>
              <h2 className="font-bold text-xl">Departure Details</h2>
            </div>
            <div className="grid grid-cols-2 border-b-2 border-black">
              <div>
                <p className="text-[#363535]">Origin</p>{" "}
                <span className=" text-black font-bold">
                  {localStorage.getItem("origin_city")}
                </span>
              </div>

              <div>
                <p className="text-[#363535]">Origin Airport</p>{" "}
                <span className="text-black font-bold">
                  {localStorage.getItem("origin_airport")}
                </span>
              </div>
            </div>

            <div className="grid grid-cols-2 border-b-2 border-black">
              <div>
                <p className="text-[#363535]">Destination</p>{" "}
                <span className="text-black font-bold">
                  {localStorage.getItem("destination_city")}
                </span>
              </div>

              <div>
                <p className="text-[#363535]">Destination Airport</p>{" "}
                <span className="text-black font-bold">
                  {localStorage.getItem("destination_airport")}
                </span>
              </div>
            </div>

            <div className="grid grid-cols-2 border-b-2 border-black">
              <div>
                <p className="text-[#363535]">Departure Date</p>{" "}
                <span className="text-black font-bold">
                  {localStorage.getItem("departure_date")}
                </span>
              </div>

              <div>
                <p className="text-[#363535]">Departure Time </p>{" "}
                <span className="text-black font-bold">
                  {truncateString(localStorage.getItem("departure_time"), 5)}
                </span>
              </div>
            </div>

            <div className="grid grid-cols-2 border-b-2 border-black">
              <div>
                <p className="text-[#363535]">Arrival Date</p>{" "}
                <span className="text-black font-bold">
                  {localStorage.getItem("arrival_date")}
                </span>
              </div>

              <div>
                <p className="text-[#363535]">Arrival Time</p>{" "}
                <span className="text-black font-bold">
                  {truncateString(localStorage.getItem("arrival_time"), 5)}
                </span>
              </div>
            </div>

            <div className="grid grid-cols-2 border-b-2 border-black">
              <div>
                <p className="text-[#363535]">AirCraft Name</p>{" "}
                <span className="text-black font-bold">
                  {localStorage.getItem("aircraft_name")}
                </span>
              </div>

              <div>
                <p className="text-[#363535]">Cabin Class</p>{" "}
                <span className="text-black font-bold">
                  {localStorage.getItem("cabin_class").toUpperCase()}
                </span>
              </div>
            </div>

            {/* Return */}
            <div>
              <h2 className="font-bold text-xl">Return Details</h2>
            </div>
            <div className="grid grid-cols-2 border-b-2 border-black">
              <div>
                <p className="text-[#363535]">Origin</p>{" "}
                <span className=" text-black font-bold">
                  {localStorage.getItem("return_origin_city")}
                </span>
              </div>

              <div>
                <p className="text-[#363535]">Origin Airport</p>{" "}
                <span className="text-black font-bold">
                  {localStorage.getItem("return_origin_airport")}
                </span>
              </div>
            </div>

            <div className="grid grid-cols-2 border-b-2 border-black">
              <div>
                <p className="text-[#363535]">Destination</p>{" "}
                <span className="text-black font-bold">
                  {localStorage.getItem("return_destination_city")}
                </span>
              </div>

              <div>
                <p className="text-[#363535]">Destination Airport</p>{" "}
                <span className="text-black font-bold">
                  {localStorage.getItem("return_destination_airport")}
                </span>
              </div>
            </div>

            <div className="grid grid-cols-2 border-b-2 border-black">
              <div>
                <p className="text-[#363535]">Departure Date</p>{" "}
                <span className="text-black font-bold">
                  {localStorage.getItem("return_departure_date")}
                </span>
              </div>

              <div>
                <p className="text-[#363535]">Departure Time </p>{" "}
                <span className="text-black font-bold">
                  {truncateString(localStorage.getItem("return_departure_time"), 5)}
                </span>
              </div>
            </div>

            <div className="grid grid-cols-2 border-b-2 border-black">
              <div>
                <p className="text-[#363535]">Arrival Date</p>{" "}
                <span className="text-black font-bold">
                  {localStorage.getItem("return_arrival_date")}
                </span>
              </div>

              <div>
                <p className="text-[#363535]">Arrival Time</p>{" "}
                <span className="text-black font-bold">
                  {truncateString(localStorage.getItem("return_arrival_time"), 5)}
                </span>
              </div>
            </div>

            <div className="grid grid-cols-2 border-b-2 border-black">
              <div>
                <p className="text-[#363535]">AirCraft Name</p>{" "}
                <span className="text-black font-bold">
                  {localStorage.getItem("aircraft_name")}
                </span>
              </div>

              <div>
                <p className="text-[#363535]">Cabin Class</p>{" "}
                <span className="text-black font-bold">
                  {localStorage.getItem("cabin_class").toUpperCase()}
                </span>
              </div>
            </div>

            <button
              type="button"
              onClick={handleSubmit}
              className="w-full my-4 md:my-2 p-3 bg-[#000] text-white rounded-lg"
            >
              {loading ? (
                <ClipLoader size={20} color={"#fff"} />
              ) : (
                <p>
                  Proceed to Payment (£ {localStorage.getItem("price")}){" "}
                </p>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnWayFlightDetails;
