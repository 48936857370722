import React from 'react'
import { Link } from 'react-router-dom'
import {HiArrowNarrowRight} from 'react-icons/hi'
import { Blurhash } from "react-blurhash";
import { useState } from 'react';
import { useEffect } from 'react';

const Cities = ({city}) => {
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    const img = new Image()
    img.onload = () => {
      setImageLoaded(true)  
    }
    img.src = city.image
  }, [city.image])
  
  return (
    <div>
      <Link to={`/cities/${city._id}`}>
        <div className="group flex justify-center text-center relative overflow-hidden cursor-pointer w-[350px] xs:w-[370px] h-[300px] rounded-lg">
          {!imageLoaded && (
            <Blurhash
              hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
              width={400}
              height={300}
              resolutionX={32}
              resolutionY={32}
              punch={1}
            />
          )}
          {imageLoaded && (
            <img 
              src={city.image} 
              alt={city?.name} 
              className="rounded-lg object-cover ease-in-out duration-500 group-hover:scale-125 w-[350px] xs:w-[380px] h-[300px]"
            />
          )}
          <div className="absolute bg-gradient-to-r from-black opacity-10 w-[350px] xs:w-[380px] h-[300px] transition-opacity duration-500 group-hover:opacity-70">
            <p className='white-space-normal font-bold justify-center items-center h-full text-center          hover:text-white flex text-transparent text-lg'>Explore {city ? city?.name : "Coming Soon"} <HiArrowNarrowRight className='inline ml-3'/></p>
          </div>
        </div>
      </Link>
      <p className='py-2 font-bold text-xl'>{city ? city?.name : ""}, {city ? city?.country : "Coming soon"}</p>
    </div>
  )
}

export default Cities