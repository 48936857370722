import React, { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import {MdChevronLeft, MdChevronRight} from 'react-icons/md'

const Modal = ({ clickedImg, setClickedImg, handleRotationRight, handleRotationLeft, loading, Loading}) => {
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      setClickedImg(null);
    }
  };

  return (
    <div
      className="dismiss bg-black/95 w-full h-full fixed top-0 left-0 right-0 flex items-center justify-center z-[99999]"
      onClick={handleClick}
    >
      <img
        src={clickedImg}
        alt=""
        className="block object-contain sm:w-[70%] lg:w-[40%] mx-auto pointer-events-none" 
      />
      <AiOutlineClose
        size={25}
        className="dismiss absolute top-[1rem] sm:top-[1rem] right-[1rem] z-[99999] text-white cursor-pointer rounded-full"
        onClick={() => setClickedImg(null)}
      />
      <MdChevronLeft onClick={handleRotationLeft} size={40} className="sm:bg-[#ffffff] text-white sm:text-black rounded-full absolute sm:opacity-50 hover:opacity-100 cursor-pointer z-[999] left-0 flex justify-between top-[50%] w-[50px] h-[50px]"/>
      <MdChevronRight onClick={handleRotationRight} className="sm:bg-[#ffffff] text-white sm:text-black rounded-full absolute sm:opacity-50 hover:opacity-100 cursor-pointer z-[999] right-0 flex justify-between top-[50%] w-[50px] h-[50px]"/>
    </div>
  );
};



export default Modal;
