import React, { useEffect, useState } from "react";
import citybg from "../images/citybg2.jpeg";
import frames from "../images/frames.jpeg";
import videography from "../images/videography4.jpg";
import articles from "../images/articles.jpg";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import axios from "axios";
import FadeLoader from "react-spinners/FadeLoader";
import { GiCommercialAirplane } from "react-icons/gi";
import { baseUrl } from "../utils/baseUrl";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import LoadingModal from "../components/LoadingModal";

const CityPage = () => {
  const [city, setCity] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { cityId } = useParams();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  // console.log(auth);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  const url = `${baseUrl}/cities/${cityId}`;

  useEffect(() => {
    if (!auth.token) {
      setLoading(true);
      axios.get(url).then((response) => {
        setCity(response.data.results);
        setCityData(response.data.city_data);
        // console.log(response.data);
        setLoading(false);
      });
    } else {
      setLoading(true);
      axios
        .get(url, {
          headers: {
            Authorization: `${auth.token}`,
          },
        })
        .then((response) => {
          setCity(response.data.results);
          setCityData(response.data.city_data);
          // console.log(response.data);
          setLoading(false);
        });
    }
  }, [url, auth.token]);

  const Loading = () => {
    return (
      <div className="flex flex-wrap items-center justify-center h-screen">
        <FadeLoader color={"#000"} loading={loading} size={150} />
        <p className="text-2xl ">Loading City...</p>
      </div>
    );
  };

  function closeLoadingModal() {
    setIsLoading(false);
  }

  const handleChange = async () => {
    if (auth._id && cityData.liked === false) {
      setIsLoading(true);
      try {
        await axios
          .post(
            `${baseUrl}/cities/${cityId}/likes/`,
            {},
            {
              headers: {
                Authorization: `${auth.token}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            setIsLoading(false);
            if (response.status === 201) {
              toast.success(`You liked ${city[0]?.city}.`, {
                position: "top-right",
              });

              const reloadPage = setTimeout(() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                });
                window.location.reload();
              }, 1000);

              return () => clearTimeout(reloadPage);
            }
          });
      } catch (err) {
        setIsLoading(false);
        toast.error(`Please Try Again`, { position: "top-right" });
        // console.log(err.response);
      }
    } else if (cityData.liked === true) {
      try {
        setIsLoading(true);
        await axios
          .post(
            `${baseUrl}/cities/${cityId}/likes/`,
            {},
            {
              headers: {
                Authorization: `${auth.token}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            setIsLoading(false);
            if (response.status === 201) {
              toast.success(`You unliked ${city[0]?.city}.`, {
                position: "top-right",
              });

              const reloadPage = setTimeout(() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                });
                window.location.reload();
              }, 1000);

              return () => clearTimeout(reloadPage);
            }
          });
      } catch (err) {
        setIsLoading(false);
        toast.error(`Please Try Again`, { position: "top-right" });

        // console.log(err.response);
      }
    } else {
      navigate("/login");
      toast.info(`Hi there!, Please login to like a city.`, {
        position: "top-right",
      });
    }
  };

  const ShowCity = () => {
    return (
      <div>
        <div className="w-full h-[100vh] xs:h-[98vh] lg:h-[90vh]  text-white">
          <div className="w-full h-full">
            <div className="absolute w-full h-[100vh] xs:h-[98vh] lg:h-[90vh] bg-gradient-to-r from-black"></div>
            <img
              className="w-full h-full object-cover object-center"
              src={citybg}
              alt="/"
            />

            <div className="absolute w-full top-[15%] xs:top-[25%] md:top-[20%] lg:top-[25%] p-4 lg:p-8">
              <div className="max-w-[1240px] px-2 lg:px-4 mx-auto">
                <h1 className="text-4xl font-bold md:text-6xl py-4">
                  The City Of {city ? city[0]?.city : "..."}
                </h1>
                <p className="sm:w-[45rem] text-xl pt-2 pb-11 md:pb-6 lg:pb-11 tracking-widest font-semibold">
                  Explore the city of {city ? city[0]?.city : "..."} through
                  their foods, lifestyle and festivals.
                </p>
                <div className="flex items-center gap-8 flex-wrap">
                  <Link
                    to={`/frames`}
                    className="border bg-transparent hover:bg-white text-white hover:text-black border-white hover:border-black shadow-md hover:shadow-xl px-6 py-4 font-bold rounded-md text-xl cursor-pointer"
                  >
                    Buy a frame
                  </Link>

                  <div className="flex items-center gap-8">
                    {auth._id && auth.first_name ? (
                      <Link to={`${cityId}/flights`}>
                        <button className="border bg-transparent hover:bg-white cursor-pointer text-white hover:text-black border-white hover:border-black shadow-md hover:shadow-xl px-6 py-4 font-bold rounded-md text-xl group flex items-center">
                          Visit {city ? city[0]?.city : "..."}{" "}
                          <span className="group-hover:rotate-45 duration-300">
                            <GiCommercialAirplane className="ml-3" />
                          </span>
                        </button>
                      </Link>
                    ) : (
                      <Link to="/login">
                        <button className="border bg-transparent hover:bg-white cursor-pointer text-white hover:text-black border-white hover:border-black shadow-md hover:shadow-xl px-6 py-4 font-bold rounded-md text-xl group flex items-center">
                          Visit {city ? city[0]?.city : "..."}{" "}
                          <span className="group-hover:rotate-45 duration-300">
                            <GiCommercialAirplane className="ml-3" />
                          </span>
                        </button>
                      </Link>
                    )}

                    <div
                      onClick={handleChange}
                      className="cursor-pointer relative"
                    >
                      {cityData.liked ? (
                        <AiFillHeart size={35} color={"#FF0000"} />
                      ) : (
                        <AiOutlineHeart size={35} />
                      )}
                      {cityData.likes_count > 0 ? (
                        <div className="absolute -top-2 left-6 w-5 h-5 rounded-full flex items-center justify-center">
                          <p className="text-sm text-white font-semibold">
                            {cityData.likes_count}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[1240px] px-2 lg:px-4 mx-auto py-16">
          <div className="flex flex-col items-center justify-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-12">
              <Link to={`${cityId}/foods`}>
                <div className="group flex justify-center text-center relative overflow-hidden cursor-pointer  w-[350px] xs:w-[375px] h-[350px] rounded-xl">
                  <img
                    src={city[0]?.image}
                    alt="/"
                    className="rounded-xl object-cover ease-in-out duration-500 group-hover:scale-125 group-hover:rotate-6  w-[350px] xs:w-[380px] h-[350px]"
                  />
                  <div className="absolute  w-[350px] xs:w-[380px] h-[350px] duration-500">
                    <p className="white-space-normal font-bold justify-center items-center h-full text-center text-white flex text-2xl">
                      Restaurants
                    </p>
                  </div>
                </div>
              </Link>

              <Link to={`${cityId}/lifestyles`}>
                <div className="group flex justify-center text-center relative overflow-hidden cursor-pointer  w-[350px] xs:w-[375px] h-[350px] rounded-xl">
                  <img
                    src={city[1]?.image}
                    alt="/"
                    className="rounded-xl object-cover ease-in-out duration-500 group-hover:scale-125 group-hover:rotate-6  w-[350px] xs:w-[380px] h-[350px]"
                  />
                  <div className="absolute  w-[350px] xs:w-[380px] h-[350px] duration-500">
                    <p className="white-space-normal font-bold justify-center items-center h-full text-center text-white flex text-2xl">
                      Lifestyles
                    </p>
                  </div>
                </div>
              </Link>

              <Link to={`${cityId}/booklets`}>
                <div className="group flex justify-center text-center relative overflow-hidden cursor-pointer  w-[350px] xs:w-[375px] h-[350px] rounded-xl">
                  <img
                    src={city[2]?.image}
                    alt="/"
                    className="rounded-xl object-cover ease-in-out duration-500 group-hover:scale-125 group-hover:rotate-6  w-[350px] xs:w-[380px] h-[350px]"
                  />
                  <div className="absolute  w-[350px] xs:w-[380px] h-[350px] duration-500">
                    <p className="white-space-normal font-bold justify-center items-center h-full text-center text-white flex text-2xl">
                      Travel Guide
                    </p>
                  </div>
                </div>
              </Link>

              {/* <div>
                <Link to={`${cityId}/videos`}>
                  <div className="group flex justify-center text-center relative overflow-hidden cursor-pointer  w-[350px] xs:w-[380px] h-[350px] rounded-xl">
                    <img
                      src={videography}
                      alt="/"
                      className="rounded-xl object-cover ease-in-out duration-500 group-hover:scale-125 group-hover:rotate-6  w-[350px] xs:w-[380px] h-[350px]"
                    />
                    <div className="absolute  w-[350px] xs:w-[380px] h-[350px] duration-500">
                      <p className="white-space-normal font-bold justify-center items-center h-full text-center text-white flex text-2xl">
                        Videography
                      </p>
                    </div>
                  </div>
                </Link>
              </div> */}

              <div>
                <Link to={`${cityId}/articles`}>
                  <div className="group flex justify-center text-center relative overflow-hidden cursor-pointer  w-[350px] xs:w-[375px] h-[350px] rounded-xl">
                    <img
                      src={articles}
                      alt="/"
                      className="rounded-xl object-cover ease-in-out duration-500 group-hover:scale-125 group-hover:rotate-6  w-[350px] xs:w-[380px] h-[350px]"
                    />
                    <div className="absolute w-[350px] xs:w-[380px] h-[350px] duration-500 bg-black/20">
                      <p className="white-space-normal font-bold justify-center items-center h-full text-center text-white flex text-2xl">
                        Articles
                      </p>
                    </div>
                  </div>
                </Link>
              </div>

              <Link to={`/frames`}>
                <div className="group flex justify-center text-center relative overflow-hidden cursor-pointer  w-[350px] xs:w-[375px] h-[350px] rounded-xl">
                  <img
                    src={frames}
                    alt="/"
                    className="rounded-xl object-cover ease-in-out duration-500 group-hover:scale-125 group-hover:rotate-6  w-[350px] xs:w-[380px] h-[350px]"
                  />
                  <div className="absolute  w-[350px] xs:w-[380px] h-[350px] duration-500">
                    <p className="white-space-normal font-bold justify-center items-center h-full text-center text-white flex text-2xl">
                      Frames
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        {isLoading ? (
          <LoadingModal isLoading={isLoading} closeLoadingModal={closeLoadingModal} />
        ) : (
          ""
        )}
        <Footer />
      </div>
    );
  };

  return (
    <div>
      <div>{loading ? <Loading /> : <ShowCity />}</div>
    </div>
  );
};

export default CityPage;
