import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiPlus, BiMinus } from "react-icons/bi";
import { GiCommercialAirplane } from "react-icons/gi";
import { MdAirplaneTicket, MdLocationPin } from "react-icons/md";
import { useParams, useNavigate} from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ClipLoader } from "react-spinners";
import { baseUrl } from "../../utils/baseUrl";
import { toast } from "react-toastify";
import csv from "./airport.csv"
import moment from 'moment'

const cabinClass = [
  { value: "economy", label: "Economy" },
  { value: "business", label: "Business" },
  { value: "first class", label: "First Class" },
  { value: "premium economy", label: "Premium Economy" },
];

const SearchFlights = () => {
  const [city, setCity] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { cityId } = useParams();
  const [selectedClass, setSelectedClass] = useState(null);
  // const [selectedOrigin, setSelectedOrigin] = useState(null);
  const [data, setData] = useState([]);
  const [selectedAirport, setSelectedAirport] = useState("");
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [infantCount, setInfantCount] = useState(0);
  const [departureDate, setDepartureDate] = useState();
  const [returnDate, setReturnDate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [returnTrip, setReturnTrip] = useState(false);
  // const [flights, setFlights] = useState([]);
  const navigate = useNavigate();

  const handleReturnTrip = () => {
    setReturnTrip(true);
  };

  const handleOneWayTrip = () => {
    setReturnTrip(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(csv);
      const text = await response.text();
      const rows = text.split("\n").slice(1);
      const options = rows.map((row) => {
        const cols = row.split(",");
        return { value: cols[0], label: cols[1] };
      });
      setData(options);
    };
    fetchData();
  }, []); // Empty dependency array

  const handleSelect = (selectedOption) => {
    setSelectedAirport(selectedOption);
    console.log(selectedOption);
  };

  const url = `${baseUrl}/cities/${cityId}`;

  useEffect(() => {
    setLoading(true);
    axios.get(url).then((response) => {
      setCity(response.data);
      setCityData(response.data.city_data);
      // console.log(response.data);
      setLoading(false);
    });
  }, [url]);

  const Loading = () => {
    return (
      <div className="flex flex-wrap items-center justify-center h-screen">
        <FadeLoader color={"#000"} loading={loading} size={150} />
        <p className="text-2xl ">Loading...</p>
      </div>
    );
  };

  const styles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "transparent",
      height: "3rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      borderWidth: "1px",
      borderColor: "#555353",
      outline: "2px solid transparent",
      outlineOffset: "2px",
      borderRadius: "0.375rem",
      transitionDuration: "200ms",
      transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
    }),
  };

  const handleOneWayForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios
        .get(
          `${baseUrl}/flights/?origin=${
            selectedAirport.value
          }&destination=${cityData.iata_code}&departure_date=${
            // departureDate.toISOString().split("T")[0]
            moment.parseZone(departureDate).format().split("T")[0]
          }&adult=${adultCount}&journey_type=oneway&cabin_class=${
            selectedClass.value
          }&child=${childCount}&infant=${infantCount}&page=1`
        )
        .then((response) => {
          console.log(response.data.results);
          // setFlights(response.data.results.flight_offers);
          navigate("/one-way-available-flights");
          window.localStorage.setItem(
            "flights",
            JSON.stringify(response.data.results.flight_offers)
          );
          window.localStorage.setItem(
            "passengers",
            JSON.stringify(response.data.results.passenger_ids_and_type)
          );
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (error.message === "Network Error") {
        toast.error(`Unable to search for flights, Please try again`, {
          position: "top-right",
        });
      }
    }
  };

  const handleReturnWayForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios
        .get(
          `${baseUrl}/flights/?origin=${
            selectedAirport.value
          }&destination=${cityData.iata_code}&departure_date=${
            // departureDate.toISOString().split("T")[0]
            moment.parseZone(departureDate).format().split("T")[0]
          }&return_date=${
            // returnDate.toISOString().split("T")[0]
            moment.parseZone(returnDate).format().split("T")[0]
          }&adult=${adultCount}&journey_type=return&cabin_class=${
            selectedClass.value
          }&child=${childCount}&infant=${infantCount}&page=1`
        )
        .then((response) => {
          console.log(response.data.results);
          navigate("/return-way-available-flights");
          window.localStorage.setItem(
            "flights",
            JSON.stringify(response.data.results.flight_offers)
          );
          window.localStorage.setItem(
            "passengers",
            JSON.stringify(response.data.results.passenger_ids_and_type)
          );
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (error.message === "Network Error") {
        toast.error(`Unable to search for flights, Please try again`, {
          position: "top-right",
        });
      }
    }
  };

  const ShowFlight = () => {
    return (
      <div className="pt-[5rem]">
        <div className="w-full h-full">
          <div className="w-full h-[60vh] md:h-[70vh] text-white">
            <div className="w-full h-full">
              <div className="absolute w-full h-[60vh] md:h-[70vh] bg-gradient-to-r from-black"></div>
              <img
                className="w-full h-full object-cover object-center"
                src={cityData.image}
                alt="/"
              />

              <div className="absolute w-full top-[60%] md:top-[60%] pl-[1rem]">
                <h1 className="text-4xl font-bold md:text-6xl">{city.city}</h1>
                <h2 className="text-xl md:text-2xl tracking-wide py-2 text-gray-400">
                  <MdLocationPin className="inline mr-2" />
                  Destination
                </h2>
              </div>
            </div>
          </div>

          <div className="px-2 lg:px-4 max-w-[1240px] mx-auto py-16">
            <h2 className="text-2xl md:text-3xl font-bold tracking-wide py-2">
              Let's Book Your Flight{" "}
              <GiCommercialAirplane className="ml-1 inline" />
            </h2>

            <div className="max-w-[700px] m-auto px-4 py-9">
              <div className="bg-[#F5F5F5] shadow-xl px-10 py-8 rounded-lg">
                <form>
                  <div>
                    <div className="py-2 font-bold">
                      <div className="flex items-center justify-center">
                        <div>
                          {returnTrip ? (
                            <div className="flex items-center border border-black rounded-3xl p-[1px] bg-white">
                              <div
                                onClick={handleOneWayTrip}
                                className="bg-grey-700 py-1 px-5 rounded-2xl cursor-pointer"
                              >
                                <p className="text-sm">
                                  One Way Trip{" "}
                                  <MdAirplaneTicket className="ml-1 inline" />
                                </p>
                              </div>
                              <div
                                onClick={handleReturnTrip}
                                className="bg-[#000] py-1 px-6 rounded-2xl cursor-pointer"
                              >
                                <p className="text-sm text-white">
                                  Return Trip{" "}
                                  <MdAirplaneTicket className="ml-1 inline" />
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="flex items-center border border-black rounded-3xl p-[1px] bg-white">
                              <div
                                onClick={handleOneWayTrip}
                                className="bg-[#000] py-1 px-5 rounded-2xl cursor-pointer"
                              >
                                <p className="text-sm text-white">
                                  One Way Trip{" "}
                                  <MdAirplaneTicket className="ml-1 inline" />
                                </p>
                              </div>
                              <div
                                onClick={handleReturnTrip}
                                className="bg-grey-700 py-1 px-5 rounded-2xl cursor-pointer"
                              >
                                <p className="text-sm">
                                  Return Trip{" "}
                                  <MdAirplaneTicket className="ml-1 inline" />
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="grid md:grid-cols-2 md:gap-8">
                    <div className="my-6 md:my-9">
                      <div className="md:hidden">
                        <label className="font-bold">Origin*</label>
                        <Select
                          defaultValue={selectedAirport}
                          onChange={handleSelect}
                          options={data}                  
                          // defaultValue={selectedOrigin}
                          // onChange={setSelectedOrigin}
                          // options={origin}
                          styles={styles}
                          placeholder="Search or Select Origin..."
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "grey",
                              primary: "black",
                            },
                          })}
                        />
                      </div>

                      <label className="relative hidden md:block">
                        <Select
                          defaultValue={selectedAirport}
                          onChange={handleSelect}
                          options={data}                  
                          // defaultValue={selectedOrigin}
                          // onChange={setSelectedOrigin}
                          // options={origin}
                          styles={styles}
                          placeholder="Search or Select an Origin..."
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "grey",
                              primary: "black",
                            },
                          })}
                        />
                        <span className="text-xl absolute left-0 top-2 mx-4 text-[#000] bg-[#F5F5F5] transform -translate-y-6 -translate-x-3 scale-75 font-bold">
                          Origin*
                        </span>
                      </label>
                    </div>

                    <div className="mb-6 md:my-9 md:mt-9">
                      <div className="md:hidden">
                        <label className="font-bold">Destination*</label>
                        <div className="h-12 px-4 border border-[#555353] text-gray-400 bg-transparent rounded-md py-3">
                          {city?.city}
                        </div>
                      </div>

                      <label className="relative hidden md:block">
                        <div className="h-12 sm:w-[280px] px-4 border border-[#555353] outline-none focus:border-[#000] focus:text-[#000] text-gray-400 transition duration-200 bg-transparent rounded-md py-3">
                          {city?.city}
                        </div>
                        <span className="text-xl absolute left-0 top-2 mx-4 text-[#000] bg-[#F5F5F5] transform -translate-y-6 -translate-x-3 scale-75 font-bold">
                          Destination*
                        </span>
                        <MdLocationPin
                          size={22}
                          className="absolute top-3 right-3 hidden md:block"
                        />
                      </label>
                    </div>
                  </div>

                  {returnTrip ? (
                    <div>
                      <div className="mb-6 md:my-0">
                        <div className="md:hidden">
                          <label className="font-bold">Departure Date*</label>
                          <DatePicker
                            selected={
                              departureDate === "" ? null : departureDate
                            }
                            onChange={(date) =>
                              new Date(setDepartureDate(date))
                            }
                            showIcon
                            dateFormat="yyyy-MM-dd"
                            placeholderText="yyyy-mm-dd"
                            className="h-12 w-full px-4 border border-[#555353]  bg-transparent rounded-md"
                          />
                        </div>

                        <label className="relative hidden md:block">
                          <DatePicker
                            showIcon
                            closeOnScroll={true}
                            dateFormat="yyyy-MM-dd"
                            selected={
                              departureDate === "" ? null : departureDate
                            }
                            placeholderText="yyyy-mm-dd"
                            onChange={(date) =>
                              new Date(setDepartureDate(date))
                            }
                            className="h-12 w-full px-4 border border-[#555353] outline-none focus:border-[#000] focus:text-[#000] transition duration-200 bg-transparent rounded-md"
                          />
                          <span className="text-xl absolute left-0 top-2 mx-4 text-[#000] bg-[#F5F5F5] transform -translate-y-6 -translate-x-3 scale-75 font-bold">
                            Departure Date*
                          </span>
                        </label>
                      </div>

                      <div className="mb-6 md:my-0">
                        <div className="md:hidden">
                          <label className="font-bold">Return Date*</label>
                          <DatePicker
                            showIcon
                            dateFormat="yyyy/MM/dd"
                            selected={returnDate === "" ? null : returnDate}
                            placeholderText="yyyy-mm-dd"
                            onChange={(date) => new Date(setReturnDate(date))}
                            className="h-12 w-full px-4 border border-[#555353]  bg-transparent rounded-md"
                          />
                        </div>

                        <label className="relative hidden md:block my-9">
                          <DatePicker
                            showIcon
                            closeOnScroll={true}
                            dateFormat="yyyy-MM-dd"
                            selected={returnDate === "" ? null : returnDate}
                            placeholderText="yyyy-mm-dd"
                            onChange={(date) => new Date(setReturnDate(date))}
                            className="h-12 w-full px-4 border border-[#555353] outline-none focus:border-[#000] focus:text-[#000] transition duration-200 bg-transparent rounded-md"
                          />
                          <span className="text-xl absolute left-0 top-2 mx-4 text-[#000] bg-[#F5F5F5] transform -translate-y-6 -translate-x-3 scale-75 font-bold">
                            Return Date*
                          </span>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div className="mb-6 md:my-0">
                      <div className="md:hidden">
                        <label className="font-bold">Departure Date*</label>
                        <DatePicker
                          showIcon
                          dateFormat="yyyy-MM-dd"
                          selected={departureDate === "" ? null : departureDate}
                          placeholderText="yyyy-mm-dd"
                          onChange={(date) => new Date(setDepartureDate(date))}
                          className="h-12 w-full px-4 border border-[#555353]  bg-transparent rounded-md"
                        />
                      </div>

                      <label className="relative hidden md:block">
                        <DatePicker
                          showIcon
                          closeOnScroll={true}
                          dateFormat="yyyy-MM-dd"
                          selected={departureDate === "" ? null : departureDate}
                          placeholderText="yyyy-mm-dd"
                          onChange={(date) => new Date(setDepartureDate(date))}
                          
                          // onChange={(e) => {
                          //   handleChange(
                          //     { target: { name: "departureDate", value: e }}
                          //   );
                          // }}
                          // value={
                          //   departureDate
                          //     ? departureDate
                          //     : ""
                          // }
                          // selected={
                          //   departureDate
                          //     ? departureDate
                          //     : ""
                          // }
                          // name="departureDate"
                          // showIcon
                          // dateFormat="yyyy-MM-dd"
                          // placeholderText="yyyy-mm-dd"
                          className="h-12 w-full px-4 border border-[#555353] outline-none focus:border-[#000] focus:text-[#000] transition duration-200 bg-transparent rounded-md"
                        />
                        <span className="text-xl absolute left-0 top-2 mx-4 text-[#000] bg-[#F5F5F5] transform -translate-y-6 -translate-x-3 scale-75 font-bold">
                          Departure Date*
                        </span>
                      </label>
                    </div>
                  )}

                  <div>
                    <div className="mb-6 md:my-9">
                      <div className="md:hidden">
                        <label className="font-bold">Cabin Class*</label>
                        <Select
                          defaultValue={selectedClass}
                          onChange={setSelectedClass}
                          options={cabinClass}
                          styles={styles}
                          placeholder="Select Class..."
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "grey",
                              primary: "black",
                            },
                          })}
                        />
                      </div>

                      <label className="relative hidden md:block">
                        <Select
                          defaultValue={selectedClass}
                          onChange={setSelectedClass}
                          options={cabinClass}
                          styles={styles}
                          placeholder="Select Class..."
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "grey",
                              primary: "black",
                            },
                          })}
                        />
                        <span className="text-xl absolute left-0 top-2 mx-4 text-[#000] bg-[#F5F5F5] transform -translate-y-6 -translate-x-3 scale-75 font-bold">
                          Cabin Class*
                        </span>
                      </label>
                    </div>
                  </div>

                  <div className="my-4 md:my-9">
                    <div className="bg-[#000] w-full rounded-md text-white py-4 px-6">
                      <h2 className="font-bold">Passengers*</h2>
                      <div className="py-4">
                        <div className="flex items-center justify-between py-2">
                          <div className="font-bold">Adult :</div>
                          <div className="flex items-center gap-6">
                            <div className="bg-white rounded-sm p-1 text-black">
                              <BiMinus
                                onClick={() => {
                                  console.log("working");
                                  setAdultCount((prev) =>
                                    prev < 1 ? prev : prev - 1
                                  );
                                  // handleRemovePassenger("adult");
                                }}
                              />
                            </div>
                            <div>{adultCount}</div>
                            <div className="bg-white rounded-sm p-1 text-black">
                              <BiPlus
                                onClick={() => {
                                  setAdultCount((prev) => prev + 1);
                                  // handleAddPassenger("adult");
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <hr />

                        <div className="flex items-center justify-between py-2">
                          <div className="font-bold">11 years+ :</div>
                          <div className="flex items-center gap-6">
                            <div className="bg-white rounded-sm p-1 text-black">
                              <BiMinus
                                onClick={() => {
                                  setChildCount((prev) =>
                                    prev < 1 ? prev : prev - 1
                                  );
                                  // handleRemovePassenger("child");
                                }}
                              />
                            </div>
                            <div>{childCount}</div>
                            <div className="bg-white rounded-sm p-1 text-black">
                              <BiPlus
                                onClick={(type, age) => {
                                  setChildCount((prev) => prev + 1);
                                  // handleAddPassenger("child");
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <hr />

                        <div className="flex items-center justify-between py-2">
                          <div className="font-bold">1 year+ :</div>
                          <div className="flex items-center gap-6">
                            <div className="bg-white rounded-sm p-1 text-black">
                              <BiMinus
                                onClick={() => {
                                  setInfantCount((prev) =>
                                    prev < 1 ? prev : prev - 1
                                  );
                                  // handleRemovePassenger("infant");
                                }}
                              />
                            </div>
                            <div>{infantCount}</div>
                            <div className="bg-white rounded-sm p-1 text-black">
                              <BiPlus
                                onClick={() => {
                                  setInfantCount((prev) => prev + 1);
                                  // handleAddPassenger("infant");
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>

                  {returnTrip ? (
                    <div>
                      <button
                        type="button"
                        onClick={handleReturnWayForm}
                        className="w-full my-4 md:my-2 p-3 bg-[#000] text-white rounded-lg"
                      >
                        {isLoading ? (
                          <ClipLoader size={20} color={"#fff"} />
                        ) : (
                          "Find available flights"
                        )}
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        type="button"
                        onClick={handleOneWayForm}
                        className="w-full my-4 md:my-2 p-3 bg-[#000] text-white rounded-lg"
                      >
                        {isLoading ? (
                          <ClipLoader size={20} color={"#fff"} />
                        ) : (
                          "Find available flights"
                        )}
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>{loading ? <Loading /> : <ShowFlight />}</div>
    </div>
  );
};

export default SearchFlights;
