import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { baseUrl } from "../slices/api";

const ResetPasswordPage = () => {
  const initialValue = {
    password: "",
    password2: "",
  };

  const [user, setUser] = useState(initialValue);
  const [passwordEye, setPasswordEye] = useState(false);
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handlePasswordEye = () => {
    setPasswordEye(!passwordEye);
  };
  const handleConfirmPasswordEye = () => {
    setConfirmPasswordEye(!confirmPasswordEye);
  };

  const validate = (values) => {
    const errorss = {};
    if (!values.password) {
      errorss.password = "Password is Required!";
    } else if (values.password.length < 8) {
      errorss.password = "Password must be 8 or more than 8 characters";
    }
    if (!values.password2) {
      errorss.password2 = "Please Confirm Password!";
    } else if (values.password2 !== values.password) {
      errorss.password2 = "Password does not match";
    }

    return errorss;
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    //   console.log(errors);
    if (Object.keys(errors).length === 0 && isSubmit) {
      // console.log(user);
    }
  }, [user, errors, isSubmit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    // console.log(user);
  };

  const { resetId } = useParams();

  const url = `${baseUrl}/reset-password/`;

  const reset = async (user) => {
    try {
      setLoading(true);
      await axios
        .post(url, {
          token: resetId,
          new_password: user.password,
          confirm_password: user.password2,
        })
        .then((response) => {
          if (response.status === 200) {
            // console.log('Success!!');
            navigate("/login");
            setLoading(false);
          } else {
            throw response;
          }
        });
    } catch (err) {
      // console.log(err.response.data);
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(user));
    setIsSubmit(true);
    reset(user);
  };

  return (
    <div className="pt-[5rem]">
      <div className="max-w-[400px] mx-auto min-h-[600px] px-2 py-20">
        <div className="bg-[#F5F5F5] shadow-lg px-10 py-8 rounded-lg relative">
          <h1 className="text-2xl text-center font-bold">
            Reset your password
          </h1>
          <form onSubmit={handleSubmit}>
            <div className="my-4">
              <label>New Password</label>
              <div className="my-2 w-full relative ">
                <input
                  className="w-full p-2 border border-gray-400 bg-transparent rounded-lg"
                  type={passwordEye === false ? "password" : "text"}
                  placeholder="Enter New Password"
                  name="password"
                  value={user.password}
                  onChange={handleChange}
                />
                <div className="absolute right-2 top-3">
                  {passwordEye === false ? (
                    <AiFillEyeInvisible
                      onClick={handlePasswordEye}
                      className="text-gray-400 cursor-pointer"
                    />
                  ) : (
                    <AiFillEye
                      onClick={handlePasswordEye}
                      className="text-gray-400 cursor-pointer"
                    />
                  )}
                </div>
              </div>
              <p className="text-sm text-red-600">{errors.password}</p>
            </div>

            <div className="my-4">
              <label>Confirm Password</label>
              <div className="my-2 w-full relative">
                <input
                  className="w-full p-2 border border-gray-400 bg-transparent rounded-lg"
                  type={confirmPasswordEye === false ? "password" : "text"}
                  placeholder="Confirm Password"
                  name="password2"
                  value={user.password2}
                  onChange={handleChange}
                />
                <div className="absolute right-2 top-3">
                  {confirmPasswordEye === false ? (
                    <AiFillEyeInvisible
                      onClick={handleConfirmPasswordEye}
                      className="text-gray-400 cursor-pointer"
                    />
                  ) : (
                    <AiFillEye
                      onClick={handleConfirmPasswordEye}
                      className="text-gray-400 cursor-pointer"
                    />
                  )}
                </div>
              </div>
              <p className="text-sm text-red-600">{errors.password2}</p>
            </div>

            <button className="w-full my-2 p-3 bg-[#000] rounded-lg text-white">
              {loading ? (
                <ClipLoader size={20} color={"#fff"} />
              ) : (
                "Reset Password"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
