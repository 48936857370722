import React, { useEffect, useRef, useState } from "react";
import bgimg from "../images/river.jpg";
import { BsSearch } from "react-icons/bs";
import Cities from "../homeLayout/Cities";
import Footer from "./Footer";
import axios from "axios";
import PropagateLoader from "react-spinners/PropagateLoader";
import Pagination from "./Pagination";
import Marquee from "react-fast-marquee";
import logo from "../images/logo-white.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../slices/authSlice";
import LoginModal from "./LoginModal";
import { baseUrl } from "../utils/baseUrl";
import appstore from "../images/appstore.svg";
import playstore from "../images/playstore.svg";
import { Link } from "react-router-dom";
import PhotoWeek from "../homeLayout/PhotoWeek";

const Home = () => {
  const [cities, setCities] = useState([]);
  const [searchCities, setSearchCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pageTopRef = useRef(null);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const url = `${baseUrl}/cities/`;
  const SearchUrl = `${baseUrl}/cities/?search=${value}`;

  const auth = useSelector((state) => state.auth);
  // console.log(auth);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });

    if (!auth.token) {
      const getCities = async () => {
        setLoading(true);
        try {
          const res = await axios.get(url);
          setCities(res.data.results);
          //   setFilterCities(res.data.results);
          // console.log(res.data);
        } catch (error) {
          // console.log(error);
        }
        setLoading(false);
      };
      getCities();
    } else {
      const getAuthCities = async () => {
        setLoading(true);
        try {
          await axios
            .get(url, {
              headers: {
                Authorization: `${auth.token}`,
              },
            })
            .then((response) => {
              // console.log(response.data);
              if (response) {
                setLoading(false);
                setCities(response.data.results);
                // setFilterCities(response.data.results);
              }
            });
        } catch (error) {
          // console.log(error.response);
          if (error.response.status === 401) {
            openModal();
            dispatch(logoutUser(null));
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      };
      getAuthCities();
    }
  }, [url]);

  const postsPerPage = 6;
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts =
    value.length > 0
      ? searchCities.slice(firstPostIndex, lastPostIndex)
      : cities.slice(firstPostIndex, lastPostIndex);
  // console.log(currentPosts);
  const currentSearchPosts = cities.filter((city) => {
    if (value.length === 0) {
      return city;
    } else {
      return city;
    }
  });
  //   console.log(currentSearchPosts);

  const Loading = () => {
    return (
      <div className="flex items-center py-6">
        <PropagateLoader color={"#000"} loading={loading} />
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!auth.token) {
        setLoading(true);
        const res = await axios.get(SearchUrl);
        setCities(res.data.results);
        setSearchCities(res.data.results);
        // console.log(res.data.results);
        setLoading(false);
      } else {
        setLoading(true);
        axios
          .get(SearchUrl, {
            headers: {
              Authorization: `${auth.token}`,
            },
          })
          .then((res) => {
            setLoading(false);
            setCities(res.data.results);
            setSearchCities(res.data.results);
            // console.log(res.data.results);
          });
      }
    };

    const getData = setTimeout(() => {
      fetchData();
    }, 1000);

    return () => clearTimeout(getData);
  }, [value]);

  const handleSearch = async (e) => {
    e.preventDefault();
  };

  return (
    <div className="pt-[5rem]">
      <div className="md:hidden">
        <Marquee className="bg-[#000]" gradient={false} pauseOnHover speed={40}>
          <div className="flex justify-center items-center gap-4 w-full h-[4rem]">
            <div className="flex items-center gap-3 pl-5">
              <div className="w-14 h-14 mt-4">
                <img src={logo} alt="kofyImages logo" className="rounded-md" />
              </div>
              <div>
                <p className="text-red-500 text-sm">Get KofyImages App now!</p>
                <p className="text-white text-sm">Explore different Cities!</p>
              </div>
            </div>

            <div className="flex items-center justify-center gap-2">
              <div>
                <a
                  href="https://apps.apple.com/app/kofyimages/id6447689001"
                  rel="noreferrer"
                  target="_blank"
                >
                  {/* <button className="border border-white text-white rounded-md flex items-center">
                    <div className="w-[30px] h-[30px]">
                      <img src={appstore} alt="/" />
                    </div>
                  </button> */}

                  <div className="w-[130px]">
                    <img src={appstore} alt="/" />
                  </div>
                </a>
              </div>

              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.kofy.kofyimages"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="w-[130px]">
                    <img src={playstore} alt="/" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </Marquee>
      </div>

      <div className="w-full">
        <div className="w-full h-[60vh] md:h-[70vh] text-white">
          <div className="w-full h-full">
            <div className="absolute w-full h-[60vh] md:h-[70vh] bg-gradient-to-r from-black"></div>
            <img
              className="w-full h-full object-cover object-center"
              src={bgimg}
              alt="/"
            />

            <div className="absolute w-full top-[32%] sm:top-[30%] flex flex-col justify-center items-center p-4 md:p-8">
              <h1 className="text-2xl font-bold md:text-3xl py-4">
                Experience different cultures through their food, lifestyle and
                festivals
              </h1>
              <div className="flex">
                <form className="relative flex" onSubmit={handleSearch}>
                  <input
                    onChange={(e) => {
                      setValue(e.target.value);
                    }}
                    className=" border border-input px-3 py-2 w-[15rem] sm:w-[38rem] shadow-lg rounded-tl-lg rounded-bl-lg text-black"
                    type="text"
                    placeholder="Search for Cities"
                  />
                  <div className="bg-black text-white px-5 py-2 rounded-tr-lg rounded-br-lg shadow-lg hover:shadow-2xl flex items-center justify-between cursor-pointer">
                    <BsSearch />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-2 lg:px-4 max-w-[1240px] mx-auto pb-9">
        <PhotoWeek />
      </div>

      <hr />

      <div
        className="px-2 lg:px-4 max-w-[1240px] mx-auto pb-16 pt-6"
        ref={pageTopRef}
        id="cities"
      >
        <div>
          <h1 className="text-4xl font-bold">Curated cities collections</h1>
          <p>
            Explore diverse collections of cities, their foods, lifestyle and
            festivals
          </p>
          <div className="flex items-center justify-center">
            <div>
              {loading ? (
                <Loading />
              ) : (
                <div>
                  <div className="flex flex-col items-center justify-center w-full">
                    <div>
                      {currentSearchPosts.length === 0 ? (
                        <div className="font-bold w-full text-2xl text-red-500 py-4">
                          <p>City not found! Check back later!</p>
                        </div>
                      ) : currentPosts.length === 0 ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 sm:gap-8 py-9 w-full">
                          {searchCities.map((city) => (
                            <Cities key={city._id} city={city} />
                          ))}
                        </div>
                      ) : (
                        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 sm:gap-8 py-9 w-full">
                          {currentPosts.map((city) => (
                            <Cities key={city._id} city={city} />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <Pagination
                    totalPosts={
                      value.length > 0 ? searchCities.length : cities.length
                    }
                    postsPerPage={postsPerPage}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    pageTopRef={pageTopRef}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isOpen ? <LoginModal closeModal={closeModal} isOpen={isOpen} /> : ""}
      <Footer className="pt-[4rem]" />
    </div>
  );
};

export default Home;
