import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../utils/baseUrl";
import DOMPurify from "dompurify";
import ReactMarkdown from "react-markdown";

const PhotoWeek = () => {
  const [photo, setPhoto] = useState("");

  const url = `${baseUrl}/exhibitions/photo-of-the-week/`;

  useEffect(() => {
    axios.get(url).then((response) => {
      setPhoto(response.data);
      console.log(response.data);
    });
  }, [url]);

  return (
    <div className="pt-[2.5rem] px-2 lg:px-4 max-w-[1240px] mx-auto">
      <div className="flex items-center justify-center flex-col lg:pb-4">
        <h2 className="font-bold text-3xl after:w-[60px] after:h-[5px] after:bg-[#000] after:block after:m-auto after:mt-[0.5rem] mb-2">
          Photo Of The Week
        </h2>
      </div>
      <div className="md:flex justify-between w-full">
        <div className="flex flex-col lg:flex-row md:gap-4">
          <div className="mx-auto md:mx-0 md:hidden">
            <img src={photo.image_thumbnail} alt="photo of the week" />
          </div>
          <div className="mx-auto md:mx-0 hidden md:block">
            <img
              src={photo.image_thumbnail}
              alt="photo of the week"
              className="w-[380px] h-[380px]"
            />
          </div>
          <div className="hidden md:block">
            <div className="pb-4">
              <h3 className="font-bold text-xl">Name :</h3>
              <p>{photo.name}</p>
            </div>

            <div>
              <h3 className="font-bold text-xl ">City, Country :</h3>
              <p>{photo.city}</p>
            </div>
          </div>

          <div className="md:hidden block">
            <div className="py-1">
              <p className="font-bold">{photo.name}</p>
            </div>
            <div>
              <p className="font-bold">{photo.city}</p>
            </div>
            <div className="py-1">
              <ReactMarkdown>{photo.content}</ReactMarkdown>
            </div>
          </div>
        </div>
        <div className="hidden md:block border border-l-black border-white px-5 w-[47%]">
          <div>
            <h3 className="font-bold text-xl">Description :</h3>
            <ReactMarkdown>{photo.content}</ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoWeek;
