import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { MdChevronRight } from "react-icons/md";
import FadeLoader from "react-spinners/FadeLoader";
import { useDispatch } from "react-redux";
import { addToCart } from "../slices/cartSlice";
import { baseUrl } from "../utils/baseUrl";

const BookletsPage = () => {
  const [booklets, setBooklets] = useState([]);
  const [loading, setLoading] = useState(false);
  const { cityId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  const url = `${baseUrl}/cities/${cityId}/booklets/`;

  useEffect(() => {
    setLoading(true);
    axios.get(url).then((response) => {
      response.data.results.map((booklet) => {
        booklet.image = booklet.images[0];
        booklet.color = null;
      });
      setBooklets(response.data.results);
      // console.log(response.data);
      setLoading(false);
    });
  }, [url]);

  const handleAddToCart = (booklet) => {
    dispatch(addToCart(booklet));
  };

  const Loading = () => {
    return (
      <div className="flex flex-wrap items-center justify-center h-screen">
        <FadeLoader color={"#000"} loading={loading} size={150} />
        <p className="text-2xl ">Loading Travel Guide...</p>
      </div>
    );
  };

  const ShowPictures = () => {
    return (
      <div className="pt-[5rem]">
        <div className="w-full bg-[#000] p-7">
          <div className="px-2 lg:px-4 max-w-[1240px] mx-auto">
            <p className="text-white">
              <Link to="/" className="cursor-pointer font-bold">
                Home
              </Link>{" "}
              <MdChevronRight className="inline" />{" "}
              <span className="text-gray-300">Travel Guide</span>
            </p>
          </div>
        </div>
        <div className="px-2 lg:px-4 max-w-[1240px] mx-auto py-12">
          <h2 className="text-2xl md:text-4xl font-bold tracking-wide py-2">
            Discover The World With Our Guide
          </h2>
          <div className="flex items-center justify-center flex-col py-9">
            {booklets.length === 0 ? (
              <div>
                <div className="flex items-center justify-center">
                  <p className="font-bold w-full text-2xl text-red-500 py-4 text-center">
                    No Travel Guide! Please Check back later
                  </p>
                </div>
              </div>
            ) : (
              <div>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 xl:gap-16">
                  {booklets?.map((booklet) => (
                    <div
                      key={booklet?._id}
                      className="border border-black p-4 rounded-lg shadow-lg"
                    >
                      <img
                        src={booklet?.images[0]}
                        alt="/"
                        width={300}
                        className="rounded-md"
                      />
                      <div className="py-2 flex items-center justify-center">
                        <p className="font-bold text-2xl">
                          £{booklet?.price.toFixed(2)}
                        </p>
                      </div>

                      <div
                        onClick={() => {
                          handleAddToCart(booklet);
                        }}
                        className="W-full bg-black text-white text-center py-2 px-2 rounded-md cursor-pointer"
                      >
                        Add to Cart
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>{loading ? <Loading /> : <ShowPictures />}</div>
    </div>
  );
};

export default BookletsPage;
