import React, { useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import ClipLoader from "react-spinners/ClipLoader";
import { baseUrl } from "../utils/baseUrl";

const PayButton = () => {
    const [loading, setLoading] = useState(false)
    const auth = useSelector((state) => state.auth);
    const cart = useSelector((state) => state.cart);

    const handleCheckout = () => {
        setLoading(true)
        axios.post(`${baseUrl}/orders/`, 
        {
            orders: cart.cartItems.map((cartItem) => {
                return {
                    product_id: cartItem._id,
                    quantity: cartItem.cartQuantity,
                    color: cartItem.color,
                };
            }),
            state: 'Lagos',
            address: 'Jakende',    
        },
        {
            headers: {
                Authorization: `${auth.token}`,
            },
        },
        ).then((res) => {
            if (res.data.payment_url){
                window.location.href = res.data.payment_url
            }
            // console.log(res.data);
            setLoading(false)
        }).catch((err) => {
            // console.log(err.message);
            setLoading(false)
        })
    }

  return (
    <div className='w-full text-center py-3 bg-black text-white rounded-md px-8 cursor-pointer'>
        <button onClick={() => handleCheckout()}>
            {loading ? <ClipLoader size={20} color={'#fff'} /> : 'Proceed to Checkout'}
        </button>
    </div>
  )
}

export default PayButton