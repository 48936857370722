import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import videobg from "../images/videography.jpg";
import FadeLoader from "react-spinners/FadeLoader";
import Pagination from "../components/Pagination";
import { AiFillEye } from "react-icons/ai";
import { baseUrl } from "../utils/baseUrl";
import "vidstack/styles/defaults.css";
import "vidstack/styles/community-skin/video.css";
import {
  MediaCommunitySkin,
  MediaGesture,
  MediaOutlet,
  MediaPlayer,
  MediaPoster,
  MediaThumbnail,
} from "@vidstack/react";
import Iframe from 'react-iframe'

const VideosPage = () => {
  const [videos, setVideos] = useState([]);
  const [city, setCity] = useState([]);
  const [loading, setLoading] = useState(false);
  const { cityId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);

  const pageTopRef = useRef(null);

  const url = `${baseUrl}/cities/${cityId}/images/videos`;

  useEffect(() => {
    setLoading(true);
    axios.get(url).then((response) => {
      setVideos(response.data.results);
      setCity(response.data);
      // console.log(response.data.results);
      setLoading(false);
    });
  }, [url]);

  const handleViews = async (video) => {
    try {
      await axios
        .post(`${baseUrl}/exhibitions/${video._id}/`, {})
        .then((response) => {
          console.log(response);
          // alert("yes")
        });
    } catch (err) {
      console.log(err.response);
    }
  };

  const postsPerPage = 6;
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = videos.slice(firstPostIndex, lastPostIndex);

  const Loading = () => {
    return (
      <div className="flex items-center justify-center h-screen">
        <FadeLoader color={"#000"} loading={loading} size={150} />
        <p className="text-2xl ">Loading Videos...</p>
      </div>
    );
  };

  const ShowVideos = () => {
    return (
      <div className="pt-[5rem]">
        <div className="w-full h-full">
          <div className="w-full h-[60vh] text-white">
            <div className="w-full h-full">
              <div className="absolute w-full h-[60vh] bg-gradient-to-r from-black"></div>
              <img
                className="w-full h-full object-cover object-center"
                src={videobg}
                alt="/"
              />

              <div className="absolute w-full top-[35%] text-center">
                <h1 className="text-4xl font-bold md:text-6xl">
                  Eclectic Street View Videos of {city?.city}
                </h1>
              </div>
            </div>
          </div>

          {videos.length === 0 ? (
            <div className="px-2 lg:px-4 max-w-[1240px] mx-auto py-16">
              <div className="flex items-center justify-center">
                <p className="font-bold w-full text-2xl text-red-500 py-4 text-center">
                  No Videos! Please Check back later
                </p>
              </div>
            </div>
          ) : (
            <div
              className="px-2 lg:px-4 max-w-[1240px] mx-auto py-16"
              ref={pageTopRef}
            >
              <h2 className="text-2xl md:text-4xl font-bold tracking-wide py-2">
                Explore {city?.city} through diverse videos
              </h2>
              <div className="py-12">
                <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-16 md:gap-10 pb-9 mx-auto pl-2">
                  {currentPosts?.map((video, index) => (
                    <div
                      key={index}
                      onClick={() => handleViews(video)}
                      className="w-[350px] h-[250px]"
                    >
                      {/* <Iframe
                        onPlay={() => handleViews(video)}
                        className="w-[350px] xs:w-[380px] h-[250px] rounded-md"
                        src={`https://www.youtube.com/embed/${video.youtube_link.slice(
                          32
                        )}?rel=0&controls=0`}
                        title={video.name}
                        frameBorder="0"
                        allowFullScreen
                        modestBranding="1"
                        showInfo="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      ></Iframe>
                      <div className="flex items-center flex-end justify-end font-bold text-sm">
                        <AiFillEye className="inline mr-1" /> Views{" "}
                        {video.views}
                      </div>
                      <div>
                        <div
                          className="text-xl font-bold"
                        >
                          {video.caption}
                        </div>
                      </div> */}
                      <div onClick={() => handleViews(video)}>
                      <MediaPlayer
                        title={video.caption}
                        src={video.youtube_link}
                        poster={video.popup_image}
                        thumbnails={video.thumbnail}
                        aspectRatio={15 / 10}
                        crossorigin=""
                        keyDisabled={true}
                        onScroll={true}
                      >
                        <MediaOutlet>
                          <MediaThumbnail slot={video.thumbnail} />
                          <MediaPoster alt={video.caption} />
                          <MediaGesture event="pointerup" action="toggle:user-idle"/>
                        </MediaOutlet>
                        <MediaCommunitySkin className="pointer-events-none"/>
                      </MediaPlayer>
                      <div className="flex items-center flex-end justify-end font-bold text-sm">
                        <AiFillEye className="inline mr-1" /> Views{" "}
                        {video.views}
                      </div>
                      <div>
                        <div className="text-xl font-bold">{video.caption}</div>
                      </div>
                    </div>
                    </div>
                  ))}
                </div>
              </div>
              <Pagination
                totalPosts={videos.length}
                postsPerPage={postsPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                pageTopRef={pageTopRef}
              />
            </div>
          )}
          <Footer />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>{loading ? <Loading /> : <ShowVideos />}</div>
    </div>
  );
};

export default VideosPage;
