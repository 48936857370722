import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { MdChevronRight } from "react-icons/md";
import FadeLoader from "react-spinners/FadeLoader";
import { useDispatch } from "react-redux";
import { addToCart } from "../slices/cartSlice";
import { AiOutlineArrowUp } from "react-icons/ai";
import Pagination from "../components/Pagination";
import { baseUrl } from "../utils/baseUrl";

const FramedPicturesPage = () => {
  const [frames, setFrames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { cityId } = useParams();
  const dispatch = useDispatch();

  const pageTopRef = useRef(null);


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  const url = `${baseUrl}/frames/`;

  useEffect(() => {
    setLoading(true);
    axios.get(url).then((response) => {
      response.data.results.map((frame) => {
        frame.color = "black";
        frame.imgIndex = 0;
        frame.selected = false;
        frame.image = frame.images[0];
        frame.alert = false;
      });
      setFrames(response.data.results);
      // console.log(response.data.results);
      setLoading(false);
    });
  }, [url]);

  const postsPerPage = 6;
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = frames.slice(firstPostIndex, lastPostIndex);

  const changeColorBlack = (frame) => {
    frame.color = "black";
    frame.imgIndex = 1;
    frame.selected = true;
    frame.image = frame.images[1];
    frame.alert = false;

    const frameId = [];
    frames.forEach((f) => {
      frameId.push(f._id);
    });
    // console.log(frameId);
    // console.log(frame);

    const frameIndex = frameId.indexOf(frame._id);
    const tempFrame = [];
    for (let i = 0; i < frames.length; i++) {
      if (i === frameIndex) {
        tempFrame.push(frame);
      } else {
        tempFrame.push(frames[i]);
      }
    }
    setFrames(tempFrame);
    // console.log(frames);
  };

  const changeColorBrown = (frame) => {
    frame.color = "brown";
    frame.imgIndex = 2;
    frame.selected = true;
    frame.image = frame.images[2];
    frame.alert = false;

    const frameId = [];
    frames.forEach((f) => {
      frameId.push(f._id);
    });
    // console.log(frameId);
    // console.log(frame);

    const frameIndex = frameId.indexOf(frame._id);
    const tempFrame = [];
    for (let i = 0; i < frames.length; i++) {
      if (i === frameIndex) {
        tempFrame.push(frame);
      } else {
        tempFrame.push(frames[i]);
      }
    }
    setFrames(tempFrame);
    // console.log(frames);
  };

  const handleAddToCart = (frame) => {
    if (frame.selected === false) {
      frame.alert = true;

      const frameId = [];
      frames.forEach((f) => {
        frameId.push(f._id);
      });
      // console.log(frameId);
      // console.log(frames);

      const frameIndex = frameId.indexOf(frame._id);
      const tempFrame = [];
      for (let i = 0; i < frames.length; i++) {
        if (i === frameIndex) {
          tempFrame.push(frame);
        } else {
          tempFrame.push(frames[i]);
        }
      }
      setFrames(tempFrame);
    } else {
      dispatch(addToCart(frame));
    }
    // console.log(frames);
  };

  const Loading = () => {
    return (
      <div className="flex flex-wrap items-center justify-center h-screen">
        <FadeLoader color={"#000"} loading={loading} size={150} />
        <p className="text-2xl ">Loading Frames...</p>
      </div>
    );
  };

  const ShowPictures = () => {
    return (
      <div className="pt-[5rem]">
        <div className="w-full bg-[#000] p-7">
          <div className="px-2 lg:px-4 max-w-[1240px] mx-auto">
            <p className="text-white">
              <Link to="/" className="cursor-pointer font-bold">
                Home
              </Link>{" "}
              <MdChevronRight className="inline" />{" "}
              <span className="text-gray-300">Frames </span>
            </p>
          </div>
        </div>
        <div className="px-2 lg:px-4 max-w-[1240px] mx-auto py-12"  ref={pageTopRef}>
          <h2 className="text-2xl md:text-4xl font-bold tracking-wide py-2">
            Curated Frames collections
          </h2>
          <div className="flex items-center justify-center flex-col py-9">
            {frames.length === 0 ? (
              <div>
                <div className="flex items-center justify-center">
                  <p className="font-bold w-full text-2xl text-red-500 py-4 text-center">
                    No Frames! Please Check back later
                  </p>
                </div>
              </div>
            ) : (
              <div>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 xl:gap-16">
                  {currentPosts?.map((frame) => (
                    <div
                      key={frame?._id}
                      className="border border-black p-4 rounded-lg shadow-lg"
                    >
                      <img
                        src={frame?.images[frame.imgIndex]}
                        alt="/"
                        width={300}
                        className="rounded-md"
                      />
                      <div className="pt-2 flex items-center justify-between">
                        <p className="font-bold text-2xl">
                          £{frame?.price}
                        </p>
                        <div className="py-2 flex items-center">
                          <div
                            className="w-[22px] h-[22px] rounded-full bg-[#000] mr-4 border border-black shadow-lg cursor-pointer lg:hover:scale-105 ease-in duration-300"
                            onClick={() => {
                              changeColorBlack(frame);
                            }}
                          />
                          <div
                            className="w-[22px] h-[22px] rounded-full bg-[#964b00] border border-[#964b00] shadow-lg cursor-pointer lg:hover:scale-105 ease-in duration-300"
                            onClick={() => {
                              changeColorBrown(frame);
                            }}
                          />
                        </div>
                      </div>

                      {frame.alert ? (
                        <div className="flex items-center justify-end text-red-500 mb-2">
                          <p className="">Please select a frame color</p>
                          <AiOutlineArrowUp className="inline mr-1" />
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="text-center font-bold text-xl pb-6">
                        {frame.name}
                      </div>

                      <div
                        onClick={() => {
                          handleAddToCart(frame);
                        }}
                        className="W-full bg-black text-white text-center py-2 px-2 rounded-md cursor-pointer"
                      >
                        Add to Cart
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <Pagination
            totalPosts={frames.length}
            postsPerPage={postsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            pageTopRef={pageTopRef}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>{loading ? <Loading /> : <ShowPictures />}</div>
    </div>
  );
};

export default FramedPicturesPage;
