import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import lifestyle from "../images/lifestyle.jpeg";
import FadeLoader from "react-spinners/FadeLoader";
import Pagination from "../components/Pagination";
import Modal from "../components/Modal";
import { baseUrl } from "../utils/baseUrl";

const LifestylePage = () => {
  const [lifestyles, setLifestyles] = useState([]);
  const [city, setCity] = useState([]);
  const [loading, setLoading] = useState(false);
  const { cityId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const pageTopRef = useRef(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  const url = `${baseUrl}/cities/${cityId}/images/lifestyle`;

  useEffect(() => {
    setLoading(true);
    axios.get(url).then((response) => {
      setLifestyles(response.data.results);
      setCity(response.data);
      // console.log(response.data);
      setLoading(false);
    });
  }, [url]);

  const postsPerPage = 6;
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = lifestyles.slice(firstPostIndex, lastPostIndex);

  const OpenModal = (lifestyle, index) => {
    setCurrentIndex(index);
    setClickedImg(lifestyle?.popup_image);
  };

  const handleRotationRight = () => {
    const totalLength = lifestyles.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = lifestyles[0].popup_image;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = lifestyles.filter((item) => {
      return lifestyles.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].popup_image;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  const handleRotationLeft = () => {
    const totalLength = lifestyles.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = lifestyles[totalLength - 1].popup_image;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = lifestyles.filter((item) => {
      return lifestyles.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].popup_image;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  const Loading = () => {
    return (
      <div className="flex items-center justify-center h-screen">
        <FadeLoader color={"#000"} loading={loading} size={150} />
        <p className="text-2xl ">Loading Lifestyles...</p>
      </div>
    );
  };

  const ShowLifestyles = () => {
    return (
      <div className="pt-[5rem]">
        <div className="w-full h-full">
          <div className="w-full h-[60vh] text-white">
            <div className="w-full h-full">
              <div className="absolute w-full h-[60vh] bg-gradient-to-r from-black"></div>
              <img
                className="w-full h-full object-cover object-center"
                src={lifestyle}
                alt="/"
              />

              <div className="absolute w-full top-[35%] md:top-[30%] text-center">
                <h1 className="text-4xl font-bold md:text-6xl">
                  Explore cities through lifestyle
                </h1>
              </div>
            </div>
          </div>

          <div className="px-2 lg:px-4 max-w-[1240px] mx-auto py-16" ref={pageTopRef}>
            <h2 className="text-2xl md:text-4xl font-bold tracking-wide py-2">
              Different lifestyles in {city?.city}
            </h2>

            <div className="flex items-center justify-center flex-col py-9">
              {lifestyles.length === 0 ? (
                <div>
                  <div className="flex items-center justify-center">
                    <p className="font-bold w-full text-2xl text-red-500 py-4 text-center">
                      No Lifestyles! Please Check back later
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-8">
                    {currentPosts.map((lifestyle, index) => (
                      <div>
                        <div
                          onClick={() => OpenModal(lifestyle, index)}
                          key={index}
                          className="group flex justify-center text-center relative overflow-hidden cursor-pointer  w-[350px] xs:w-[380px] h-[350px] rounded-2xl"
                        >
                          <img
                            src={lifestyle.image}
                            alt="/"
                            className="rounded-2xl object-cover ease-in-out duration-500  group-hover:scale-125  w-[350px] xs:w-[380px] h-[350px]"
                          />
                          <div className="absolute bg-gradient-to-r from-black opacity-50  w-[350px] xs:w-[380px] h-[350px] transition-opacity duration-500 group-hover:opacity-80" />
                        </div>
                        <p className='py-2 font-extrabold text-xl'>{lifestyle.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <Pagination
              totalPosts={lifestyles.length}
              postsPerPage={postsPerPage}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              pageTopRef={pageTopRef}
            />
            {clickedImg && (
              <Modal
                clickedImg={clickedImg}
                setClickedImg={setClickedImg}
                handleRotationRight={handleRotationRight}
                handleRotationLeft={handleRotationLeft}
                loadingModal={loading}
                LoadingModal={Loading}
              />
            )}
          </div>
          <Footer />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>{loading ? <Loading /> : <ShowLifestyles />}</div>
    </div>
  );
};

export default LifestylePage;
