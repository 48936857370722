import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MdChevronRight, MdEmail } from "react-icons/md";
import { useSelector } from "react-redux";
import { FaUserAlt } from "react-icons/fa";
import UpdateModal from "../components/UpdateModal";
import DeleteModal from "../components/DeleteModal";

const UserProfile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal2() {
    setIsOpen1(false);
  }

  function openModal2() {
    setIsOpen1(true);
  }

  const auth = useSelector((state) => state.auth);
  // console.log(auth);

  return (
    <div className="py-[5rem]">
      <div className="w-full bg-[#000] p-7">
        <div className="px-2 lg:px-4 max-w-[1240px] mx-auto">
          <p className="text-white">
            <Link to="/" className="cursor-pointer font-bold">
              Home
            </Link>{" "}
            <MdChevronRight className="inline" />{" "}
            <span className="text-gray-300">User Profile</span>
          </p>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center pt-[3rem]">
        <h1 className="font-bold text-3xl md:text-4xl tracking-wid after:w-[60px] after:h-[5px] after:bg-[#000] after:block after:m-auto after:mt-[0.5rem] mb-2">
          User Profile
        </h1>
        <p className="text-lg my-2 tracking-wide">
          Update and Delete user profile
        </p>
      </div>

      <div className="max-w-[450px] m-auto px-4 py-6">
        <>
          <div className="h-12 px-4 border border-[#000] text-[#000] bg-transparent rounded-md py-3 my-3">
            <FaUserAlt className="inline mr-3" /> {auth.first_name}
          </div>

          <div className="h-12 px-4 border border-[#000] text-[#000] bg-transparent rounded-md py-3 my-3">
            <FaUserAlt className="inline mr-3" />{" "}
            {window.localStorage.getItem("last_name")}
          </div>

          <div className="h-12 px-4 border border-[#000] text-[#000] bg-transparent rounded-md py-3 my-3">
            <MdEmail className="inline mr-3" />{" "}
            {window.localStorage.getItem("email")}
          </div>

          <button
            className="rounded-md h-12 w-full bg-black text-white my-2"
            onClick={openModal2}
          >
            Update User Account
          </button>

          <button
            className="rounded-md h-12 w-full bg-red-700 text-white my-2"
            onClick={openModal}
          >
            Delete User Account
          </button>
        </>
      </div>

      <div className="flex items-center justify-center ">
        <Link to="/" className="font-bold">
          Back to Explore Cities
        </Link>
      </div>

      {isOpen1 ? (
        <UpdateModal closeModal2={closeModal2} isOpen1={isOpen1} />
      ) : (
        ""
      )}

      {isOpen ? <DeleteModal closeModal={closeModal} isOpen={isOpen} /> : ""}
    </div>
  );
};

export default UserProfile;
