import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  AiOutlineMail,
  AiFillEyeInvisible,
  AiFillEye,
  AiOutlineClose,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../slices/authSlice";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import google from "../images/google.svg";
import { baseUrl } from "../utils/baseUrl";

const Login = () => {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [passwordEye, setPasswordEye] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const auth = useSelector((state) => state.auth);
  // console.log(auth);

  const handlePasswordEye = () => {
    setPasswordEye(!passwordEye);
  };

  useEffect(() => {
    if (auth._id && auth.first_name) {
      navigate("/");
      toast.success(`Welcome, ${auth.first_name}`, { position: "bottom-left" });
    }
  }, [auth._id, auth.first_name, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser(user));
  };

  useEffect(() => {
    if (auth.loginStatus === "rejected") {
      toast.error(`${auth.loginError}`, { position: "top-right" });
    }
  }, [auth.loginStatus, auth.loginError]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  // const handleGoogle = async () => {
  //   try {
  //     await axios
  //       .post(
  //         `${baseUrl}/users/social/login/`,
  //         {
  //           provider: "google",
  //         },
  //         setLoading(true)
  //       )
  //       .then((response) => {
  //         // console.log(response);
  //         // console.log(response.data);
  //         if (response.data.redirect_url){
  //           window.location.href = response.data.redirect_url
  //       }
  //       });
  //   } catch (error) {
  //     console.log(error.response);
  //     if (error) {
  //       toast.error(`An error occurred, please try again`, {
  //         position: "top-right",
  //       });
  //       setLoading(false);
  //     } else {
  //       setLoading(false);
  //     }
  //   }
  // };

  return (
    <div className="pt-[3rem]">
      <div className="max-w-[400px] mx-auto min-h-[600px] px-4 py-20">
        <div className="bg-[#F5F5F5] dark:bg-[#0e0e10]  shadow-lg px-10 py-8 rounded-lg relative">
          <h1 className="text-2xl font-bold">Sign in</h1>
          <form onSubmit={handleSubmit}>
            <div className="my-4">
              <label>Email Address</label>
              <div className="my-2 w-full relative">
                <input
                  className="w-full p-2 border border-gray-400 bg-transparent rounded-lg"
                  type="email"
                  placeholder="Enter Your Email Address"
                  required
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                />
                <AiOutlineMail className="absolute right-2 top-3 text-gray-400" />
              </div>
            </div>

            <div className="my-4">
              <label>Password</label>
              <div className="my-2 w-full relative">
                <input
                  className="w-full p-2 border border-gray-400 bg-transparent rounded-lg"
                  type={passwordEye === false ? "password" : "text"}
                  placeholder="Enter Your Password"
                  required
                  onChange={(e) =>
                    setUser({ ...user, password: e.target.value })
                  }
                />
                <div className="absolute right-2 top-3">
                  {passwordEye === false ? (
                    <AiFillEyeInvisible
                      onClick={handlePasswordEye}
                      className="text-gray-400 cursor-pointer"
                    />
                  ) : (
                    <AiFillEye
                      onClick={handlePasswordEye}
                      className="text-gray-400 cursor-pointer"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="text-sm flex items-center justify-between">
              <p></p>
              <Link
                to="/forgot-password"
                className="cursor-pointer text-red-500"
              >
                Forgot password?
              </Link>
            </div>

            <button className="w-full my-2 p-3 bg-[#000] dark:bg-secondary rounded-lg text-white">
              {auth.loginStatus === "pending" ? (
                <ClipLoader size={20} color={"#fff"} />
              ) : (
                "Sign in"
              )}
            </button>
          </form>
          {/* <a href="https://lobster-app-tr27y.ondigitalocean.app/users/social/login/google" target="_blank" rel="noreferrer"> */}
          {/* <button
            className="w-full mb-2 p-3 bg-transparent border border-black rounded-lg text-black"
            onClick={handleGoogle}
          >
            {loading ? (
              <ClipLoader size={20} color={"#000"} />
            ) : (
              <div className="flex items-center justify-center gap-2">
                <div className="w-[1.5rem]">
                  <img src={google} alt="" />
                </div>
                <div>Sign in with Google</div>
              </div>
            )}
          </button> */}
          {/* </a> */}
          <p className="my-4 text-sm">
            Don't have an account?{" "}
            <Link className="text-red-500" to="/register">
              Create Account
            </Link>
          </p>
          <div className="absolute right-4 top-4">
            <Link to="/">
              <AiOutlineClose size={22} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
