import React, { Fragment, useEffect, useRef, useState } from "react";
import axios from "axios";
import PropagateLoader from "react-spinners/PropagateLoader";
import { baseUrl } from "../utils/baseUrl";
import { MdEventSeat } from "react-icons/md";
import { BsSearch } from "react-icons/bs";
import Pagination from "../components/Pagination";
import Footer from "../components/Footer";
import eventbg from "../images/eventsbg.jpeg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import EventsModal from "../components/EventsModal";
import { BsFillCalendarEventFill } from "react-icons/bs";
import Events from "../EventLayout/Events";

const EventsPage = () => {
  const [events, setEvents] = useState([]);
  const [searchEvents, setSearchEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const auth = useSelector((state) => state.auth);

  function closeAddModal() {
    setIsOpen(false);
  }

  function openAddModal() {
    setIsOpen(true);
  }

  const pageTopRef = useRef(null);

  const url = `${baseUrl}/events/`;
  const searchUrl = `${baseUrl}/events/?search=${query}`;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    if (!auth.token) {
      setLoading(true);
      axios.get(url).then((res) => {
        setEvents(res.data.results);
        // console.log(response.data);
        setLoading(false);
      });
    } else {
      setLoading(true);
      axios
        .get(url, {
          headers: {
            Authorization: `${auth.token}`,
          },
        })
        .then((res) => {
          setEvents(res.data.results);
          // console.log(response.data);
          setLoading(false);
        });
    }
  }, [url, auth.token]);

  const postsPerPage = 6;
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts =
    query.length > 0
      ? searchEvents.slice(firstPostIndex, lastPostIndex)
      : events.slice(firstPostIndex, lastPostIndex);
  // console.log(currentPosts);
  const currentSearchPosts = events.filter((event) => {
    if (query.length === 0) {
      return event;
    } else {
      return event;
    }
  });

  const Loading = () => {
    return (
      <div className="flex items-center justify-center py-9">
        <PropagateLoader color={"#000"} loading={loading} />
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!auth.token) {
        setLoading(true);
        const res = await axios.get(searchUrl);
        setEvents(res.data.results);
        setSearchEvents(res.data.results);
        // console.log(res.data.results);
        setLoading(false);
      } else {
        setLoading(true);
        axios
          .get(searchUrl, {
            headers: {
              Authorization: `${auth.token}`,
            },
          })
          .then((res) => {
            setLoading(false);
            setEvents(res.data.results);
            setSearchEvents(res.data.results);
            // console.log(res.data.results);
          });
      }
    };

    const getData = setTimeout(() => {
      fetchData();
    }, 1000);

    return () => clearTimeout(getData);
  }, [query]);

  const handleSearch = async (e) => {
    e.preventDefault();
  };

  return (
    <div className="pt-[5rem]">
      <div className="w-full h-full">
        <div className="w-full h-[60vh] md:h-[70vh] text-white">
          <div className="w-full h-full">
            <div className="absolute w-full h-[60vh] md:h-[70vh] bg-gradient-to-r from-black"></div>
            <img
              className="w-full h-full object-cover object-center"
              src={eventbg}
              alt="/"
            />

            <div className="absolute w-full top-[63%] md:top-[60%] pl-[1rem]">
              <div className="flex items-center justify-between">
                <div>
                  <h1 className="text-2xl font-bold md:text-6xl">
                    Upcoming Events
                  </h1>
                  <h2 className="text-md md:text-2xl tracking-wide py-2 text-gray-400">
                    All Upcoming Events
                    <MdEventSeat className="inline ml-2" />
                  </h2>
                </div>

                <div>
                  {auth._id && auth.first_name ? (
                    <div>
                      <button
                        onClick={openAddModal}
                        className="border border-white rounded-md px-2 sm:px-6 py-3 mr-2 lg:mr-[5rem] font-bold bg-transparent hover:bg-white cursor-pointer text-white hover:text-black hover:border-white shadow-md hover:shadow-xl md:text-xl"
                      >
                        Add Event
                        <BsFillCalendarEventFill
                          size={16}
                          className=" ml-2 mb-1 hidden sm:inline"
                        />
                      </button>
                    </div>
                  ) : (
                    <Link
                      to="/login"
                      className="border border-white rounded-md px-2 sm:px-6 py-3 mr-2 lg:mr-[5rem] font-bold bg-transparent hover:bg-white cursor-pointer text-white hover:text-black hover:border-white shadow-md hover:shadow-xl md:text-xl"
                    >
                      Add Event
                      <MdEventSeat className=" ml-2 hidden sm:inline" />
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-9 px-2 lg:px-4 max-w-[1240px] mx-auto" ref={pageTopRef}>
        <div className="flex flex-col items-center justify-center">
          <h3 className="text-2xl font-bold py-4">Find all Upcoming Events</h3>
          <div className="flex">
            <form className="relative flex pb-2" onSubmit={handleSearch}>
              <input
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                className=" border border-input border-black px-3 py-2 w-[15rem] sm:w-[30rem] shadow-lg rounded-tl-lg rounded-bl-lg text-black"
                type="text"
                placeholder="Search for Events (City)..."
              />
              <div className="bg-black text-white px-5 py-2 rounded-tr-lg rounded-br-lg shadow-lg hover:shadow-2xl flex items-center justify-between cursor-pointer">
                <BsSearch />
              </div>
            </form>
          </div>
        </div>

        {loading ? (
          <Loading />
        ) : (
          <div className="flex items-center justify-center flex-col py-9">
            <div>
              {currentSearchPosts.length === 0 ? (
                <div className="font-bold w-full text-2xl text-red-500 py-4 text-center">
                  <p>No Events! Check back later!</p>
                </div>
              ) : currentPosts.length === 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 xl:gap-16">
                  {searchEvents.map((event, index) => (
                    <Events key={event._id} event={event} index={index} />
                  ))}
                </div>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 xl:gap-16">
                  {currentPosts.map((event, index) => (
                    <Events key={event._id} event={event} index={index} />
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        <Pagination
          totalPosts={query.length > 0 ? searchEvents.length : events.length}
          postsPerPage={postsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          pageTopRef={pageTopRef}
        />
      </div>
      <Footer />

      {isOpen ? (
        <EventsModal closeAddModal={closeAddModal} isOpen={isOpen} />
      ) : (
        ""
      )}
    </div>
  );
};

export default EventsPage;
