import axios from "axios";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import logo from "../images/logo1.PNG";
import StarsRating from "react-star-ratings";
import { Link, useNavigate } from "react-router-dom";
import { MdChevronRight, MdEdit, MdDelete } from "react-icons/md";
import FadeLoader from "react-spinners/FadeLoader";
import Pagination from "../components/Pagination";
import Footer from "../components/Footer";
import { logoutUser } from "../slices/authSlice";
import ReactTimeAgo from "react-time-ago";
import { baseUrl } from "../utils/baseUrl";
import ReviewModal from "../components/ReviewModal";
import DeleteReviewModal from "../components/DeleteReviewModal";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import LoadingModal from "../components/LoadingModal";

const Review = () => {
  const [options, setOptions] = useState([""]);
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState("");
  const [selectedCities, setSelectedCities] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [itsloading, setItsloading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [clickedReview, setClickedReview] = useState(null);
  const [clickedRating, setClickedRating] = useState(null);
  const [clickedId, setClickedId] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  // console.log(auth);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal(review, index) {
    setIsOpen(true);
    setCurrentIndex(index);
    setClickedReview(review.review);
    setClickedRating(review.rating);
    setClickedId(review._id);
  }

  function closeDeleteModal() {
    setIsOpen2(false);
  }

  function openDeleteModal(review, index) {
    setIsOpen2(true);
    setCurrentIndex(index);
    setClickedId(review._id);
  }

  const styles = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "transparent",
      height: "3rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      borderWidth: "2px",
      borderColor: "#000",
      outline: "2px solid transparent",
      outlineOffset: "2px",
      borderRadius: "0.375rem",
      transitionDuration: "200ms",
      transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
    }),
  };

  const url = `${baseUrl}/cities/`;

  useEffect(() => {
    const getData = async () => {
      const arr = [];
      setItsloading(true);
      await axios.get(url).then((res) => {
        let result = res.data.results;
        // console.log(result);
        result.map((city) => {
          return arr.push({
            value: city._id,
            label: city.name,
          });
        });
        setOptions(arr);
      });
      setItsloading(false);
    };
    getData();
  }, [url]);

  const reviewUrl = `${baseUrl}/reviews/`;

  useEffect(() => {
    if (!auth.token) {
      axios.get(reviewUrl).then((res) => {
        setReviews(res.data.results);
        // console.log(response.data);
      });
    } else {
      axios
        .get(reviewUrl, {
          headers: {
            Authorization: `${auth.token}`,
          },
        })
        .then((res) => {
          setReviews(res.data.results);
          // console.log(response.data);
        });
    }
  }, [reviewUrl, auth.token]);

  const postsPerPage = 6;
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = reviews.slice(firstPostIndex, lastPostIndex);

  const [values, setValues] = useState({
    rating: "",
    review: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const newObj = { ...values, [e.target.name]: e.target.value };
    setValues(newObj);
  };

  const validation = (values) => {
    const errorss = {};
    if (values.review === "") {
      errorss.review = "Review is Required!";
    }
    if (values.rating === "") {
      errorss.rating = "Rating is Required!";
    } else if (values.rating < 1) {
      errorss.rating = "Please input a value from 1 to 5";
    } else if (values.rating > 5) {
      errorss.rating = "Please input a value from 1 to 5";
    }

    return errorss;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors(validation(values));

    if (values.rating >= 1 && values.rating <= 5) {
      try {
        await axios
          .post(
            `${baseUrl}/reviews/`,
            {
              rating: values.rating,
              review: values.review,
              city: selectedCities.value,
            },
            {
              headers: {
                Authorization: `${auth.token}`,
              },
            },
            setLoading(true)
          )
          .then((response) => {
            // console.log(response.data);
            if (response.status === 201) {
              setLoading(false);
              // console.log('success');
              toast.success(
                `Submitted, Thank you for the feedback ${auth.first_name}!`,
                { position: "top-right" }
              );
              // document.getElementById("create-course-form").reset();

              const reloadPage = setTimeout(() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                });
                window.location.reload();
              }, 1000);

              return () => clearTimeout(reloadPage);
            }
          });
      } catch (error) {
        // console.log(error.response);
        if (error.response.status === 401) {
          dispatch(logoutUser(null));
          navigate("/login");
          setError(
            toast.error(
              `Your session expired, Please re-enter your login details.`,
              { position: "top-right" }
            )
          );
          setLoading(false);
        } else if (
          error.response.data.message === "User already posted review"
        ) {
          setError(
            toast.error(
              `A review has been made for this city already, Please make a review on other cities.`,
              { position: "top-right" }
            )
          );
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }
  };

  const Loading = () => {
    return (
      <div className="flex flex-wrap items-center justify-center h-screen">
        <FadeLoader color={"#000"} loading={itsloading} size={150} />
        <p className="text-2xl ">Loading Review...</p>
      </div>
    );
  };

  function closeLoadingModal() {
    setIsLoading(false);
  }

  const handleLikes = async (review, index) => {
    if (auth._id && review.liked === false) {
      setIsLoading(true);
      try {
        await axios
          .post(
            `${baseUrl}/reviews/${review._id}/likes/`,
            {},
            {
              headers: {
                Authorization: `${auth.token}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            setIsLoading(false);
            if (response.status === 201) {
              toast.success(`You liked a review.`, {
                position: "top-right",
              });

              const reloadPage = setTimeout(() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                });
                window.location.reload();
              }, 1000);

              return () => clearTimeout(reloadPage);
            }
          });
      } catch (err) {
        setIsLoading(false);
        toast.error(`Please Try Again`, { position: "top-right" });
        console.log(err.response);
      }
    } else if (review.liked === true) {
      try {
        setIsLoading(true);
        await axios
          .post(
            `${baseUrl}/reviews/${review._id}/likes/`,
            {},
            {
              headers: {
                Authorization: `${auth.token}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            setIsLoading(false);
            if (response.status === 201) {
              toast.success(`You unliked a review.`, {
                position: "top-right",
              });

              const reloadPage = setTimeout(() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                });
                window.location.reload();
              }, 1000);

              return () => clearTimeout(reloadPage);
            }
          });
      } catch (err) {
        setIsLoading(false);
        toast.error(`Please Try Again`, { position: "top-right" });

        console.log(err.response);
      }
    } else {
      navigate("/login");
      toast.info(`Hi there!, Please login to like a review.`, {
        position: "top-right",
      });
    }
  };

  return (
    <div className="pt-[5rem]">
      <div className="w-full bg-[#000] p-7">
        <div className="px-2 lg:px-4 max-w-[1240px] mx-auto">
          <p className="text-white">
            <Link to="/" className="cursor-pointer font-bold">
              Home
            </Link>{" "}
            <MdChevronRight className="inline" />{" "}
            <span className="text-gray-300">Reviews</span>
          </p>
        </div>
      </div>

      {itsloading ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-col items-center justify-center pt-[3rem]">
            <h1 className="font-bold text-3xl md:text-4xl tracking-wid after:w-[60px] after:h-[5px] after:bg-[#000] after:block after:m-auto after:mt-[0.5rem] mb-2">
              Review
            </h1>
            <p className="text-lg my-2 tracking-wide">
              Write a Review about the Cities
            </p>
          </div>

          <div className="max-w-[500px] m-auto px-4 py-9">
            <form
              onSubmit={handleSubmit}
              id="create-course-form"
              className="my-4"
            >
              <div>
                <div className="md:hidden">
                  <label className="font-bold">City*</label>
                  <Select
                    defaultValue={selectedCities}
                    onChange={setSelectedCities}
                    options={options}
                    styles={styles}
                    placeholder="Search or Select a City..."
                    // required
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "grey",
                        primary: "black",
                      },
                    })}
                  />
                </div>

                <label className="relative hidden md:block">
                  <Select
                    defaultValue={selectedCities}
                    onChange={setSelectedCities}
                    options={options}
                    styles={styles}
                    placeholder="Search or Select a City..."
                    // required
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "grey",
                        primary: "black",
                      },
                    })}
                  />
                  <span className="text-xl absolute left-0 top-2 mx-4 text-[#000] bg-[#fff] transform -translate-y-6 -translate-x-3 scale-75 font-bold">
                    City*
                  </span>
                </label>
              </div>

              <div className="my-4">
                <input
                  type="number"
                  placeholder="Rate the city out of 5.0"
                  name="rating"
                  onChange={handleChange}
                  className="w-full h-12 px-6 rounded-md border-[2px] border-black"
                />
                {errors.rating && (
                  <p className="text-sm text-red-600">{errors.rating}</p>
                )}
              </div>

              <div className="my-2">
                <textarea
                  name="review"
                  onChange={handleChange}
                  cols="10"
                  rows="5"
                  placeholder="Leave a Review"
                  className="px-6 border-[2px] border-black w-full rounded-md py-2"
                ></textarea>
                {errors.review && (
                  <p className="text-sm text-red-600">{errors.review}</p>
                )}
              </div>

              <button className="rounded-md h-12 w-full bg-[#000] text-white my-2">
                {loading ? (
                  <ClipLoader size={20} color={"#fff"} />
                ) : (
                  "Submit Review"
                )}
              </button>
            </form>
          </div>

          <hr />
          <div className="py-9">
            <div className="flex items-center justify-center">
              <h1 className="font-bold text-3xl md:text-4xl tracking-wid after:w-[60px] after:h-[5px] after:bg-[#000] after:block after:m-auto after:mt-[0.5rem] mb-2">
                User Reviews
              </h1>
            </div>
            <div className="px-2 lg:px-4 max-w-[1240px] mx-auto">
              <div className="flex items-center justify-center">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 py-8 w-full">
                  {currentPosts?.map((review, index) => (
                    <div
                      className="p-4 rounded-md border border-gray-100 bg-white shadow-sm w-full"
                      key={index}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-4">
                          <div className="w-12 h-12">
                            <img
                              src={logo}
                              alt="kofyimages logo"
                              className="rounded-md"
                            />
                          </div>
                          <h3 className="font-bold mb-4">
                            {review.user.first_name} {review.user.last_name}
                          </h3>
                        </div>
                        <div className="flex items-center gap-4">
                          <div
                            onClick={() => handleLikes(review, index)}
                            className="cursor-pointer relative"
                          >
                            {review.liked ? (
                              <AiFillHeart size={22} color={"#FF0000"} />
                            ) : (
                              <AiOutlineHeart size={22} />
                            )}
                            {review.likes_count > 0 ? (
                              <div className="absolute -top-2 left-3 w-5 h-5 rounded-full flex items-center justify-center">
                                <p className="text-sm font-semibold">
                                  {review.likes_count}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <MdEdit
                            size={18}
                            color={"#000"}
                            className="cursor-pointer"
                            onClick={() => openModal(review, index)}
                          />

                          <MdDelete
                            size={18}
                            color={"#000"}
                            className="cursor-pointer"
                            onClick={() => openDeleteModal(review, index)}
                          />
                        </div>
                      </div>
                      <div className="flex items-center gap-8 mb-4">
                        <StarsRating
                          rating={review.rating}
                          starRatedColor="orange"
                          starDimension="20px"
                          starSpacing="15px"
                        />
                        <div>({review.rating}.0)</div>
                      </div>
                      <div>
                        <p>{review.review}</p>
                      </div>
                      <div className="flex gap-4 items-center">
                        <div>
                          __________{" "}
                          <span className="mt-9 font-bold">{review.city}</span>
                        </div>
                        <div className="text-sm text-gray-500">
                          <ReactTimeAgo
                            date={review.created_at}
                            locale="en-US"
                            timeStyle="mini"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <Pagination
                totalPosts={reviews.length}
                postsPerPage={postsPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
            </div>
          </div>
        </>
      )}
      <Footer />

      {isLoading ? (
          <LoadingModal isLoading={isLoading} closeLoadingModal={closeLoadingModal}/>
        ) : (
          ""
        )}

      {isOpen && clickedReview ? (
        <ReviewModal
          closeModal={closeModal}
          isOpen={isOpen}
          clickedReview={clickedReview}
          clickedRating={clickedRating}
          clickedId={clickedId}
          options={options}
        />
      ) : (
        ""
      )}

      {isOpen2 && clickedId ? (
        <DeleteReviewModal
          closeDeleteModal={closeDeleteModal}
          isOpen2={isOpen2}
          clickedId={clickedId}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Review;
