import Navbar from "./components/Navbar";
import {Routes, Route} from 'react-router-dom'
// import Home from "./components/Home";
import HomePage from "./components/HomePage";
import CityPage from "./pages/CityPage";
import ScrollToTop from "./components/ScrollToTop";
import FoodPage from "./pages/FoodPage";
import FramesPage from "./pages/FramesPage";
import FramedPicturePage from "./pages/FramedPicturePage";
import FramedPaintingsPage from "./pages/FramedPaintingsPage";
import FramedCanvasPage from "./pages/FramedCanvasPage";
import LifestylePage from "./pages/LifestylePage";
import Register from "./pages/RegisterPage";
import Login from "./pages/LoginPage";
import CartPage from "./pages/CartPage";
import NotFound from "./pages/NotFound";
import TermsAndCondition from "./footerPages/TermsAndCondition";
import Faq from "./footerPages/Faq";
import PrivacyPolicy from "./footerPages/PrivacyPolicy";
import ContactUs from "./footerPages/ContactUs";
import CheckoutSuccessPage from "./pages/CheckoutSuccessPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import BookletsPage from "./pages/BookletsPage";
import SearchFlights from "./pages/flights/SearchFlights";
import VideosPage from "./pages/VideosPage";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import "video-react/dist/video-react.css";
import Review from "./pages/ReviewPage";
import UserProfile from "./pages/UserProfilePage";
import FlightPayment from "./pages/flights/FlightPayment";
import BookFlight from "./pages/flights/BookFlight";
import FlightSuccessPage from "./pages/flights/FlightSuccessPage";
import AvailableOneWayFlights from "./pages/flights/oneWay/AvailableOneWayFlights";
import AvailableReturnWayFlights from "./pages/flights/returnWay/AvailableReturnWayFlight";
import OneWayFlightDetails from "./pages/flights/oneWay/OneWayFlightDetails";
import ReturnWayFlightDetails from "./pages/flights/returnWay/ReturnWayFlightDetails";
import EventsPage from "./pages/EventsPage";
import WeatherPage from "./pages/WeatherPage";
import GoogleLoginPage from "./pages/GoogleLoginPage";
import ArticlesPage from "./pages/ArticlesPage";



function App() {
  
  return (
    <div className="App">
      <ToastContainer/>
      <ScrollToTop/>
        <Navbar/>
        <Routes>
          {/* <Route path="/" element={<Home/>} /> */}
          <Route path="/" element={<HomePage/>} />
          <Route path="/register" element={<Register/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/review" element={<Review/>}/>
          <Route path="/user-profile" element={<UserProfile/>}/>
          <Route path="/cart" element={<CartPage/>}/>
          <Route path="/termsandcondition" element={<TermsAndCondition/>}/>
          <Route path="/faq" element={<Faq/>}/>
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="/contact-us" element={<ContactUs/>}/>
          <Route path="/checkout-success" element={<CheckoutSuccessPage/>}/>
          <Route path="/forgot-password" element={<ForgotPasswordPage/>}/>
          <Route path="/one-way-available-flights" element={<AvailableOneWayFlights/>}/>
          <Route path="/return-way-available-flights" element={<AvailableReturnWayFlights/>}/>
          <Route path="/one-way-flight-details" element={<OneWayFlightDetails/>}/>
          <Route path="/return-way-flight-details" element={<ReturnWayFlightDetails/>}/>
          <Route path="/payment" element={<FlightPayment/>}/>
          <Route path="/book-flight" element={<BookFlight/>}/>
          <Route path="/flight-success" element={<FlightSuccessPage/>}/>
          <Route path="/events" element={<EventsPage/>}/>
          <Route path="/weather" element={<WeatherPage/>}/>
          <Route path="/password-reset/:resetId" element={<ResetPasswordPage/>}/>
          <Route path="/login/success" element={<GoogleLoginPage/>}/>
          <Route path="/cities/:cityId" element={<CityPage/>}/>
          <Route path="/cities/:cityId/:cityId/foods" element={<FoodPage/>}/>
          <Route path="/cities/:cityId/:cityId/lifestyles" element={<LifestylePage/>}/>
          {/* <Route path="/cities/:cityId/:cityId/frames" element={<FramesPage/>}/> */}
          <Route path="/cities/:cityId/:cityId/booklets" element={<BookletsPage/>}/>
          <Route path="/cities/:cityId/:cityId/videos" element={<VideosPage/>}/>
          <Route path="/cities/:cityId/:cityId/articles" element={<ArticlesPage/>}/>
          <Route path="/cities/:cityId/:cityId/flights" element={<SearchFlights/>}/>
          <Route path="/frames" element={<FramedPicturePage/>}/>
          {/* <Route path="/cities/:cityId/:cityId/frames/:cityId/paintings" element={<FramedPaintingsPage/>}/> */}
          {/* <Route path="/cities/:cityId/:cityId/frames/:cityId/canvas" element={<FramedCanvasPage/>}/> */}
          <Route path='*' element={<NotFound/>}/>
        </Routes>
    </div>
  );
}

export default App;
