import React, { useEffect } from "react";
import "../css/WeatherPage.css";
import axios from "axios";
import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { MdLocationPin } from "react-icons/md";
import {
  TiWeatherWindyCloudy,
  TiWeatherShower,
  TiWeatherCloudy,
} from "react-icons/ti";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";

const WeatherPage = () => {
  const [data, setData] = useState({});
  const [location, setLocation] = useState("");
  const [ip, setIp] = useState("");
  const [loading, setLoading] = useState(false);
  const [location2, setLocation2] = useState(null);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    setLoading(true)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      setLoading(false)
      console.log("Geolocation not supported");
      toast.info(`Geolocation not supported.`, {
        position: "top-right",
      });
    }

    function success(position) {
      setLoading(false)
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
      setLat(latitude)
      setLong(longitude)
    }
  
    function error() {
      setLoading(false)
      console.log("Unable to retrieve your location");
      toast.error(`Unable to retrieve your location.`, {
        position: "top-right",
      });
    }
  }, []);


  // const ipUrl = `https://geo.ipify.org/api/v2/country,city?apiKey=${process.env.REACT_APP_IP_API_KEY}&ipAddress`;

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //   });
  //   axios
  //     .get(ipUrl)
  //     .then((response) => {
  //       // console.log(response.data);
  //       setIp(response.data.location.city);
  //     })
  //     .catch((error) => {
  //       if (error.response) {
  //         console.log(error.response.data.messages);
  //       }
  //     });
  // }, [ipUrl]);

  const loadUrl = `http://api.weatherapi.com/v1/forecast.json?key=${process.env.REACT_APP_API_KEY}&q=${lat},${long}`;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    setLoading(true);
    axios
      .get(loadUrl)
      .then((response) => {
        console.log(response.data);
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  }, [loadUrl]);

  const url = `http://api.weatherapi.com/v1/forecast.json?key=${process.env.REACT_APP_API_KEY}&q=${location}`;

  const searchLocation = (event) => {
    if (event.key === "Enter") {
      axios.get(url).then((response) => {
        setData(response.data);
        console.log(response.data);
      });
    }
  };

  const Loading = () => {
    return (
      <div className="flex items-center justify-center h-[60vh] mb-[5rem]">
        <ClipLoader color={"#000"} loading={loading} />
      </div>
    );
  };

  return (
    <div className="pt-[5rem]">
      <div className="main">
        <div className="flex justify-center items-center pt-9">
          <div className="relative">
            <input
              type="text"
              value={location}
              onChange={(event) => setLocation(event.target.value)}
              onKeyPress={searchLocation}
              onKeyUp={searchLocation}
              onKeyDown={searchLocation}
              placeholder="Search city e.g. London"
              className="bg-transparent border border-white py-3 px-12 rounded-lg relative"
            />

            <div className="absolute top-3.5 left-3">
              <AiOutlineSearch size={22} color={"#fff"} />
            </div>
          </div>
        </div>

        {loading ? (
          <Loading />
        ) : (
          <div className="flex flex-col items-center justify-center">
            <div className="py-9">
              {data.location ? (
                <div className="flex items-center justify-center gap-2 text-center">
                  <MdLocationPin size={22} color={"#fff"} />
                  <p className="text-xl font-bold text-center">
                    {data.location.name}, {data.location.country}
                  </p>
                </div>
              ) : null}

              {data.current ? (
                <div className="flex items-center justify-center">
                  <img
                    src={data.current.condition.icon}
                    alt=""
                    className="w-[150px] h-[150px]"
                  />
                </div>
              ) : null}

              {data.forecast ? (
                <div className="text-center pb-4">
                  <h1 className="text-6xl md:text-8xl font-bold">
                    {data.forecast.forecastday[0].day.avgtemp_c.toFixed()}℃
                  </h1>
                </div>
              ) : null}

              {data.current ? (
                <div className="text-center text-2xl font-bold pb-2">
                  <p>{data.current.condition.text}</p>
                </div>
              ) : null}

              {data.forecast ? (
                <div className="text-center text-xl font-bold">
                  <p>{data.forecast.forecastday[0].date}</p>
                </div>
              ) : null}
            </div>

            {data.forecast && (
              <div className="flex items-center gap-20 md:gap-24 py-4 bottom px-6 md:px-16 rounded-md mb-5">
                <div>
                  <TiWeatherCloudy
                    size={40}
                    color={"#fff"}
                    className="text-center py-1"
                  />
                  <p className="font-bold text-center">
                    {data.forecast.forecastday[0].day.mintemp_c.toFixed()}℃
                  </p>
                </div>
                <div>
                  <TiWeatherShower
                    size={40}
                    color={"#fff"}
                    className="text-center py-1"
                  />
                  <p className="font-bold text-center">
                    {data.forecast.forecastday[0].day.avghumidity}%
                  </p>
                </div>
                <div>
                  <TiWeatherWindyCloudy
                    size={40}
                    color={"#fff"}
                    className="text-center py-1"
                  />
                  <p className="font-bold text-center">
                    {data.forecast.forecastday[0].day.avgvis_km.toFixed()}km/h
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default WeatherPage;
