import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import { BsFillCalendarEventFill } from "react-icons/bs";
import { BiWorld } from "react-icons/bi";
import {
  MdLocationPin,
  MdDateRange,
  MdAccessTimeFilled,
  MdDescription,
  MdLocationCity,
} from "react-icons/md";
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TimePicker } from "antd";

const EditEventsModal = ({
  closeEditModal,
  isOpen1,
  clickedCity,
  clickedCountry,
  clickedName,
  clickedLocation,
  clickedDescription,
  clickedDate,
  clickedTime,
  clickedImage,
  clickedId,
}) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    city: clickedCity,
    country: clickedCountry,
    eventName: clickedName,
    eventLocation: clickedLocation,
    eventDescription: clickedDescription,
    eventDate: "",
    eventTime: "",
  });
  const [imageValue, setImageValue] = useState({
    preview: "",
    data: "",
  });

  const handleImage = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImageValue(img);
  };

  const handleChange = (e) => {
    const newObj = { ...values, [e.target.name]: e.target.value };
    setValues(newObj);
    console.log(newObj);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", imageValue.data);
    formData.append("city", values.city);
    formData.append("country", values.country);
    formData.append("name", values.eventName);
    formData.append("location", values.eventLocation);
    formData.append("description", values.eventDescription);
    formData.append("time", values.eventTime.$d.toLocaleTimeString("it-IT"));
    formData.append("date", moment.parseZone(values.eventDate).format().split("T")[0]);

    try {
      const AuthToken = await localStorage.getItem("token");
      setLoading(true);
      const response = await fetch(`${baseUrl}/events/${clickedId}/`, {
        method: "PUT",
        headers: {
          Authorization: AuthToken,
        },
        body: formData,
      });
    //   console.log(response);
      if (response.status === 200) {
        setLoading(false);
        toast.success(`Event Updated Successfully!`, {
          position: "top-right",
        });

        const reloadPage = setTimeout(() => {
          window.location.reload();
        }, 1000);

        return () => clearTimeout(reloadPage);
      }
      if (response.status === 401) {
        setLoading(false);
        toast.error(`You are not allowed to update this Event!`, {
          position: "top-right",
        });
      }
      if (response.status === 400) {
        setLoading(false);
        toast.error(`Event Update Failed, Please try again!`, {
          position: "top-right",
        });
      }
    } catch (error) {
    //   console.error(error);
      setLoading(false);
      toast.error(`Please Try Again`, { position: "top-right" });
      setLoading(false);
      if (error.response.status === 400) {
        setLoading(false);
        toast.error(`Event Update Failed, Please try again!`, {
          position: "top-right",
        });
      }
    }
  };

  return (
    <div>
      <Transition appear show={isOpen1} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeEditModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/80" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all mt-[4.3rem]">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 flex flex-col items-center justify-center"
                  >
                    <p className="font-bold text-2xl py-1">Update Event</p>
                    <p className="font-bold text-center tracking-wider">
                      Update your Upcoming Event
                    </p>
                  </Dialog.Title>
                  <div className="mt-2">
                    <form className="pt-2" onSubmit={handleSubmit}>
                      <div className="my-3 relative">
                        <input
                          type="text"
                          name="city"
                          value={values.city}
                          placeholder="Enter City Name"
                          onChange={handleChange}
                          required
                          className="pl-9 border border-[#000] text-[#000] bg-transparent rounded-md py-3 h-12 w-full"
                        />
                        <MdLocationCity
                          size={14}
                          className="absolute top-4 left-3"
                        />
                      </div>

                      <div className="my-3 relative">
                        <input
                          type="text"
                          name="country"
                          value={values.country}
                          placeholder="Enter Country Name"
                          onChange={handleChange}
                          required
                          className="pl-9 border border-[#000] text-[#000] bg-transparent rounded-md py-3 h-12 w-full"
                        />
                        <BiWorld size={14} className="absolute top-4 left-3" />
                      </div>

                      <div className="my-3 relative">
                        <input
                          type="text"
                          name="eventName"
                          value={values.eventName}
                          placeholder="Event Name"
                          onChange={handleChange}
                          required
                          className="pl-9 border border-[#000] text-[#000] bg-transparent rounded-md py-3 h-12 w-full"
                        />
                        <BsFillCalendarEventFill
                          size={14}
                          className="absolute top-4 left-3"
                        />
                      </div>

                      <div className="my-3 md:my-5 relative">
                        <input
                          type="text"
                          name="eventLocation"
                          value={values.eventLocation}
                          placeholder="Event Location"
                          onChange={handleChange}
                          required
                          className="pl-9 border border-[#000] text-[#000] bg-transparent rounded-md py-3 h-12 w-full"
                        />
                        <MdLocationPin className="absolute top-4 left-3" />
                      </div>

                      <div className="my-3 md:my-5 relative">
                        <textarea
                          cols="10"
                          rows="5"
                          name="eventDescription"
                          value={values.eventDescription}
                          placeholder="Event Description"
                          onChange={handleChange}
                          required
                          className="pl-9 border border-[#000] text-[#000] bg-transparent rounded-md py-3 w-full"
                        ></textarea>
                        <MdDescription className="absolute top-4 left-3"/>
                      </div>

                      <div className="my-3">
                        <div className="md:hidden relative">
                          <label className="font-bold">Event Date</label>
                          <DatePicker
                            required
                            onChange={(e) => {
                              handleChange({
                                target: { name: "eventDate", value: e },
                              });
                            }}
                            value={values.eventDate ? values.eventDate : ""}
                            selected={values.eventDate ? values.eventDate : ""}
                            name="eventDate"
                            dateFormat="yyyy-MM-dd"
                            placeholderText="yyyy-mm-dd"
                            className="h-12 w-full pl-9 border border-[#555353]  bg-transparent rounded-md"
                          />
                          <MdDateRange className="absolute top-10 left-3" />
                        </div>

                        <div className="relative hidden lg:block">
                          <DatePicker
                            closeOnScroll={true}
                            dateFormat="yyyy-MM-dd"
                            required
                            onChange={(e) => {
                              handleChange(
                                { target: { name: "eventDate", value: e } }
                                //   console.log(e.toISOString().split("T")[0])
                              );
                            }}
                            value={values.eventDate ? values.eventDate : ""}
                            selected={values.eventDate ? values.eventDate : ""}
                            name="eventDate"
                            placeholderText="yyyy-mm-dd"
                            className="h-12 w-full px-9 border border-[#555353] outline-none focus:border-[#000] focus:text-[#000] transition duration-200 bg-transparent rounded-md"
                          />
                          <span className="text-xl absolute left-0 top-2 mx-4 text-[#000] bg-white transform -translate-y-6 -translate-x-3 scale-75 font-bold">
                            Event Date
                          </span>
                          <MdDateRange className="absolute top-4 left-3" />
                        </div>
                      </div>

                      <div className="mt-6">
                        <div className="md:hidden relative">
                          <label className="font-bold">Event Time</label>
                          <TimePicker
                            required
                            onChange={(e) => {
                              handleChange({
                                target: { name: "eventTime", value: e },
                              });
                            }}
                            renderExtraFooter={() => (
                              <div
                                size="small"
                                type="primary"
                                className="bg-black h-6 rounded-md w-8 absolute right-[14px] bottom-[9px] z-index-[-100]"
                              >
                                OK
                              </div>
                            )}
                            value={values.eventTime ? values.eventTime : ""}
                            selected={values.eventTime ? values.eventTime : ""}
                            name="eventTime"
                            size="large"
                            className="h-12 w-full pl-9 border border-[#555353] bg-transparent rounded-md"
                          />
                          <MdAccessTimeFilled className="absolute top-10 left-3" />
                        </div>

                        <label className="relative hidden lg:block my-2">
                          <TimePicker
                            required
                            onChange={(e) => {
                              handleChange(
                                { target: { name: "eventTime", value: e } }
                                //   console.log(e.$d.toLocaleTimeString('it-IT'))
                              );
                            }}
                            renderExtraFooter={() => (
                              <div
                                size="small"
                                type="primary"
                                className="bg-black h-6 rounded-md w-8 absolute right-[14px] bottom-[9px] z-index-[-100]"
                              >
                                OK
                              </div>
                            )}
                            value={values.eventTime ? values.eventTime : ""}
                            selected={values.eventTime ? values.eventTime : ""}
                            name="eventTime"
                            size="large"
                            className="h-12 pl-9 w-full border border-[#555353] outline-none focus:border-[#000] focus:text-[#000] transition duration-200 bg-transparent rounded-md"
                          />
                          <span className="text-xl absolute left-0 top-2 mx-4 text-[#000] bg-white transform -translate-y-6 -translate-x-3 scale-75 font-bold">
                            Event Time
                          </span>
                          <MdAccessTimeFilled className="absolute top-4 left-3" />
                        </label>
                      </div>

                      <div className="my-3">
                        <h2 className="font-bold">Upload Event Image:</h2>
                        <input
                          type="file"
                          onChange={handleImage}
                          required
                          name="file"
                        />
                        <img src={imageValue.preview} alt="/" />
                      </div>

                      <div className="flex flex-col items-center justify-center">
                        <button
                          type="submit"
                          className="rounded-md h-12 w-full bg-black text-white"
                        >
                          {loading ? (
                            <ClipLoader size={20} color={"#fff"} />
                          ) : (
                            "Update Event"
                          )}
                        </button>
                        <div
                          onClick={closeEditModal}
                          className="text-red-500 cursor-pointer pt-4"
                        >
                          Cancel
                        </div>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default EditEventsModal;
