import React, { useState } from "react";
import {
  MdLocationPin,
  MdDateRange,
  MdAccessTime,
  MdEdit,
  MdDelete,
  MdLocationCity,
} from "react-icons/md";
import { HiArrowNarrowRight } from "react-icons/hi";
import { FaUser } from "react-icons/fa";
import EditEventsModal from "../components/EditEventsModal";
import DeleteEventsModal from "../components/DeleteEventsModal";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../utils/baseUrl";
import { useSelector } from "react-redux";
import LoadingModal from "../components/LoadingModal";
import AboutEventsModal from "../components/AboutEventsModal";

const Events = ({ event, index }) => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentIndex1, setCurrentIndex1] = useState(null);
  const [clickedCity, setClickedCity] = useState(null);
  const [clickedCity1, setClickedCity1] = useState(null);
  const [clickedCountry, setClickedCountry] = useState(null);
  const [clickedCountry1, setClickedCountry1] = useState(null);
  const [clickedName, setClickedName] = useState(null);
  const [clickedName1, setClickedName1] = useState(null);
  const [clickedLocation, setClickedLocation] = useState(null);
  const [clickedLocation1, setClickedLocation1] = useState(null);
  const [clickedDescription, setClickedDescription] = useState(null);
  const [clickedDescription1, setClickedDescription1] = useState(null);
  const [clickedDate, setClickedDate] = useState(null);
  const [clickedDate1, setClickedDate1] = useState(null);
  const [clickedTime, setClickedTime] = useState(null);
  const [clickedTime1, setClickedTime1] = useState(null);
  const [clickedImage, setClickedImage] = useState(null);
  const [clickedImage1, setClickedImage1] = useState(null);
  const [clickedId, setClickedId] = useState(null);
  const [clickedId1, setClickedId1] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  // console.log(auth);

  function closeDeleteModal() {
    setIsOpen2(false);
  }

  function openDeleteModal(event, index) {
    setIsOpen2(true);
    setCurrentIndex(index);
    setClickedId(event._id);
  }

  function closeEditModal() {
    setIsOpen1(false);
  }

  function openEditModal(event, index) {
    setIsOpen1(true);
    setCurrentIndex(index);
    setClickedCity(event.city);
    setClickedCountry(event.country);
    setClickedName(event.name);
    setClickedLocation(event.location);
    setClickedDescription(event.description);
    setClickedDate(event.date);
    setClickedTime(event.time);
    setClickedImage(event.image);
    setClickedId(event._id);
  }

  function closeAboutModal() {
    setIsOpen3(false);
  }

  function openAboutModal(event, index) {
    setIsOpen3(true);
    setCurrentIndex1(index);
    setClickedCity1(event.city);
    setClickedCountry1(event.country);
    setClickedName1(event.name);
    setClickedLocation1(event.location);
    setClickedDescription1(event.description);
    setClickedDate1(event.date);
    setClickedTime1(event.time);
    setClickedImage1(event.image);
    setClickedId1(event._id);
  }

  const truncateString = (str, num) => {
    if (str?.length > num) {
      return str.slice(0, num) + "";
    } else {
      return str;
    }
  };

  function closeLoadingModal() {
    setIsLoading(false);
  }

  const handleLikes = async (event, index) => {
    if (auth._id && event.liked === false) {
      setIsLoading(true);
      try {
        await axios
          .post(
            `${baseUrl}/events/${event._id}/likes/`,
            {},
            {
              headers: {
                Authorization: `${auth.token}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            setIsLoading(false);
            if (response.status === 201) {
              toast.success(`You liked an Upcoming Event.`, {
                position: "top-right",
              });

              const reloadPage = setTimeout(() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                });
                window.location.reload();
              }, 1000);

              return () => clearTimeout(reloadPage);
            }
          });
      } catch (err) {
        setIsLoading(false);
        toast.error(`Please Try Again`, { position: "top-right" });
        console.log(err.response);
      }
    } else if (event.liked === true) {
      try {
        setIsLoading(true);
        await axios
          .post(
            `${baseUrl}/events/${event._id}/likes/`,
            {},
            {
              headers: {
                Authorization: `${auth.token}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            setIsLoading(false);
            if (response.status === 201) {
              toast.success(`You unliked an Upcoming Event.`, {
                position: "top-right",
              });

              const reloadPage = setTimeout(() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                });
                window.location.reload();
              }, 1000);

              return () => clearTimeout(reloadPage);
            }
          });
      } catch (err) {
        setIsLoading(false);
        toast.error(`Please Try Again`, { position: "top-right" });

        console.log(err.response);
      }
    } else {
      navigate("/login");
      toast.info(`Hi there!, Please login to like an Event.`, {
        position: "top-right",
      });
    }
  };

  return (
    <div>
      <div className="rounded-lg border border-gray-500 shadow-lg p-4 w-[350px]">
        <div className="w-full h-full relative">
          <div className="absolute w-full h-[300px] bg-black opacity-20 rounded-lg"></div>
          <img
            src={event.image}
            alt="/"
            className="rounded-lg h-[300px] w-full"
          />

          <div className="absolute w-full top-[42%] px-[1rem] flex items-center justify-between">
            <div>
              <h1 className="font-extrabold py-1 text-white tracking-wider text-md">
                {event.name}
              </h1>
            </div>
            <div className="flex items-center gap-4">
              <div
                onClick={() => handleLikes(event, index)}
                className="cursor-pointer relative"
              >
                {event.liked === true ? (
                  <AiFillHeart size={22} color={"#FF0000"} />
                ) : (
                  <AiOutlineHeart size={22} color={"FFF"} />
                )}
                {event.likes_count > 0 ? (
                  <div className="absolute -top-2 left-3 w-5 h-5 rounded-full flex items-center justify-center">
                    <p className="text-sm text-white font-semibold">
                      {event.likes_count}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {auth._id === event.owner_id ? (
                <div className="flex items-center gap-4">
                  <MdEdit
                    color={"#fff"}
                    size={18}
                    className="cursor-pointer"
                    onClick={() => openEditModal(event, index)}
                  />
    
                  <MdDelete
                    color={"#fff"}
                    size={18}
                    className="cursor-pointer"
                    onClick={() => openDeleteModal(event, index)}
                  />
                </div>
              ) : ("")}
            </div>
          </div>

          <div className="pt-3 relative">
            <p className="font-bold py-1">
              <FaUser className="inline mr-2" />
              {event.owner}
            </p>
            <p className="font-bold py-1">
              <MdLocationCity className="inline mr-2" />
              {event.city}, {event.country}
            </p>
            <p className="font-bold py-1">
              <MdLocationPin className="inline mr-2" />
              {event.location}
            </p>
            <p className="font-bold py-1">
              <MdDateRange className="inline mr-2" />
              {event.date}
            </p>
            <p className="font-bold pt-1">
              <MdAccessTime className="inline mr-2" />
              {truncateString(event.time, 5)}
            </p>
          </div>
          <button
            className="w-full py-3 px-6 bg-black dark:bg-button rounded-md text-white cursor-pointer mt-3 group flex items-center justify-center"
            onClick={() => openAboutModal(event, index)}
          >
            Event Details{" "}
            <span className="group-hover:rotate-90 duration-300">
              <HiArrowNarrowRight className="ml-3" />
            </span>
          </button>
        </div>
      </div>

      {isOpen1 ? (
        <EditEventsModal
          closeEditModal={closeEditModal}
          isOpen1={isOpen1}
          clickedCity={clickedCity}
          clickedCountry={clickedCountry}
          clickedName={clickedName}
          clickedLocation={clickedLocation}
          clickedDescription={clickedDescription}
          clickedDate={clickedDate}
          clickedTime={clickedTime}
          clickedImage={clickedImage}
          clickedId={clickedId}
        />
      ) : (
        ""
      )}

      {isOpen2 ? (
        <DeleteEventsModal
          closeDeleteModal={closeDeleteModal}
          isOpen2={isOpen2}
          clickedId={clickedId}
        />
      ) : (
        ""
      )}

      {isOpen3 ? (
        <AboutEventsModal
          closeAboutModal={closeAboutModal}
          isOpen3={isOpen3}
          clickedCity1={clickedCity1}
          clickedCountry1={clickedCountry1}
          clickedName1={clickedName1}
          clickedLocation1={clickedLocation1}
          clickedDescription1={clickedDescription1}
          clickedDate1={clickedDate1}
          clickedTime1={clickedTime1}
          clickedImage1={clickedImage1}
          clickedId1={clickedId1}
        />
      ) : (
        ""
      )}

      {isLoading ? (
        <LoadingModal
          isLoading={isLoading}
          closeLoadingModal={closeLoadingModal}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Events;
