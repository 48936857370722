import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FadeLoader from "react-spinners/FadeLoader";
import { baseUrl } from "../utils/baseUrl";
import DOMPurify from "dompurify";
import ReactMarkdown from "react-markdown";
import Footer from "../components/Footer";
import articlesImg from "../images/articles.jpg";

const ArticlesPage = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { cityId } = useParams();

  const url = `${baseUrl}/cities/${cityId}/articles`;

  useEffect(() => {
    setLoading(true);
    axios.get(url).then((response) => {
      setArticles(response.data.results);
      console.log(response.data.results);
      setLoading(false);
    });
  }, [url]);

  const Loading = () => {
    return (
      <div className="flex items-center justify-center h-screen">
        <FadeLoader color={"#000"} loading={loading} size={150} />
        <p className="text-2xl ">Loading Article...</p>
      </div>
    );
  };

  const ShowArticles = () => {
    return (
      <div className="pt-[5rem]">
        <div className="w-full h-full">
          <div className="w-full h-[60vh] text-white">
            <div className="w-full h-full">
              <div className="absolute w-full h-[60vh] bg-gradient-to-r from-black"></div>
              <img
                className="w-full h-full object-cover object-center"
                src={articlesImg}
                alt="/"
              />

              <div className="absolute w-full top-[38%] md:top-[35%] text-center">
                <h1 className="text-4xl font-bold md:text-6xl">
                  {articles[0]?.title}
                </h1>
              </div>
            </div>

            <div>
                <div>
                  <div className="px-2 lg:px-4 max-w-[1240px] mx-auto">
                    <div className="py-4 text-black article">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            articles[0]?.content_rendered
                              ? articles[0].content_rendered
                              : ""
                          ),
                        }}
                      ></p>
                      {/* <ReactMarkdown>{articles[0]?.content}</ReactMarkdown> */}
                    </div>
                  </div>
                  <Footer />
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>{loading ? <Loading /> : <ShowArticles />}</div>
    </div>
  );
};

export default ArticlesPage;
