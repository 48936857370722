import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { clearCart } from "../slices/cartSlice";

const CheckoutSuccessPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(clearCart());
  });

  return (
    <div className="pt-[5rem]">
      <div className="flex flex-col items-center justify-center py-16">
        <h2 className="font-bold text-3xl tracking-wide py-2">Success!!!</h2>
        <p className="py-2">Your Order has been created successfully</p>
        <p className="pb-2">
          We will let you know when your order is on its way
        </p>
        <Link to="/" className="underline">
          {" "}
          Return to homepage
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default CheckoutSuccessPage;
