import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { MdChevronRight } from "react-icons/md";
import Footer from "../components/Footer";

const Faq = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  return (
    <div className="pt-[5rem]">
      <div className="w-full bg-[#000] p-7">
        <div className="px-2 lg:px-4 max-w-[1240px] mx-auto">
          <p className="text-white">
            <Link to="/" className="cursor-pointer font-bold">
              Home
            </Link>{" "}
            <MdChevronRight className="inline" />{" "}
            <span className="text-gray-300">FAQ</span>
          </p>
        </div>
      </div>
      <div className="px-2 lg:px-4 max-w-[1240px] mx-auto">
        <div className="py-4">
          <p className="text-3xl font-bold py-2 ">What is KofyImages?</p>
          <p>
            KofyImages is a platform to explore Cities through pictures. It
            gives first hand view of Cities and also gives the user an
            experience of various cities available on the platform
          </p>
        </div>

        <div className="py-4 ">
          <p className="text-3xl font-bold py-2 ">How to place an order ?</p>
          <p>
            You can buy a frame by selecting from available cities, then place
            an order and checkout
          </p>
        </div>

        <div className="py-4 ">
          <p className="text-3xl font-bold py-2 ">
            How do I create an account with KofyImages?
          </p>
          <p>Create an account by signing up to become a user.</p>
        </div>

        <div className="py-4 ">
          <p className="text-3xl font-bold py-2 ">
            What payments methods can I use?
          </p>
          <p>
            You can only pay by providing your card information at the checkout
            using either your Visa, Mastercard, debit cards
          </p>
        </div>

        <div className="py-4 ">
          <p className="text-3xl font-bold py-2 ">Tracking my order</p>
          <p>
            KofyImages will send tracking details once an item is shipped for
            delivery
          </p>
        </div>

        <div className="py-4 ">
          <p className="text-3xl font-bold py-2 ">
            How many days does it take to receive an order?
          </p>
          <p>
            For delivery, your product will be delivered between 1-7 days, at
            most 7 days
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Faq;
