import React, { useState } from "react";
import Footer from "../components/Footer";
import emailjs from "emailjs-com";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { MdChevronRight } from "react-icons/md";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    emailjs
      .sendForm(
        "service_pgywu8s",
        "template_igc72ri",
        e.target,
        "YOjym2Spg4YOheCI2"
      )
      .then((res) => {
        // console.log(res);
        // navigate("/contact-success");
        setLoading(false);
        toast.success(
          `Submitted, Thank you for contacting us!`,
          { position: "top-right" }
        );
        navigate("/");
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  return (
    <div className="pt-[5rem]">
      <div className="w-full bg-[#000] p-7">
        <div className="px-2 lg:px-4 max-w-[1240px] mx-auto">
          <p className="text-white">
            <Link to="/" className="cursor-pointer font-bold">
              Home
            </Link>{" "}
            <MdChevronRight className="inline" />{" "}
            <span className="text-gray-300">Contact us</span>
          </p>
        </div>
      </div>
      <div className="max-w-[800px] m-auto px-4 py-16">
        <div className="bg-[#F5F5F5] shadow-xl px-10 py-8 rounded-lg">
          <h1 className="text-2xl font-bold">Contact Us</h1>
          <form onSubmit={handleSubmit}>
            <input
              className="w-full p-2 border border-gray-400 bg-transparent rounded-lg my-4"
              type="text"
              placeholder="Fullname"
              name="Name"
              required
            />
            <input
              className="w-full p-2 border border-gray-400 bg-transparent rounded-lg my-4"
              type="email"
              placeholder="Email"
              name="Email"
              required
            />
            <input
              className="w-full p-2 border border-gray-400 bg-transparent rounded-lg my-4"
              type="text"
              placeholder="Subject"
              name="Subject"
              required
            />
            <textarea
              className="w-full p-2 border border-gray-400 bg-transparent rounded-lg my-4"
              name="Message"
              rows="5"
              placeholder="Message"
              required
            />

            <button className="w-full my-4 md:my-2 p-3 bg-[#000] text-white rounded-lg">
              {loading ? (
                  <ClipLoader size={20} color={"#fff"} />
                ) : (
                  "Submit"
              )}
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
