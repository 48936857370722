import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../utils/baseUrl";
import { toast } from "react-toastify";

const GoogleLoginPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const userId = searchParams.get("userId");
  const navigate = useNavigate();

  useEffect(() => {
    try {
      axios
        .get(`${baseUrl}/users/${userId}/`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          // console.log(response);
          // console.log(response.data);
          if (response) {
            window.localStorage.setItem("token", token);
            window.localStorage.setItem("first_name", response.data.first_name);
            window.localStorage.setItem('last_name', response.data.last_name)
            window.localStorage.setItem('email', response.data.email)
            window.localStorage.setItem('phone', response.data.phone)
            toast.success(`Welcome, ${response.data.first_name}`, {
              position: "bottom-left",
            });
            navigate("/");
          }
        });
    } catch (err) {
      console.error("Failed to fetch user data:", err);
      toast.error("Login failed. Please try again.", { position: "top-right" });
      navigate("/login");
    }
  }, [token, userId, navigate]);


  return <div></div>;
};

export default GoogleLoginPage;
