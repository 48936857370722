import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logowhite.png";
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
import { SiMinutemailer } from "react-icons/si";
import emailjs from 'emailjs-com'
import { toast } from 'react-toastify';
import { useState } from "react";
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault()

        emailjs.sendForm(
            'service_pgywu8s',
            'template_rrqm0eu',
            e.target,
            'YOjym2Spg4YOheCI2'
        ).then((res) => {
            // console.log(res);
            if (res.status === 200) {
              // console.log('success');
              toast.success(`Thank you for subscribing!`, {position: "top-right"})
              setEmail('')
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleRefresh = () => {
      window.reload(false);
      window.scrollTo({
        top: 0,
        left: 0,
      });
    };
  return (
    <div className="bg-black dark:bg-[#0e0e10]  w-full text-white">
      <div className="py-5 px-2 lg:px-4 max-w-[1240px] mx-auto">
        <div className="px-4 sm:px-0">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 lg:gap-0">
            <div>
              <Link to="/" onClick={handleRefresh} className="cursor-pointer">
                <img src={logo} alt="/" width={150} />
              </Link>
              <p>Got a burning question?</p>
              <p>Reach us 24/7</p>
              <a href="mailto:info@kofyimages.com">info@kofyimages.com</a>
            </div>

            <div className="sm:mt-[6rem]">
              <h2 className="font-bold text-lg">Company</h2>
              <ul className="py-4">
                <li className="py-2 cursor-pointer">
                  <HashLink to="/#cities" smooth={true} offset={50} duration={500}>Cities</HashLink>
                </li>
                <li className="py-2 cursor-pointer">
                  <Link to="/faq">FAQ</Link>
                </li>
                <li className="py-2 cursor-pointer">
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>
            </div>

            <div className="lg:mt-[6rem]">
              <h2 className="font-bold text-lg">Customer</h2>
              <ul className="py-4">
                <li className="py-2 cursor-pointer">
                  <Link to="/cart">View Cart</Link>
                </li>
                <li className="py-2 cursor-pointer">
                  <Link to="/termsandcondition">Terms and condition</Link>
                </li>
                <li className="py-2 cursor-pointer">
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>

            <div className="lg:mt-[6rem]">
              <p className="font-bold text-lg">Subscribe</p>
              <p className="xl:w-[20rem] py-4">
                We update our catalog regularly, Subscribe to stay updated.
              </p>
              <div className="py-2">
                <form onSubmit={handleSubmit} className="flex items-center">
                  <input
                    className="bg-transparent border border-input p-2 rounded-lg rounded-r-none w-auto"
                    type="email"
                    placeholder="Enter Your Email"
                    name="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <button type="submit" className="bg-white text-black p-2 rounded-lg rounded-l-none shadow-xl w-auto">
                    <SiMinutemailer size={25} />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#121212] dark:bg-secondary text-white py-4 lg:py-0">
        <div className="px-2 lg:px-4 max-w-[1240px] mx-auto">
          <div className="flex items-center justify-center sm:justify-between flex-col sm:flex-row">
            <div className="text-center pb-4 lg:py-4">
              &copy;2023.KofyImages.All rights reserved
            </div>

            <div className="flex items-center justify-between">
              <a
                href="https://www.facebook.com/profile.php?id=100088051583463"
                target="_blank"
                rel="noreferrer"
                className="mr-10"
              >
                <AiFillFacebook size={25} />
              </a>
              <a
                href="https://twitter.com/KofyImages"
                target="_blank"
                rel="noreferrer"
                className="mr-10"
              >
                <BsTwitter size={25} />
              </a>
              <a
                href="https://www.instagram.com/kofy_images/"
                target="_blank"
                rel="noreferrer"
                className="lg:mr-14"
              >
                <AiFillInstagram size={25} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
