import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logonv.png";
import {
  AiOutlineMenu,
  AiOutlineClose,
  AiFillFacebook,
  AiFillInstagram,
} from "react-icons/ai";
import { HiShoppingCart, HiUser, HiChevronDown } from "react-icons/hi";
import { GoSignIn, GoSignOut } from "react-icons/go";
import { MdRateReview, MdEventSeat } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { SiGnuprivacyguard } from "react-icons/si";
import { useDispatch, useSelector } from "react-redux";
import { getTotals } from "../slices/cartSlice";
import { Menu, Transition } from "@headlessui/react";
import { ImUserCheck } from "react-icons/im";
import { logoutUser } from "../slices/authSlice";
import ThemeToggle from "./ThemeToggle";
import logo2 from "../images/logowhite.png";


const Navbar = () => {
  const [nav, setNav] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { cartTotalQuantity } = useSelector((state) => state.cart);
  const cart = useSelector((state) => state.cart);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  const handleNav = () => {
    setNav(!nav);
  };

  const handleRefresh = () => {
    window.reload(false);
  };

  const token = localStorage.getItem("token");
  const first_name = localStorage.getItem("first_name");

  return (
    <div>
      <div className="fixed w-full h-20 z-[100] shadow-lg bg-white">
        <div className="flex justify-between items-center w-full h-full px-2 lg:px-4 max-w-[1240px] mx-auto">
          <Link to="/" onClick={handleRefresh} className="cursor-pointer">
            <img src={logo} alt="/" width={140} />
          </Link>

          <div>
            <ul className="hidden md:flex items-center">
              <li className="mr-10 uppercase">
                <Link to="/">Home</Link>
              </li>
              {auth._id && auth.first_name ? (
                <div className="flex items-center justify-between">
                  <div className="flex items-center justify-between mr-10">
                    <p>
                      <ImUserCheck className="inline mr-1" size={22} /> Hi,{" "}
                      {auth.first_name}!
                    </p>
                    <div>
                      <Menu as="div" className="relative text-left ml-1">
                        <div className="flex">
                          <Menu.Button>
                            <HiChevronDown size={20} />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-[-5rem] mt-4 w-56 divide-y divide-gray-100 rounded-md bg-[#F5F5F5] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <p
                                    className={
                                      (active
                                        ? "bg-gray-500 text-gray-100"
                                        : "text-gray-200",
                                      "block px-4 py-2 text-primary border-b text-center cursor-pointer")
                                    }
                                  >
                                    Welcome! 👋
                                  </p>
                                )}
                              </Menu.Item>

                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to="/user-profile"
                                    className={
                                      (active
                                        ? "bg-gray-500 text-gray-100"
                                        : "text-gray-200",
                                      "block px-4 py-2 text-primary border-b text-center cursor-pointer")
                                    }
                                  >
                                    Profile{" "}
                                    <FaUserCircle className="inline ml-4" />
                                  </Link>
                                )}
                              </Menu.Item>

                              <Menu.Item>
                                {({ active }) => (
                                  <p
                                    className={
                                      (active
                                        ? "bg-gray-500 text-gray-100"
                                        : "text-gray-200",
                                      "block px-4 py-2 text-primary text-center cursor-pointer")
                                    }
                                    onClick={() => {
                                      dispatch(logoutUser(null));
                                      navigate("/login");
                                    }}
                                  >
                                    Logout <GoSignOut className="inline ml-4" />
                                  </p>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <li className="mr-12 uppercase">
                    <Link to="/review">Reviews</Link>
                  </li>
                  <li className="mr-12 uppercase">
                    <Link to="/events">Events</Link>
                  </li>
                </div>
              ) : token ? (
                <div className="flex items-center justify-between">
                  <div className="flex items-center justify-between mr-10">
                    <p>
                      <ImUserCheck className="inline mr-1" size={22} /> Hi,{" "}
                      {first_name}!
                    </p>
                    <div>
                      <Menu as="div" className="relative text-left ml-1">
                        <div className="flex">
                          <Menu.Button>
                            <HiChevronDown size={20} />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-[-5rem] mt-4 w-56 divide-y divide-gray-100 rounded-md bg-[#F5F5F5] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <p
                                    className={
                                      (active
                                        ? "bg-gray-500 text-gray-100"
                                        : "text-gray-200",
                                      "block px-4 py-2 text-primary border-b text-center cursor-pointer")
                                    }
                                  >
                                    Welcome! 👋
                                  </p>
                                )}
                              </Menu.Item>

                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to="/user-profile"
                                    className={
                                      (active
                                        ? "bg-gray-500 text-gray-100"
                                        : "text-gray-200",
                                      "block px-4 py-2 text-primary border-b text-center cursor-pointer")
                                    }
                                  >
                                    Profile{" "}
                                    <FaUserCircle className="inline ml-4" />
                                  </Link>
                                )}
                              </Menu.Item>

                              <Menu.Item>
                                {({ active }) => (
                                  <p
                                    className={
                                      (active
                                        ? "bg-gray-500 text-gray-100"
                                        : "text-gray-200",
                                      "block px-4 py-2 text-primary text-center cursor-pointer")
                                    }
                                    onClick={() => {
                                      dispatch(logoutUser(null));
                                      navigate("/login");
                                    }}
                                  >
                                    Logout <GoSignOut className="inline ml-4" />
                                  </p>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <li className="mr-12 uppercase">
                    <Link to="/review">Reviews</Link>
                  </li>
                  <li className="mr-12 uppercase">
                    <Link to="/events">Events</Link>
                  </li>
                </div>
              ) : (
                <div className="flex items-center">
                  <li className="mr-10 uppercase">
                    <Link to="/register">Register</Link>
                  </li>
                  <li className="mr-10 uppercase">
                    <Link to="/login">Login</Link>
                  </li>
                  <li className="mr-10 uppercase">
                    <Link to="/events">Events</Link>
                  </li>
                </div>
              )}
              <li className="uppercase relative">
                <Link to="/cart">
                  <HiShoppingCart className="inline" size={22} /> CART
                  {cartTotalQuantity > 0 ? (
                    <div className="absolute -top-3 left-4 w-5 h-5 rounded-full bg-black flex items-center justify-center">
                      <p className="text-xs text-white font-semibold">
                        {cartTotalQuantity}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </Link>
              </li>
            </ul>

            <div className="flex items-center justify-evenly">
              {/* Cart icon */}
              <Link to="/cart" className="md:hidden z-9 relative mr-3">
                <HiShoppingCart size={22} />
                {cartTotalQuantity > 0 ? (
                  <div className="absolute -top-3 left-4 w-5 h-5 rounded-full bg-black flex items-center justify-center">
                    <p className="text-xs text-white font-semibold">
                      {cartTotalQuantity}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </Link>
              {/* Hamburger Icon */}
              <div onClick={handleNav} className="md:hidden z-9 ml-3">
                <AiOutlineMenu size={25} />
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        {/* Overlay */}
        <div
          className={
            nav
              ? "md:hidden fixed left-0 top-0 w-full h-screen bg-black/70"
              : ""
          }
        >
          {/* Side Drawer Menu */}
          <div
            className={
              nav
                ? "fixed left-0 top-0 w-[75%] sm:w-[60%] md:w-[45%] h-screen bg-slate-100 p-4 ease-in duration-500 z-[100]"
                : "fixed left-[-100%] top-0 p-4 ease-in duration-500"
            }
          >
            <div>
              <div className="flex w-full items-center justify-between ">
                <Link onClick={() => setNav(false)} to="">
                  <img src={logo} alt="/" width={110} />
                </Link>

                <div
                  onClick={handleNav}
                  className="rounded-full shadow-md shadow-gray-400 p-3 cursor-pointer"
                >
                  <AiOutlineClose />
                </div>
              </div>

              <div className="border-b border-gray-300 my-9">
                <p className="w-[85%] md:w-[90%] py-3 font-bold">
                  Explore Cities Through Pictures
                </p>
              </div>
            </div>

            <div className="py-2 flex flex-col">
              {auth._id && auth.first_name ? (
                <div>
                  <p className="py-4 font-bold flex items-center">
                    <HiUser className="inline mr-4" size={22} />
                    Hi, {auth.first_name}!
                  </p>
                  <ul className="uppercase">
                    <Link to="/">
                      <li
                        className="py-4 text-sm"
                        onClick={() => setNav(false)}
                      >
                        Home
                      </li>
                    </Link>

                    <Link to="/user-profile">
                      <li
                        className="py-4 text-sm"
                        onClick={() => setNav(false)}
                      >
                        Profile <FaUserCircle className="inline ml-2" />
                      </li>
                    </Link>

                    <Link to="/events">
                      <li
                        className="py-4 text-sm"
                        onClick={() => setNav(false)}
                      >
                        Events <MdEventSeat className="inline ml-2" />
                      </li>
                    </Link>

                    <Link to="/review">
                      <li
                        className="py-4 text-sm"
                        onClick={() => setNav(false)}
                      >
                        Reviews <MdRateReview className="inline ml-2" />
                      </li>
                    </Link>

                    <li
                      className="py-4 text-sm"
                      onClick={() => {
                        dispatch(logoutUser(null));
                        navigate("/login");
                      }}
                    >
                      Logout <GoSignOut className="inline ml-2" />
                    </li>
                  </ul>
                </div>
              ) : token ? (
                <div>
                  <p className="py-4 font-bold flex items-center">
                    <HiUser className="inline mr-4" size={22} />
                    Hi, {first_name}!
                  </p>
                  <ul className="uppercase">
                    <Link to="/">
                      <li
                        className="py-4 text-sm"
                        onClick={() => setNav(false)}
                      >
                        Home
                      </li>
                    </Link>

                    <Link to="/user-profile">
                      <li
                        className="py-4 text-sm"
                        onClick={() => setNav(false)}
                      >
                        Profile <FaUserCircle className="inline ml-2" />
                      </li>
                    </Link>

                    <Link to="/events">
                      <li
                        className="py-4 text-sm"
                        onClick={() => setNav(false)}
                      >
                        Events <MdEventSeat className="inline ml-2" />
                      </li>
                    </Link>

                    <Link to="/review">
                      <li
                        className="py-4 text-sm"
                        onClick={() => setNav(false)}
                      >
                        Reviews <MdRateReview className="inline ml-2" />
                      </li>
                    </Link>

                    <li
                      className="py-4 text-sm"
                      onClick={() => {
                        dispatch(logoutUser(null));
                        navigate("/login");
                      }}
                    >
                      Logout <GoSignOut className="inline ml-2" />
                    </li>
                  </ul>
                </div>
              ) : (
                <div>
                  <ul className="uppercase">
                    <Link to="/">
                      <li
                        className="py-4 text-sm"
                        onClick={() => setNav(false)}
                      >
                        Home
                      </li>
                    </Link>
                    <Link to="/events">
                      <li
                        className="py-4 text-sm"
                        onClick={() => setNav(false)}
                      >
                        <MdEventSeat className="inline mr-4" />
                        Events
                      </li>
                    </Link>
                    <Link to="/login">
                      <li
                        className="py-4 text-sm"
                        onClick={() => setNav(false)}
                      >
                        <GoSignIn className="inline mr-4" />
                        Login
                      </li>
                    </Link>
                    <Link to="/register">
                      <li
                        className="py-4 text-sm"
                        onClick={() => setNav(false)}
                      >
                        <SiGnuprivacyguard className="inline mr-4" />
                        Create Account
                      </li>
                    </Link>
                  </ul>
                </div>
              )}

              <div>
                <p className="uppercase tracking-widest text-[#ff5757] mt-[4rem]">
                  Connect with Us
                </p>

                <div className="flex items-center justify-between my-4 w-full sm:w-[80%]">
                  <a
                    href="https://www.facebook.com/profile.php?id=100088051583463"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="rounded-full shadow-md shadow-gray-400 p-3 cursor-pointer hover:scale-105 ease-in duration-300">
                      <AiFillFacebook />
                    </div>
                  </a>

                  <a
                    href="https://twitter.com/KofyImages"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="rounded-full shadow-md shadow-gray-400 p-3 cursor-pointer hover:scale-105 ease-in duration-300">
                      <BsTwitter />
                    </div>
                  </a>

                  <a
                    href="https://www.instagram.com/kofy_images/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="rounded-full shadow-md shadow-gray-400 p-3 cursor-pointer hover:scale-105 ease-in duration-300">
                      <AiFillInstagram />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
