import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  MdChevronRight,
  MdRemoveCircleOutline,
  MdRemoveShoppingCart,
  MdShoppingCart,
} from "react-icons/md";
import { BiMinus, BiPlus } from "react-icons/bi";
import emptyCart from "../images/emptycart.svg";
import { useDispatch } from "react-redux";
import {
  addToCart,
  clearCart,
  decreaseCart,
  getTotals,
  removeFromCart,
} from "../slices/cartSlice";
import PayButton from "../components/PayButton";
import { useNavigate } from "react-router-dom";

const CartPage = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  const handleRemoveFromCart = (cartItem) => {
    dispatch(removeFromCart(cartItem));
  };

  const handleDecreaseCart = (cartItem) => {
    dispatch(decreaseCart(cartItem));
  };

  const handleIncreaseCart = (cartItem) => {
    dispatch(addToCart(cartItem));
  };

  const handleclearCart = () => {
    dispatch(clearCart());
  };
  return (
    <div className="pt-[5rem]">
      <div className="w-full bg-[#000] p-7">
        <div className="px-2 lg:px-4 max-w-[1240px] mx-auto">
          <p className="text-white">
            <Link to="/" className="cursor-pointer font-bold">
              Home
            </Link>{" "}
            <MdChevronRight className="inline" />{" "}
            <span className="text-gray-300">Shopping Cart</span>
          </p>
        </div>
      </div>
      {cart.cartItems.length === 0 ? (
        <div className="w-full h-full">
          <div className="flex flex-col items-center justify-center">
            <div className="h-[350px] w-[350px] py-5">
              <img src={emptyCart} alt="" />
            </div>
            <p className="text-3xl md:text-4xl font-bold tracking-wide py-2">
              Your Cart is empty!
            </p>
            <p className="text-md py-2 text-center">
              Explore our cities and shop for different frames.
            </p>
            <div className="py-4">
              <Link
                to="/"
                className="bg-black px-6 py-3 shadow-md rounded-md text-white"
              >
                Explore Cities!
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="px-2 lg:px-4 max-w-[1240px] mx-auto py-12 h-full">
          <div className="overflow-x-auto">
            <table className="border-collapse whitespace-nowrap w-full">
              <thead className="border border-solid border-gray-500 border-l-0 border-r-0">
                <tr>
                  <td className="text-xl font-bold uppercase p-[1rem] text-center">
                    Remove
                  </td>
                  <td className="text-xl font-bold uppercase p-[1rem] text-center">
                    Item
                  </td>
                  <td className="text-xl font-bold uppercase p-[1rem] text-center">
                    Price
                  </td>
                  <td className="text-xl font-bold uppercase p-[1rem] text-center">
                    Quantity
                  </td>
                  <td className="text-xl font-bold uppercase p-[1rem] text-center">
                    Color
                  </td>
                  <td className="text-xl font-bold uppercase p-[1rem] text-center">
                    Size
                  </td>
                  <td className="text-xl font-bold uppercase p-[1rem] text-center">
                    Subtotal
                  </td>
                </tr>
              </thead>
              <tbody>
                {cart.cartItems?.map((cartItem) => (
                  <tr key={cartItem._id}>
                    <td
                      onClick={() => handleRemoveFromCart(cartItem)}
                      className="text-xl flex items-center justify-center"
                    >
                      <MdRemoveCircleOutline className="mt-[2.7rem] cursor-pointer" />
                    </td>
                    <td className="w-[50px] h-[60px] p-4">
                      <img src={cartItem.image} alt="" />
                    </td>
                    <td className="text-xl p-[1rem] text-center">
                      £{cartItem.price}
                    </td>
                    <td className="text-xl p-[1rem] flex items-center justify-center gap-2">
                      <div>
                        <BiMinus
                          onClick={() => handleDecreaseCart(cartItem)}
                          className="cursor-pointer"
                        />
                      </div>
                      <p className="w-5 h-5 rounded-sm flex items-center justify-center bg-slate-300">
                        {cartItem.cartQuantity}
                      </p>
                      <div>
                        <BiPlus
                          onClick={() => handleIncreaseCart(cartItem)}
                          className="cursor-pointer"
                        />
                      </div>
                    </td>
                    <td className="text-xl p-[1rem] text-center">
                      {cartItem.color ? cartItem.color.toUpperCase() : "NONE"}
                    </td>
                    <td className="text-xl p-[1rem] text-center">
                      {cartItem.size ? cartItem.size : "NONE"}
                    </td>
                    <td className="text-xl p-[1rem] text-center">
                      £{cartItem.price * cartItem.cartQuantity.toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex flex-wrap gap-8 justify-between items-center py-6">
            <div className="flex flex-wrap gap-8 items-end">
              <div
                onClick={() => handleclearCart()}
                className="bg-black px-6 py-3 text-white rounded-md flex items-center gap-4 cursor-pointer"
              >
                Clear Cart <MdRemoveShoppingCart />
              </div>

              <div
                onClick={() => {
                  navigate(-1);
                }}
                className="bg-black px-6 py-3 text-white rounded-md flex items-center gap-4 cursor-pointer"
              >
                Continue Shopping <MdShoppingCart />
              </div>
            </div>

            <div className="px-10 py-5 border border-solid border-gray-500">
              <h3 className="text-3xl font-bold text-center underline">
                Cart Summary
              </h3>
              <div className="flex items-center justify-between py-4">
                <p className="font-bold text-lg tracking-widest">Cart Total</p>
                <p className="font-bold text-lg tracking-widest">
                  £{cart.cartTotalAmount.toLocaleString()}
                </p>
              </div>
              {auth._id ? (
                <PayButton />
              ) : (
                <div className="w-full text-center py-3 bg-black text-white rounded-md px-8 cursor-pointer">
                  <Link to="/login">Proceed to Checkout</Link>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartPage;
