import React, { useEffect, useRef, useState } from "react";
import { MdChevronRight, MdAirplaneTicket } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
import Pagination from "../../../components/Pagination";

const AvailableReturnWayFlights = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const pageTopRef = useRef(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  const flights = JSON.parse(localStorage.getItem("flights"));
  // console.log(flights);

  const postsPerPage = 6;
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = flights.slice(firstPostIndex, lastPostIndex);

  const goBack = () => {
    navigate(-1);
    localStorage.removeItem("flights");
    localStorage.removeItem("offers");
  };

  const truncateString = (str, num) => {
    if (str?.length > num) {
      return str.slice(0, num) + "";
    } else {
      return str;
    }
  };

  const handleSubmit = (flight) => {
    window.localStorage.setItem("offer_id", flight.offer_id);
    window.localStorage.setItem("price", flight.price);
    window.localStorage.setItem("aircraft_name", flight.aircraft_name);
    window.localStorage.setItem("airline", flight.airline);
    window.localStorage.setItem("cabin_class", flight.cabin_class);
    window.localStorage.setItem("airline_logo", flight.airline_logo);

    // departure
    window.localStorage.setItem("arrival_date", flight.arrival_date);
    window.localStorage.setItem("arrival_time", flight.arrival_time);
    window.localStorage.setItem("departure_date", flight.departure_date);
    window.localStorage.setItem("departure_time", flight.departure_time);
    window.localStorage.setItem(
      "destination_city",
      flight.destination.city_name
    );
    window.localStorage.setItem(
      "destination_airport",
      flight.destination.airport_name
    );
    window.localStorage.setItem("duration", flight.duration);
    window.localStorage.setItem(
      "origin_city",
      flight.origin.city_name
    );
    window.localStorage.setItem(
      "origin_airport",
      flight.origin.airport_name
    );

    // return
    window.localStorage.setItem(
      "return_arrival_date",
      flight.return_arrival_date
    );
    window.localStorage.setItem(
      "return_arrival_time",
      flight.return_arrival_time
    );
    window.localStorage.setItem(
      "return_departure_date",
      flight.return_departure_date
    );
    window.localStorage.setItem(
      "return_departure_time",
      flight.return_departure_time
    );
    window.localStorage.setItem(
      "return_destination_city",
      flight.return_destination.city_name
    );
    window.localStorage.setItem(
      "return_destination_airport",
      flight.return_destination.airport_name
    );
    window.localStorage.setItem("return_origin_city", flight.return_origin.city_name);
    window.localStorage.setItem(
      "return_origin_airport",
      flight.return_origin.airport_name
    );
    navigate("/return-way-flight-details");
  };

  return (
    <div className="pt-[5rem]">
      <div className="w-full bg-[#000] p-7">
        <div className="px-2 lg:px-4 max-w-[1240px] mx-auto">
          <div className="text-white flex gap-2">
            <div onClick={goBack} className="cursor-pointer font-bold">
              Search Flights
            </div>{" "}
            <div>
              <MdChevronRight className="inline" />{" "}
              <span className="text-gray-300">Available Flights</span>
            </div>
          </div>
        </div>
      </div>

      <div className="px-2 lg:px-4 max-w-[1240px] mx-auto py-[3rem]" ref={pageTopRef}>
        <div className="flex items-center justify-center flex-col">
          <h2 className="font-bold text-3xl md:text-4xl tracking-wid after:w-[60px] after:h-[5px] after:bg-[#000] after:block after:m-auto after:mt-[0.5rem] mb-2 text-center">
            Flights
          </h2>
          <p className="text-lg my-2 tracking-wide">
            Showing available flights for this trip
          </p>
        </div>

        <div className="flex items-center justify-center flex-col py-9">
          <div className="grid grid-cols-1 gap-8">
            {currentPosts?.map((flight, index) => (
              <div
                key={index}
                className="bg-[#f5f5f5] rounded-md px-6 py-9 w-[350px] sm:w-[600px] md:w-[870px]"
              >
                {/* Desktop View */}
                <div className="hidden md:block">
                  <div>
                    <p className="font-bold text-xl pb-2">Departure</p>
                    <div className="hidden md:flex items-center justify-between gap-4 flex-col md:flex-row pb-6">
                      <div className="flex items-center gap-4">
                        <div className="w-12 h-12">
                          <img src={flight.airline_logo} alt="" />
                        </div>
                        <div>
                          <p className="font-bold">
                            {truncateString(flight.departure_time, 5)} -{" "}
                            {truncateString(flight.arrival_time, 5)}
                          </p>
                          <p className="text-sm">
                            {flight.departure_date} - {flight.arrival_date}
                          </p>
                          <p className="text-xs text-[#363535]">
                            {flight.aircraft_name} - {flight.airline}
                          </p>
                        </div>
                      </div>

                      <div className="flex gap-2">
                        <div>
                          <p className="font-bold">
                            0{flight.duration.replace(/([A-Z])/g, "$1 ").trim()}
                          </p>
                          <p className="text-sm">
                            {flight.origin.city_name} ({flight.origin.iata_code}
                            ) - {flight.destination.city_name} (
                            {flight.destination.iata_code})
                          </p>
                          <p className="text-xs text-[#363535]">
                            {flight.origin.airport_name} -{" "}
                            {flight.destination.airport_name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <p className="font-bold text-xl pb-2">Return</p>
                    <div className="hidden md:flex items-center justify-between gap-4 flex-col md:flex-row">
                      <div className="flex items-center gap-4">
                        <div className="w-12 h-12">
                          <img src={flight.airline_logo} alt="" />
                        </div>
                        <div>
                          <p className="font-bold">
                            {truncateString(flight.return_departure_time, 5)} -{" "}
                            {truncateString(flight.return_arrival_time, 5)}
                          </p>
                          <p className="text-sm">
                            {flight.return_departure_date} -{" "}
                            {flight.return_arrival_date}
                          </p>
                          <p className="text-xs text-[#363535]">
                            {flight.aircraft_name} - {flight.airline}
                          </p>
                        </div>
                      </div>

                      <div className="flex gap-2">
                        <div>
                          <p className="font-bold">
                            0{flight.duration.replace(/([A-Z])/g, "$1 ").trim()}
                          </p>
                          <p className="text-sm">
                            {flight.return_origin.city_name} (
                            {flight.return_origin.iata_code}) -{" "}
                            {flight.return_destination.city_name} (
                            {flight.return_destination.iata_code})
                          </p>
                          <p className="text-xs text-[#363535]">
                            {flight.return_origin.airport_name} -{" "}
                            {flight.return_destination.airport_name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-5 flex justify-end items-end">
                    <button
                      className="bg-black text-white px-5 py-2 rounded-md font-bold mt-2"
                      onClick={() => handleSubmit(flight, index)}
                    >
                      <p>
                        £ {flight.price}{" "}
                        <HiArrowNarrowRight className="ml-1 mb-1 inline" />
                      </p>
                    </button>
                  </div>
                </div>

                {/*  Mobile View  */}
                <div className="block md:hidden">
                  <div>
                    <p className="font-bold text-xl pb-2">Departure</p>
                    <div className="flex items-center gap-4">
                      <div className="w-8 h-8">
                        <img src={flight.airline_logo} alt="" />
                      </div>
                      <div>
                        <div className="font-bold text-sm flex items-center my-1 w-full">
                          <p>
                            {truncateString(flight.departure_time, 5)} -{" "}
                            {truncateString(flight.arrival_time, 5)}
                          </p>

                          <div className="px-1 py-[1px] bg-[#000] rounded-xl text-xs text-white ml-[4rem]">
                            {flight.cabin_class.toUpperCase()}{" "}
                            <MdAirplaneTicket className="ml-1 mb-[1px] inline" />
                          </div>
                        </div>

                        <p>
                          {flight.aircraft_name} - {flight.airline}
                        </p>
                      </div>
                    </div>
                    <div className="border-b-2 border-b-black"></div>
                    <div className="flex items-center gap-4 py-2">
                      <div>
                        <p>{truncateString(flight.departure_time, 5)}</p>
                        <p className="text-xs text-[#363535]">
                          {flight.departure_date}
                        </p>
                      </div>
                      <div>
                        <p>
                          {flight.origin.city_name} ({flight.origin.iata_code})
                        </p>
                        <p className="text-xs text-[#363535]">
                          {flight.origin.airport_name}
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center gap-4 py-2">
                      <div>
                        <p>{truncateString(flight.arrival_time, 5)}</p>
                        <p className="text-xs text-[#363535]">
                          {flight.arrival_date}
                        </p>
                      </div>
                      <div>
                        <p>
                          {flight.destination.city_name} (
                          {flight.destination.iata_code})
                        </p>
                        <p className="text-xs text-[#363535]">
                          {flight.destination.airport_name}
                        </p>
                      </div>
                    </div>
                    <div className="border-b-2 border-b-black"></div>
                  </div>

                  <div>
                    <p className="font-bold text-xl pb-2 pt-4">Return</p>
                    <div className="flex items-center gap-4">
                      <div className="w-8 h-8">
                        <img src={flight.airline_logo} alt="" />
                      </div>
                      <div>
                        <div className="font-bold text-sm flex items-center my-1 w-full">
                          <p>
                            {truncateString(flight.return_departure_time, 5)} -{" "}
                            {truncateString(flight.return_arrival_time, 5)}
                          </p>

                          <div className="px-1 py-[1px] bg-[#000] rounded-xl text-xs text-white ml-[4rem]">
                            {flight.cabin_class.toUpperCase()}{" "}
                            <MdAirplaneTicket className="ml-1 mb-[1px] inline" />
                          </div>
                        </div>

                        <p>
                          {flight.aircraft_name} - {flight.airline}
                        </p>
                      </div>
                    </div>
                    <div className="border-b-2 border-b-black"></div>
                    <div className="flex items-center gap-4 py-2">
                      <div>
                        <p>{truncateString(flight.return_departure_time, 5)}</p>
                        <p className="text-xs text-[#363535]">
                          {flight.return_departure_date}
                        </p>
                      </div>
                      <div>
                        <p>
                          {flight.return_origin.city_name} (
                          {flight.return_origin.iata_code})
                        </p>
                        <p className="text-xs text-[#363535]">
                          {flight.return_origin.airport_name}
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center gap-4 py-2">
                      <div>
                        <p>{truncateString(flight.return_arrival_time, 5)}</p>
                        <p className="text-xs text-[#363535]">
                          {flight.return_arrival_date}
                        </p>
                      </div>
                      <div>
                        <p>
                          {flight.return_destination.city_name} (
                          {flight.return_destination.iata_code})
                        </p>
                        <p className="text-xs text-[#363535]">
                          {flight.return_destination.airport_name}
                        </p>
                      </div>
                    </div>

                    <div className="border-b-2 border-b-black"></div>
                    <div className="flex items-end justify-end py-2">
                      <button
                        className="bg-black text-white px-5 py-2 rounded-md mt-1 font-bold"
                        onClick={() => handleSubmit(flight, index)}
                      >
                        <p>
                          £ {flight.price}{" "}
                          <HiArrowNarrowRight className="ml-1 mb-1 inline" />
                        </p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Pagination
          totalPosts={flights.length}
          postsPerPage={postsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          pageTopRef={pageTopRef}
        />
      </div>
    </div>
  );
};

export default AvailableReturnWayFlights;
