import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ClipLoader } from "react-spinners";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../slices/authSlice";
import { baseUrl } from "../slices/api";

const UpdateModal = ({ closeModal2, isOpen1 }) => {
  const [loading, setLoading] = useState(false);
  const [passwordEye, setPasswordEye] = useState(false);
  const [passwordEye1, setPasswordEye1] = useState(false);
  const auth = useSelector((state) => state.auth);
//   console.log(auth);
  const [values, setValues] = useState({
    first_name: auth.first_name,
    last_name: localStorage.getItem("last_name"),
    email: localStorage.getItem("email"),
    phone: localStorage.getItem("phone"),
    password: "",
    password2: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const newObj = { ...values, [e.target.name]: e.target.value };
    setValues(newObj);
  };

  const handlePasswordEye = () => {
    setPasswordEye(!passwordEye);
  };

  const handlePasswordEye1 = () => {
    setPasswordEye1(!passwordEye1);
  };

  async function updateUser(e) {
    e.preventDefault();
    try {
      const AuthToken = await localStorage.getItem("token");
      setLoading(true);
      const response = await fetch(
        `${baseUrl}/${auth._id}/`,
        {
          method: "PUT",
          headers: {
            Authorization: AuthToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            phone: values.phone,
            old_password: values.password,
            new_password: values.password2,
          }),
        }
      );
      // console.log(await response);
      setLoading(false);
      if (response.status === 200) {
        setLoading(false);
        dispatch(logoutUser(null));
        navigate("/login");
        toast.success(`Successfully Updated Account!, Please Login`, {
          position: "top-right",
        });
      }
      if (response.status === 400) {
        setLoading(false);
        toast.error(`Invalid Old Password!`, {
          position: "top-right",
        });
      }
    } catch (error) {
      // console.error(error);
      setLoading(false);
      if (error.response.status === 400) {
        setLoading(false);
        toast.error(`Invalid Old Password!`, {
          position: "top-right",
        });
      }
    }
  }

  return (
    <div>
      <Transition appear show={isOpen1} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal2}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/80" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all mt-[4.3rem]">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 flex flex-col items-center justify-center"
                  >
                    <p className="font-bold text-xl">Update this Account</p>
                  </Dialog.Title>
                  <div className="mt-2">
                    <form onSubmit={updateUser}>
                      <div className="my-3">
                        <input
                          type="text"
                          name="first_name"
                          value={values.first_name}
                          placeholder="FirstName"
                          onChange={handleChange}
                          className="px-4 border border-[#000] text-[#000] bg-transparent rounded-md py-3 h-12 w-full"
                        />
                      </div>

                      <div className="my-3">
                        <input
                          type="text"
                          name="last_name"
                          value={values.last_name}
                          placeholder="LastName"
                          onChange={handleChange}
                          className="px-4 border border-[#000] text-[#000] bg-transparent rounded-md py-3 h-12 w-full"
                        />
                      </div>

                      <div className="my-3">
                        <input
                          type="email"
                          name="email"
                          value={values.email}
                          placeholder="Email"
                          onChange={handleChange}
                          className="px-4 border border-[#000] text-[#000] bg-transparent rounded-md py-3 h-12 w-full"
                        />
                      </div>

                      <div className="my-3">
                        <input
                          type="tel"
                          name="phone"
                          value={values.phone}
                          placeholder="Phone Number"
                          onChange={handleChange}
                          className="px-4 border border-[#000] text-[#000] bg-transparent rounded-md py-3 h-12 w-full"
                        />
                      </div>

                      <div className="my-3 relative">
                        <input
                          type={passwordEye === false ? "password" : "text"}
                          name="password"
                          placeholder="Old Password"
                          onChange={handleChange}
                          required
                          className="px-4 border border-[#000] text-[#000] bg-transparent rounded-md py-3 h-12 w-full"
                        />

                        <div className="absolute right-3 top-4">
                          {passwordEye === false ? (
                            <AiFillEyeInvisible
                              onClick={handlePasswordEye}
                              className="text-gray-400 cursor-pointer"
                            />
                          ) : (
                            <AiFillEye
                              onClick={handlePasswordEye}
                              className="text-gray-400 cursor-pointer"
                            />
                          )}
                        </div>
                      </div>

                      <div className="my-3 relative">
                        <input
                          type={passwordEye1 === false ? "password" : "text"}
                          className="px-4 border border-[#000] text-[#000] bg-transparent rounded-md py-3 h-12 w-full"
                          placeholder="New Password"
                          onChange={handleChange}
                          name="password2"
                          required
                        />

                        <div className="absolute right-3 top-4">
                          {passwordEye1 === false ? (
                            <AiFillEyeInvisible
                              onClick={handlePasswordEye1}
                              className="text-gray-400 cursor-pointer"
                            />
                          ) : (
                            <AiFillEye
                              onClick={handlePasswordEye1}
                              className="text-gray-400 cursor-pointer"
                            />
                          )}
                        </div>
                      </div>

                      <div className="flex items-center justify-end gap-4">
                        <div
                          onClick={closeModal2}
                          className="text-red-500 cursor-pointer"
                        >
                          Cancel
                        </div>

                        <button
                          type="button"
                          className="rounded-md h-10 w-20 bg-black text-white"
                          onClick={updateUser}
                        >
                          {loading ? (
                            <ClipLoader size={20} color={"#fff"} />
                          ) : (
                            "Update"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default UpdateModal;
