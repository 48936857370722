import axios from "axios";
import React, { useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ClipLoader } from "react-spinners";
import {  useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate} from "react-router-dom";
import Select from "react-select";
import { baseUrl } from "../utils/baseUrl";

const ReviewModal = ({
  closeModal,
  isOpen,
  clickedReview,
  clickedRating,
  clickedId,
  options
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedCities, setSelectedCities] = useState(null);
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  //   console.log(auth);

  const styles = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "transparent",
      height: "3rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      borderWidth: "2px",
      borderColor: "#000",
      outline: "2px solid transparent",
      outlineOffset: "2px",
      borderRadius: "0.375rem",
      transitionDuration: "200ms",
      transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
    }),
  };

  const [values, setValues] = useState({
    rating: clickedRating,
    review: clickedReview,
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const newObj = { ...values, [e.target.name]: e.target.value };
    setValues(newObj);
  };

  const validation = (values) => {
    const errorss = {};
    if (values.review === "") {
      errorss.review = "Review is Required!";
    }
    if (values.rating === "") {
      errorss.rating = "Rating is Required!";
    } else if (values.rating < 1) {
      errorss.rating = "Please input a value from 1 to 5";
    } else if (values.rating > 5) {
      errorss.rating = "Please input a value from 1 to 5";
    }

    return errorss;
  };

  async function updateReview(e) {
    e.preventDefault();
    setErrors(validation(values));
    if (values.rating >= 1 && values.rating <= 5) {
    try {
      const AuthToken = await localStorage.getItem("token");
      setLoading(true);
      const response = await fetch(
        `${baseUrl}/reviews/${clickedId}/`,
        {
          method: "PUT",
          headers: {
            Authorization: AuthToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            rating: values.rating,
            review: values.review,
            city: selectedCities.value,
          }),
        }
      );
      console.log(response);
      setLoading(false);
      if (response.status === 200) {
        setLoading(false);
        toast.success(`Review Successfully Updated!`, {
          position: "top-right",
        });

        const reloadPage = setTimeout(() => {
          window.scrollTo({
              top: 0,
              left: 0,
            });
          window.location.reload();
        }, 1000);

        return () => clearTimeout(reloadPage);
      }
      if (response.status === 401) {
        setLoading(false);
        toast.error(`You are not allowed to update this review!`, {
          position: "top-right",
        });
      }
      if (response.status === 400) {
        setLoading(false);
        toast.error(`Review Update Failed, Please try again!`, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      if (error.response.status === 400) {
        setLoading(false);
        toast.error(`Review Update Failed, Please try again!`, {
          position: "top-right",
        });
      }
    }}
  }
    
  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/80" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all mt-[4.3rem]">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 flex flex-col items-center justify-center"
                  >
                    <p className="font-bold text-xl">Update Review</p>
                    <p className="text-center font-bold text-sm">Update your Review about a City</p>
                  </Dialog.Title>
                  <div className="mt-2">
                    <form
                      onSubmit={updateReview}
                      id="create-course-form"
                      className="my-4"
                    >
                      <div>
                        <div className="md:hidden">
                          <label className="font-bold">City*</label>
                          <Select
                            defaultValue={selectedCities}
                            onChange={setSelectedCities}
                            options={options}
                            styles={styles}
                            placeholder="Search or Select a City..."
                            // required
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: "grey",
                                primary: "black",
                              },
                            })}
                          />
                        </div>

                        <label className="relative hidden md:block">
                          <Select
                            defaultValue={selectedCities}
                            onChange={setSelectedCities}
                            options={options}
                            styles={styles}
                            placeholder="Search or Select a City..."
                            // required
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: "grey",
                                primary: "black",
                              },
                            })}
                          />
                          <span className="text-xl absolute left-0 top-2 mx-4 text-[#000] bg-[#fff] transform -translate-y-6 -translate-x-3 scale-75 font-bold">
                            City*
                          </span>
                        </label>
                      </div>

                      <div className="my-4">
                        <input
                          type="number"
                          value={values.rating}
                          placeholder="Rate the city out of 5.0"
                          name="rating"
                          onChange={handleChange}
                          className="w-full h-12 px-6 rounded-md border-[2px] border-black"
                        />
                        {errors.rating && (
                          <p className="text-sm text-red-600">
                            {errors.rating}
                          </p>
                        )}
                      </div>

                      <div className="my-2">
                        <textarea
                          name="review"
                          value={values.review}
                          onChange={handleChange}
                          cols="10"
                          rows="5"
                          placeholder="Leave a Review"
                          className="px-6 border-[2px] border-black w-full rounded-md py-2"
                        ></textarea>
                        {errors.review && (
                          <p className="text-sm text-red-600">
                            {errors.review}
                          </p>
                        )}
                      </div>

                      <button className="rounded-md h-12 w-full bg-[#000] text-white my-2">
                        {loading ? (
                          <ClipLoader size={20} color={"#fff"} />
                        ) : (
                          "Update Review"
                        )}
                      </button>

                      <div className="text-center text-red-500 font-bold py-2 cursor-pointer" onClick={closeModal}>Cancel</div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default ReviewModal;
