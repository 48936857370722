import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { AiOutlineClose } from "react-icons/ai";

const AboutEventsModal = ({
  closeAboutModal,
  isOpen3,
  clickedCity1,
  clickedCountry1,
  clickedName1,
  clickedLocation1,
  clickedDescription1,
  clickedDate1,
  clickedTime1,
  clickedImage1,
  clickedId1,
}) => {
  return (
    <div>
      <Transition appear show={isOpen3} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAboutModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/80" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[40rem] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all mt-[4.3rem]">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 flex flex-col items-center justify-center"
                  >
                    <AiOutlineClose
                      size={22}
                      className="absolute top-[1rem] sm:top-[1rem] right-[1rem] z-[99999] cursor-pointer"
                      onClick={closeAboutModal}
                    />
                    <p className="font-bold text-2xl py-1 mt-3">Event Details</p>
                    <p className="font-bold text-center tracking-wider">
                      Details of this Upcoming Event
                    </p>
                  </Dialog.Title>
                  <div className="mt-2 w-full">
                    <div className="block md:flex md:justify-between gap-8">
                      <div className="w-[19rem] md:w-[25rem]">
                        <img src={clickedImage1} alt="" />
                      </div>
                      <div>
                        <div className="my-2">
                          <p className="text-gray-400 text-sm">
                            Name of Event:
                          </p>
                          <p className="font-bold uppercase">{clickedName1}</p>
                        </div>
                        <div className="my-2">
                          <p className="text-gray-400 text-sm">
                            City and Country of Event:
                          </p>
                          <p className="font-bold uppercase">
                            {clickedCity1}, {clickedCountry1}
                          </p>
                        </div>
                        <div className="my-2">
                          <p className="text-gray-400 text-sm">
                            Location of Event:
                          </p>
                          <p className="font-bold uppercase">
                            {clickedLocation1}
                          </p>
                        </div>
                        <div className="my-2">
                          <p className="text-gray-400 text-sm">
                            Date of Event:
                          </p>
                          <p className="font-bold uppercase">{clickedDate1}</p>
                        </div>
                        <div className="my-2">
                          <p className="text-gray-400 text-sm">
                            Time of Event:
                          </p>
                          <p className="font-bold uppercase">{clickedTime1}</p>
                        </div>
                      </div>
                    </div>

                    <div className="my-2">
                      <h4 className="text-gray-400 text-sm md:text-black md:font-bold md:text-lg">
                        Description of Event
                      </h4>
                      <p className="md:font-normal font-bold">
                        {clickedDescription1}
                      </p>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default AboutEventsModal;
