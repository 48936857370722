import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { ClipLoader } from "react-spinners";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../slices/authSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../slices/api";


const DeleteModal = ({closeModal, isOpen }) => {
  const [loading, setLoading] = useState(false);
  const [passwordEye, setPasswordEye] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePasswordEye = () => {
    setPasswordEye(!passwordEye);
  };

  const [values, setValues] = useState({
    password: "",
  });

  const handleChange = (e) => {
    const newObj = { ...values, [e.target.name]: e.target.value };
    setValues(newObj);
  };

  const auth = useSelector((state) => state.auth);
  // console.log(auth);

  async function deleteUser(e) {
    e.preventDefault();
    try {
      const AuthToken = await localStorage.getItem("token");
      setLoading(true);
      const response = await fetch(
        `${baseUrl}/${auth._id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: AuthToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            password: values.password,
          }),
        }
      );
      // console.log(await response);
      setLoading(false);
      if (response.status === 200) {
        setLoading(false);
        dispatch(logoutUser(null));
        navigate("/");
        toast.success(`Successfully Deleted Account!`, {
          position: "top-right",
        });
      }
      if (response.status === 400) {
        setLoading(false);
        toast.error(`Invalid Password!`, {
          position: "top-right",
        });
      }
    } catch (error) {
      // console.error(error);
      setLoading(false);
      if (error.response.status === 400) {
        setLoading(false);
        toast.error(`Invalid Password!`, {
          position: "top-right",
        });
      }
    }
  }

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/80" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 flex flex-col items-center justify-center"
                  >
                    <RiDeleteBin2Fill className="text-red-500" size={25} />
                    <p className="font-bold text-xl">Delete this Account</p>
                  </Dialog.Title>
                  <div className="mt-2">
                    <form onSubmit={deleteUser} id="create-course-form">
                      <p className="text-gray-500 my-2 text-center">
                        Once you delete this account, there is no going back.
                        Please be certain!
                      </p>

                      <p className="mt-4">
                        Type your{" "}
                        <span className="text-red-500 italic">password</span> in
                        the box below to confirm.
                      </p>

                      <div className="my-4 relative">
                        <input
                          type={passwordEye === false ? "password" : "text"}
                          className="px-4 border border-[#000] text-[#000] bg-transparent rounded-md py-3 h-12 w-full"
                          placeholder="Type your Password"
                          name="password"
                          onChange={handleChange}
                          required
                        />

                        <div className="absolute right-3 top-4">
                          {passwordEye === false ? (
                            <AiFillEyeInvisible
                              onClick={handlePasswordEye}
                              className="text-gray-400 cursor-pointer"
                            />
                          ) : (
                            <AiFillEye
                              onClick={handlePasswordEye}
                              className="text-gray-400 cursor-pointer"
                            />
                          )}
                        </div>
                      </div>
                      {/* {errors.email && (
                        <p className="text-sm text-red-600">{errors.email}</p>
                      )} */}

                      <div className="flex items-center justify-end gap-4">
                        <div
                          onClick={closeModal}
                          className="text-red-500 cursor-pointer"
                        >
                          Cancel
                        </div>

                        <button
                          type="button"
                          className="rounded-md h-10 w-20 bg-black text-white"
                          onClick={deleteUser}
                        >
                          {loading ? (
                            <ClipLoader size={20} color={"#fff"} />
                          ) : (
                            "Proceed"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default DeleteModal;
