import React from "react";

const Pagination = ({
  totalPosts,
  postsPerPage,
  setCurrentPage,
  currentPage,
  pageTopRef,
}) => {

  let pages = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pages.push(i);
  }

  const prevPage = () => {  
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      pageTopRef.current.scrollIntoView();
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(totalPosts / postsPerPage)) {
      setCurrentPage(currentPage + 1);
      pageTopRef.current.scrollIntoView();
    }
  };

  const handleChange = (page, index) => {
    pageTopRef.current.scrollIntoView();
    setCurrentPage(page)
  }
  return (
    <div className="pagination flex flex-wrap items-center justify-center gap-3 md:justify-end">
      <div
        className={
          currentPage == 1
            ? "border border-black dark:border-[#dbdbdb] text-black dark:text-primary rounded-md px-3 py-2 cursor-not-allowed hover:bg-gray-200 dark:hover:bg-gray-900"
            : "border border-black dark:border-[#dbdbdb] text-black dark:text-primary rounded-md px-3 py-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-800"
        }
            onClick={prevPage} 
      >
        Previous
      </div>

      <div className="flex items-center gap-2">
        {pages.map((page, index) => {
          return (
            <button
              key={index}
              onClick={() => handleChange(page, index)}
              className={page == currentPage ? "active" : ""}
            >
              {page}
            </button>
          );
        })}
      </div>
      <div
        className={
          currentPage == Math.ceil(totalPosts / postsPerPage)
          ? "border border-black dark:border-[#dbdbdb] text-black dark:text-primary rounded-md px-3 py-2 cursor-not-allowed hover:bg-gray-200 dark:hover:bg-gray-900"
          : "border border-black dark:border-[#dbdbdb] text-black dark:text-primary rounded-md px-3 py-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-800"
        }
        onClick={nextPage}
      >
        Next
      </div>
    </div>
  );
};

export default Pagination;
