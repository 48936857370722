import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";
import { RiDeleteBin2Fill } from "react-icons/ri";

const DeleteReviewModal = ({ closeDeleteModal, isOpen2, clickedId }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const AuthToken = await localStorage.getItem("token");
      setLoading(true);
      const response = await fetch(`${baseUrl}/reviews/${clickedId}/`, {
        method: "DELETE",
        headers: {
          Authorization: AuthToken,
          "Content-Type": "application/json",
        },
      });
    //   console.log(response);
      if (response.status === 200) {
        setLoading(false);
        toast.success(`Review Deleted Successfully!`, {
            position: "top-right",
          });
  
          const reloadPage = setTimeout(() => {
            window.scrollTo({
                top: 0,
                left: 0,
              });
            window.location.reload();
          }, 1000);
  
          return () => clearTimeout(reloadPage);
      }
      if (response.status === 401) {
        setLoading(false);
        toast.error(`You are not allowed to delete this Review!`, {
          position: "top-right",
        });
      }
      if (response.status === 400) {
        setLoading(false);
        toast.error(`Delete Review Failed, Please try again!`, {
          position: "top-right",
        });
      }
    } catch (error) {
    //   console.error(error);
      setLoading(false);
      toast.error(`Please Try Again`, { position: "top-right" });
      setLoading(false);
      if (error.response.status === 400) {
        setLoading(false);
        toast.error(`Delete Review Failed, Please try again!`, {
          position: "top-right",
        });
      }
    }
  };

  return (
    <div>
      <Transition appear show={isOpen2} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeDeleteModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/80" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all mt-[4.3rem]">
                  <Dialog.Title
                    as="h3"
                    className="pb-2 flex flex-col items-center justify-center"
                  >
                    <RiDeleteBin2Fill className="text-red-500" size={25} />
                    <p className="font-bold text-2xl py-1">Delete Review</p>
                    <p className="text-center">
                      Are you sure you want to delete this Review?
                    </p>
                  </Dialog.Title>
                  <div className="mt-2">
                    <form className="pt-2" onSubmit={handleSubmit}>
                      <div className="flex flex-col items-center justify-center">
                        <button
                          type="submit"
                          className="rounded-md h-12 w-full bg-red-500 text-white"
                        >
                          {loading ? (
                            <ClipLoader size={20} color={"#fff"} />
                          ) : (
                            "Delete Review"
                          )}
                        </button>
                        <div
                          onClick={closeDeleteModal}
                          className="text-black cursor-pointer pt-4 text-xl"
                        >
                          Cancel
                        </div>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default DeleteReviewModal;
