import React, { useEffect } from "react";
import { CardPayment } from "@duffel/components";
import "@duffel/components/dist/CardPayment.min.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MdChevronRight } from "react-icons/md";
import { toast } from "react-toastify";
import { baseUrl } from "../../utils/baseUrl";

const FlightPayment = () => {
  const navigate = useNavigate();
  const duffelPaymentIntentClientToken = localStorage.getItem("client_token");

  const payment_intent_id = localStorage.getItem("payment_intent_id");
  // console.log(payment_intent_id);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  const goBack = () => {
    navigate(-1);
    localStorage.removeItem("payment_intent_id");
    localStorage.removeItem("client_token");
  };

  const successfulPaymentHandlerFn = async () => {
    // Show 'successful payment' page and confirm Duffel PaymentIntent
    try {
      await axios
        .post(
          `${baseUrl}/confirm_payment_intent/`,
          {
            pit: payment_intent_id,
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.data.status === "succeeded") {
            navigate("/book-flight");
            toast.success(
              `Payment Successful!, Please input passengers details`,
              {
                position: "top-right",
              }
            );
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const errorPaymentHandlerFn = (error) => {
    // Show error page
    console.log(error);
    toast.error(
      `${error.message}`,
      { position: "top-right" }
    );
  };

  return (
    <div className="pt-[5rem]">
      <div className="w-full bg-[#000] p-7">
        <div className="px-2 lg:px-4 max-w-[1240px] mx-auto">
          <div className="text-white flex gap-2">
            <div onClick={goBack} className="cursor-pointer font-bold">
              Available Flights
            </div>{" "}
            <MdChevronRight className="inline" />{" "}
            <span className="text-gray-300">Payment</span>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div className="mt-12 bg-[#f5f5f5] py-9 px-5 rounded-lg shadow-xl">
          <div className="font-bold text-lg mb-4 text-center">Enter your card details to Checkout 
          ( Pay <span className="text-blue-500">£ {window.localStorage.getItem("price")}</span>)
          </div>
          <div>
            <CardPayment
              duffelPaymentIntentClientToken={duffelPaymentIntentClientToken}
              successfulPaymentHandler={successfulPaymentHandlerFn}
              errorPaymentHandler={errorPaymentHandlerFn}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightPayment;
